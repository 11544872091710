import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { CustomerReviewService } from '../customer-review.service';

@Component({
  selector: 'app-customer-review-create',
  templateUrl: './customer-review-create.component.html',
  styleUrls: ['./customer-review-create.component.scss']
})
export class CustomerReviewCreateComponent implements OnInit {

  config: ICMSConfig;
  constructor(private _customerReviewService: CustomerReviewService) {
    this.config = this._customerReviewService.config;
  }
  ngOnInit(): void {
  }

}
