import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { RoleSet } from 'app/auth/models/roleSet';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { ApiService } from 'app/main/service/api.service';
import { Subject, Subscription } from 'rxjs';
import { RoleSetService } from './roleset.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-roleset',
  templateUrl: './roleset.component.html',
  styleUrls: ['./roleset.component.scss'],
})
export class RolesetComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription;
  contentHeader: object;
  componentName: string;
  roleSetList: RoleSet[];

  currentLang: string;
  apiPath: string;
  pathUrl: string;

  isLoading: boolean;
  searchText: string;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  searchData: any = {};
  public page = 1;
  public pageSize = 12;

  constructor(
    private _translateService: TranslateService,
    private _apiService: ApiService,
    private _componentsService: ComponentsService,
    private _modalService: NgbModal,
    private _roleSetService: RoleSetService
  ) {
    this.componentName = this._roleSetService.componentName;
    this.apiPath = this._roleSetService.apiPath;
    this.pathUrl = this._roleSetService.pathUrl;

    this.initData();
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this
    this.contentHeader = {
      headerTitle: 'BreadCrumbs.Manage.Role',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: 10,
      order: [[0, 'asc']],
      ordering: true,
      serverSide: true,
      lengthChange: false,
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: 'RoleName', targets: 1 },
        { name: 'IsActive', targets: 2 },
        { name: 'CreateDate', targets: 3 },
        { orderable: false, targets: 4 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.roleSetList = [];
        self.SetLoadingState();

        let defaultSortName = 'Username_uq';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'asc' ? 0 : 1;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;

        console.log(this.searchData);
        this._apiService
          .GetAllData(this.apiPath, this.searchData)
          .subscribe((response) => {
            console.log(response);
            this.roleSetList = response.data.resultData;
            self.SetLoadedState();
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          });
      },
    };
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  initData(): void {
    this.searchData = { SortPath: 'roleName', Direction: 0, isDelete: false };
  }

  getRoleDate(obj?: { [key: string]: any }) {
    var self = this;
    this.SetLoadingState();
    this.roleSetList = [];

    if (obj) {
      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = {
          SortPath: 'roleName',
          Direction: 0,
          isDelete: false,
        };
      } else {
        if (this.searchData.SortPath == 'Name') {
          this.searchData.SortPath = 'roleName';
        }
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = { SortPath: 'roleName', Direction: 0, isDelete: false };
    }

    this.loadData(this.searchData)
  }

  loadData(searchData?: any) {
    if (searchData) {
      this.searchData = searchData;
    } else {
      this.searchData = {
        SortPath: 'roleName',
        Direction: 0,
        isDelete: false,
      };
    }

    if (this.dtTrigger) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }
  }


  openDeleleUserModal(itemObj: RoleSet, status: boolean): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.title = this._translateService.instant(
      'ManageRole.DeleteTitle'
    );
    modalRef.componentInstance.detail =
      this._translateService.instant('ManageRole.Delete') +
      ` (${itemObj.roleName})`;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.deleteData(itemObj.id);
    });
  }

  deleteData(guid: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .SoftDelete(this.apiPath, guid)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          if (res.data && res.success) {
            self.initData();
            self._componentsService.SuccessSwal();
          } else {
            self._componentsService.ErrorSwal();
          }
        });
    });
  }

  openIsActiveModal(itemObj, status): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    let modeText = status
      ? this._translateService.instant('Status.Active')
      : this._translateService.instant('Status.Inactive');
    modalRef.componentInstance.title = `${modeText}`;
    modalRef.componentInstance.detail =
      this._translateService.instant('ManageRole.Active') +
      `${modeText}` +
      ` (${itemObj.roleName})`;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.setIsActiveUser({ id: itemObj.id, status: status });
    });
  }

  setIsActiveUser(val): void {
    this._apiService
      .SetIsActiveData(this.apiPath, val.id, val.status)
      .subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this.isLoading = true;
          this.initData();
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
  }
}
