<form [formGroup]="bankAccountForm" id="bankAccountForm">
  <div class="row">
    <div class="col-12" style="margin-bottom: 0.5rem">
      <label class="form-label" for="bankType">{{
        'ประเภทช่องทางการชำระ'
      }}</label>

      <span class="text-danger">&nbsp;*</span>
      <div class="w-100">
        <ng-select
          formControlName="isQRCode"
          placeholder="{{
            'ประเภทช่องทางการชำระ'
          }}"
          
          [ngClass]="{
            'is-invalid error':
              (isSubmit && f.isQRCode?.invalid) ||
              (f.isQRCode?.invalid && (f.isQRCode?.dirty || f.isQRCode?.touched))
          }"
        >
          <ng-option [value]="false">บัญชีธนาคาร</ng-option>
          <ng-option [value]="true">QR Code</ng-option>
        </ng-select>
        <div class="invalid-feedback">
          <div
            *ngIf="
              isSubmit ||
              (f.isQRCode.invalid && (f.isQRCode.dirty || f.isQRCode.touched))
            "
          >
            {{'กรุณาเลือกประเภทช่องทางการชำระ'}}
          </div>
        </div>
      </div>
    </div>

    <div class="col-12" style="margin-bottom: 0.5rem">
      <label class="form-label" for="bankId">{{
        "Payment.Modal.BankAccount.Bank.Label" | translate
      }}</label>

      <span class="text-danger">&nbsp;*</span>
      <div class="w-100">
        <ng-select
          bindLabel="name"
          bindValue="id"
          id="bankId"
          formControlName="bankId"
          [clearable]="false"
          [searchable]="true"
          placeholder="{{
            'Payment.Modal.BankAccount.Bank.Placeholder' | translate
          }}"
          (change)="onSelectBankId()"
          [ngClass]="{
            'is-invalid error':
              (isSubmit && f.bankId?.invalid) ||
              (f.bankId?.invalid && (f.bankId?.dirty || f.bankId?.touched))
          }"
        >
          <ng-option *ngFor="let item of bankNameList" [value]="item.id">{{
            item.name
          }}</ng-option>
        </ng-select>
        <div class="invalid-feedback">
          <div
            *ngIf="
              isSubmit ||
              (f.bankId.invalid && (f.bankId.dirty || f.bankId.touched))
            "
          >
            {{ "Payment.Modal.BankAccount.Bank.Error" | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" style="margin-bottom: 0.5rem">
      <label class="form-label" for="accountNumber">{{
        "Payment.Modal.BankAccount.AccountNumber.Label" | translate
      }}</label>

      <span class="text-danger">&nbsp;*</span>
      <input
        type="text"
        class="form-control"
        (change)="onSelectAccountNumber()"
        formControlName="accountNumber"
        placeholder="{{
          'Payment.Modal.BankAccount.AccountNumber.Placeholder' | translate
        }}"
        id="accountNumber"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
        maxlength="50"
        [ngClass]="{
          'is-invalid error':
            (isSubmit && f.accountNumber?.invalid) ||
            (f.accountNumber?.invalid &&
              (f.accountNumber?.dirty || f.accountNumber?.touched))
        }"
      />
      <div class="invalid-feedback">
        <div
          *ngIf="
            isSubmit ||
            (f.accountNumber.invalid &&
              (f.accountNumber.dirty || f.accountNumber.touched))
          "
        >
          {{ "Payment.Modal.BankAccount.AccountNumber.Error" | translate }}
        </div>
      </div>
    </div>
    <div class="col-12" style="margin-bottom: 0.5rem">
      <label class="form-label" for="bankBranch">{{
        "Payment.Modal.BankAccount.BankBranch.Label" | translate
      }}</label>
      <input
        type="text"
        class="form-control"
        (change)="onSelectBankBranch()"
        formControlName="bankBranch"
        placeholder="{{
          'Payment.Modal.BankAccount.BankBranch.Placeholder' | translate
        }}"
        id="bankBranch"
        maxlength="100"
        [ngClass]="{
          'is-invalid error':
            (isSubmit && f.bankBranch?.invalid) ||
            (f.bankBranch?.invalid &&
              (f.bankBranch?.dirty || f.bankBranch?.touched))
        }"
      />
      <div class="invalid-feedback">
        <div
          *ngIf="
            isSubmit ||
            (f.bankBranch.invalid &&
              (f.bankBranch.dirty || f.bankBranch.touched))
          "
        >
          {{ "Payment.Modal.BankAccount.BankBranch.Error" | translate }}
        </div>
      </div>
    </div>
    <div class="col-12" style="margin-bottom: 0.5rem">
      <label class="form-label" for="accountName">{{
        "Payment.Modal.BankAccount.AccountName.Label" | translate
      }}</label>
      <span class="text-danger">&nbsp;*</span>
      <input
        type="text"
        class="form-control"
        (change)="onSelectAccountName()"
        formControlName="accountName"
        placeholder="{{
          'Payment.Modal.BankAccount.AccountName.Placeholder' | translate
        }}"
        id="accountName"
        maxlength="200"
        [ngClass]="{
          'is-invalid error':
            (isSubmit && f.accountName?.invalid) ||
            (f.accountName?.invalid &&
              (f.accountName?.dirty || f.accountName?.touched))
        }"
      />
      <div class="invalid-feedback">
        <div
          *ngIf="
            isSubmit ||
            (f.accountName.invalid &&
              (f.accountName.dirty || f.accountName.touched))
          "
        >
          {{ "Payment.Modal.BankAccount.AccountName.Error" | translate }}
        </div>
      </div>
    </div>
    <div class="col-12" style="margin-bottom: 0.5rem">
      <label class="form-label mb-1" for="bankAccountType">{{
        "Payment.Modal.BankAccount.BankAccountType.Label" | translate
      }}</label>
      <div class="d-flex" style="justify-content: space-evenly">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            id="customRadio1"
            (change)="onSelectAccountType()"
            name="bankAccountType"
            formControlName="bankAccountType"
            class="custom-control-input"
            [value]="1"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.bankAccountType?.invalid) ||
                (f.bankAccountType?.invalid &&
                  (f.bankAccountType?.dirty || f.bankAccountType?.touched))
            }"
          />
          <label class="custom-control-label" for="customRadio1">{{
            "Payment.Modal.BankAccount.BankAccountType.Saving" | translate
          }}</label>
        </div>
        <div class="custom-control custom-radio">
          <input
            type="radio"
            id="customRadio2"
            (change)="onSelectAccountType()"
            name="bankAccountType"
            formControlName="bankAccountType"
            class="custom-control-input"
            [value]="2"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.bankAccountType?.invalid) ||
                (f.bankAccountType?.invalid &&
                  (f.bankAccountType?.dirty || f.bankAccountType?.touched))
            }"
          />
          <label class="custom-control-label" for="customRadio2">{{
            "Payment.Modal.BankAccount.BankAccountType.FixedDeposit" | translate
          }}</label>
        </div>
        <div class="custom-control custom-radio">
          <input
            type="radio"
            id="customRadio3"
            (change)="onSelectAccountType()"
            name="bankAccountType"
            formControlName="bankAccountType"
            class="custom-control-input"
            [value]="3"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.bankAccountType?.invalid) ||
                (f.bankAccountType?.invalid &&
                  (f.bankAccountType?.dirty || f.bankAccountType?.touched))
            }"
          />
          <label class="custom-control-label" for="customRadio3">{{
            "Payment.Modal.BankAccount.BankAccountType.Current" | translate
          }}</label>
        </div>
      </div>
      
    </div>
  </div>
</form>
