<div class="card-body">
  <h2 class="my-2 general-title" *ngIf="!notShowTitle">{{ "General.RelatedDocument" | translate }}</h2>

  <div *ngIf="orderStateData">
    <ul class="tree">
      <ng-container *ngIf="orderStateData['quotation']">
        <li>
          <ng-container
            *ngTemplateOutlet="
              singleChildDoc;
              context: {
                title: 'Quotation',
                doc: orderStateData['quotation'],
                routerURL: 'quotation',
                docCodeName: 'quotationCode'
              }
            "
          >
          </ng-container>
        </li>
      </ng-container>

      <ng-container *ngIf="orderStateData['saleOrder']">
        <li>
          <ng-container
            *ngTemplateOutlet="
              singleChildDoc;
              context: {
                title: 'SaleOrder',
                doc: orderStateData['saleOrder'],
                routerURL: 'saleorder',
                docCodeName: 'saleOrderCode'
              }
            "
          >
          </ng-container>
        </li>
      </ng-container>

      <ng-container *ngIf="orderStateData['invoice']?.length >= 1">
        <li>
          <details open disabled>
            <summary>{{ "Document.Invoice" | translate }}</summary>
            <ul>
              <li
                *ngFor="
                  let doc of orderStateData.invoice as docList;
                  let ind = index
                "
                style=""
              >
                <div
                  class="d-flex"
                  *ngIf="
                    !docList[ind]['taxInvoice'] &&
                    !docList[ind]['receipt'].length >= 1 &&
                    !docList[ind]['creditNote'].length >= 1 &&
                    !docList[ind]['debitNote'].length >= 1
                  "
                >
                  <a
                    style="margin-left: 1rem"
                    routerLink="/document/invoice/detail/{{ doc.id }}"
                    target="_blank"
                  >
                    {{ doc["invoiceCode"] }}
                  </a>
                  <app-document-status-badge
                    class="mx-1"
                    [status]="doc.transactionStatusId"
                  ></app-document-status-badge>
                  <p class="mb-0" *ngIf="currentLang == 'en'">
                    {{ doc.documentCreateDate | date : "dd MMM YYYY" }}
                  </p>
                  <p class="mb-0" *ngIf="currentLang == 'th'">
                    {{ doc.documentCreateDate | thaidate : "DD MMM YYYY" }}
                  </p>
                </div>
                <div *ngIf="doc['invoiceCode'] == currentDocCode">
                  <p>( {{ "ContentDocument.CurrentDocument" | translate }} )</p>
                </div>
                <details
                  *ngIf="
                    docList[ind]['taxInvoice'] ||
                    docList[ind]['receipt'].length >= 1 ||
                    docList[ind]['creditNote'].length >= 1 ||
                    docList[ind]['debitNote'].length >= 1
                  "
                  open
                  disabled
                >
                  <summary>
                    <div class="d-flex">
                      <a
                        routerLink="/document/invoice/detail/{{ doc.id }}"
                        target="_blank"
                      >
                        {{ doc["invoiceCode"] }}
                      </a>
                      <app-document-status-badge
                        class="mx-1"
                        [status]="doc.transactionStatusId"
                      ></app-document-status-badge>
                      <p class="mb-0" *ngIf="currentLang == 'en'">
                        {{ doc.documentCreateDate | date : "dd MMM YYYY" }}
                      </p>
                      <p class="mb-0" *ngIf="currentLang == 'th'">
                        {{ doc.documentCreateDate | thaidate : "DD MMM YYYY" }}
                      </p>
                    </div>
                    <div *ngIf="doc['invoiceCode'] == currentDocCode">
                      <p>
                        ( {{ "ContentDocument.CurrentDocument" | translate }} )
                      </p>
                    </div>
                  </summary>

                  <ng-container
                    *ngIf="
                      docList[ind]['taxInvoice'] ||
                      docList[ind]['receipt'].length >= 1 ||
                      docList[ind]['creditNote'].length >= 1 ||
                      docList[ind]['debitNote'].length >= 1
                    "
                  >
                    <ul>
                      <ng-container *ngIf="docList[ind]['taxInvoice']">
                        <li>
                          <ng-container
                            *ngTemplateOutlet="
                              singleChildDoc;
                              context: {
                                title: 'Tax',
                                doc: docList[ind]['taxInvoice'],
                                routerURL: 'tax',
                                docCodeName: 'taxInvoiceCode'
                              }
                            "
                          >
                          </ng-container>
                        </li>
                      </ng-container>

                      <ng-container *ngIf="docList[ind]['receipt'].length >= 1">
                        <li>
                          <ng-container
                            *ngTemplateOutlet="
                              multipleChildDoc;
                              context: {
                                title: 'Receipt',
                                docList: docList[ind]['receipt'],
                                routerURL: 'receipt',
                                docCodeName: 'receiptCode'
                              }
                            "
                          >
                          </ng-container>
                        </li>
                      </ng-container>

                      <ng-container
                        *ngIf="docList[ind]['creditNote'].length >= 1"
                      >
                        <li>
                          <ng-container
                            *ngTemplateOutlet="
                              multipleChildDoc;
                              context: {
                                title: 'CreditNote',
                                docList: docList[ind]['creditNote'],
                                routerURL: 'credit-note',
                                docCodeName: 'creditNoteCode'
                              }
                            "
                          >
                          </ng-container>
                        </li>
                      </ng-container>

                      <ng-container
                        *ngIf="docList[ind]['debitNote'].length >= 1"
                      >
                        <li>
                          <ng-container
                            *ngTemplateOutlet="
                              multipleChildDoc;
                              context: {
                                title: 'DebitNote',
                                docList: docList[ind]['debitNote'],
                                routerURL: 'debit-note',
                                docCodeName: 'debitNoteCode'
                              }
                            "
                          >
                          </ng-container>
                        </li>
                      </ng-container>
                    </ul>
                  </ng-container>
                </details>
              </li>
            </ul>
          </details>
        </li>
      </ng-container>

      <ng-container *ngIf="orderStateData['purchaseRequisition']">
        <li>
          <ng-container
            *ngTemplateOutlet="
              singleChildDoc;
              context: {
                title: 'PurchaseRequest',
                doc: orderStateData['purchaseRequisition'],
                routerURL: 'purchase-request',
                docCodeName: 'purchaseRequisitionCode'
              }
            "
          >
          </ng-container>
        </li>
      </ng-container>

      <ng-container *ngIf="orderStateData['purchaseOrder']">
        <li>
          <ng-container
            *ngTemplateOutlet="
              singleChildDoc;
              context: {
                title: 'PurchaseOrder',
                doc: orderStateData['purchaseOrder'],
                routerURL: 'purchase-order',
                docCodeName: 'purchaseOrderCode'
              }
            "
          >
          </ng-container>
        </li>
      </ng-container>

      <ng-container *ngIf="orderStateData['goodsReceive']?.length >= 1">
        <li>
          <ng-container
            *ngTemplateOutlet="
              multipleChildDoc;
              context: {
                title: 'GoodsReceive',
                docList: orderStateData['goodsReceive'],
                routerURL: 'goods-receive',
                docCodeName: 'goodsReceiveCode'
              }
            "
          >
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

<ng-template
  #singleChildDoc
  let-title="title"
  let-doc="doc"
  let-routerURL="routerURL"
  let-docCodeName="docCodeName"
>
  <details open disabled>
    <summary>{{ "Document." + title | translate }}</summary>
    <ul>
      <li>
        <div class="d-flex">
          <a
            style="margin-left: 1rem"
            routerLink="/document/{{ routerURL }}/detail/{{ doc.id }}"
            target="_blank"
          >
            {{ doc[docCodeName] }}
          </a>
          <app-document-status-badge
            class="mx-1"
            [status]="doc.transactionStatusId"
          ></app-document-status-badge>
          <p class="mb-0" *ngIf="currentLang == 'en'">
            {{ doc.documentCreateDate | date : "dd MMM YYYY" }}
          </p>
          <p class="mb-0" *ngIf="currentLang == 'th'">
            {{ doc.documentCreateDate | thaidate : "DD MMM YYYY" }}
          </p>
        </div>
        <div *ngIf="doc[docCodeName] == currentDocCode">
          <p>( {{ "ContentDocument.CurrentDocument" | translate }} )</p>
        </div>
      </li>
    </ul>
  </details>
</ng-template>

<ng-template
  #multipleChildDoc
  let-title="title"
  let-docList="docList"
  let-routerURL="routerURL"
  let-docCodeName="docCodeName"
>
  <details open disabled>
    <summary>{{ "Document." + title | translate }}</summary>
    <ul>
      <li *ngFor="let doc of docList">
        <div class="d-flex">
          <a
            style="margin-left: 1rem"
            routerLink="/document/{{ routerURL }}/detail/{{ doc.id }}"
            target="_blank"
            >{{ doc[docCodeName] }}</a
          >
          <app-document-status-badge
            class="mx-1"
            [status]="doc.transactionStatusId"
          ></app-document-status-badge>
          <p class="mb-0" *ngIf="currentLang == 'en'">
            {{ doc.documentCreateDate | date : "dd MMM YYYY" }}
          </p>
          <p class="mb-0" *ngIf="currentLang == 'th'">
            {{ doc.documentCreateDate | thaidate : "DD MMM YYYY" }}
          </p>
        </div>
        <div *ngIf="doc[docCodeName] == currentDocCode">
          <p>( {{ "ContentDocument.CurrentDocument" | translate }} )</p>
        </div>
      </li>
    </ul>
  </details>
</ng-template>
