import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../modal/modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/main/service/api.service';
import Promotion from 'app/main/model/Promotion';
import { CodeManageService } from '../../../pages/code-manage/code-manage.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ComponentsService } from '../../components.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-promotion-form',
  templateUrl: './promotion-form.component.html',
  styleUrls: ['./promotion-form.component.scss']
})
export class PromotionFormComponent implements OnInit {
  isLoading: boolean;
  registerForm: FormGroup;
  apiPath: string;
  pathUrl: string;
  @Input() isEditing: boolean;
  @BlockUI() blockUI: NgBlockUI;
  isSubmit: Boolean = false;
  groupGuid: string;
  itemObj: Promotion;

  startDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y H:i:s',
    enableTime: true
  }

  endDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y H:i:s',
    enableTime: true
  }

  // @ViewChild('confirmModal') confirmModal: ModalComponent;
  constructor(
    private _formBuilder: FormBuilder,
    private _modalService: NgbModal,
    private _router: Router,
    private _route: ActivatedRoute,
    private _apiService: ApiService,
    private _codeManageService: CodeManageService,
    private _componentsService: ComponentsService,
    private _translateService : TranslateService,
  ) {
    this.pathUrl = this._codeManageService.pathUrl;
    this.apiPath = this._codeManageService.apiUrl;
    this.registerForm = this._formBuilder.group({
      codeName: ['', [Validators.required, Validators.maxLength(50),this.noWhitespaceValidator]],
      redeemCode: ['', [Validators.required, Validators.maxLength(50),this.noWhitespaceValidator]],
      amount: ['', Validators.required],
      discount: ['', Validators.required],
      isDiscountByPercent: [false],
      isAmountUnlimit: [false],
      isUsedUnlimit: [false],
      isExpireUnlimit: [false],
      amountPerCustomer: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      isActive: [false],
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').match(/^\s*$|^\s+(?=\S)|\s+$/g);
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  // isBlank(str: string): boolean {
  //   return !str || /^\s*$|^\s+(?=\S)|\s+$/g.test(str);
  // }

  ngOnInit(): void {
    this.isEditing = this._route.snapshot.paramMap.get('id') ? true : false;
    if (this.isEditing) {
      console.log('edit');
      this.groupGuid = this._route.snapshot.paramMap.get('id');
      this.getGroupData(this.groupGuid);
    } else {
      console.log('create');

    }
  }

  getGroupData(guid: string): Promise<any> {
    console.log('getGroupData');
    
    return new Promise((resolve, reject) => {
      this._apiService.GetDataById(this.apiPath, guid).subscribe((res) => {
        this.itemObj = res.data.resultData[0];
        if (this.itemObj) {
          console.log('buildForm');
          this.buildForm(this.itemObj);

          console.log(this.itemObj.isActive);
          this.isLoading = false;
        }
      }, reject);
    });
  }

  onDateFromChange(event) {
    // console.log(event);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    if (!IsConfirm || this.registerForm.valid) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });

      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;

      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        if (!IsConfirm) {
          this._router.navigate([this.pathUrl]);
        } else if (IsConfirm) {
          console.log('submit');
          this.submit();
        }
      });
    }

  }

  openCancelModal(content?: any): void {
    let isConfirm = false;
    let title
    let detail
    if (this.isEditing) {
      title = this._translateService.instant('Promation.CancelEditTitle');
      detail = this._translateService.instant('Promation.CancelEdit');
    } else {
      title = this._translateService.instant('Promation.CancelAddTitle');
      detail = this._translateService.instant('Promation.CancelAdd');
    }

    this.openModal(title, detail, isConfirm);

  }

  openConfirmModal(content: any): void {
    this.isSubmit = true;
    let isConfirm = true;
    let title
    let detail
    if (this.isEditing) {
      title = this._translateService.instant('Promation.ConfirmEditTitle');
      detail = this._translateService.instant('Promation.ConfirmEdit');
    } else {
      title = this._translateService.instant('Promation.ConfirmAddTitle');
      detail = this._translateService.instant('Promation.ConfirmAdd');
    }

    this.openModal(title, detail, isConfirm);

  }

  get f() {
    return this.registerForm.controls;
  }

  submit(): void {
    this.blockUI.start();
    if (this.registerForm.valid) {
      this.registerForm.patchValue({
        startDate: this.registerForm.controls.startDate.value[0],
        endDate: this.registerForm.controls.endDate.value[0],
      })


      if (this.isEditing) { // ถ้าเป็นการแก้ไข
        this._apiService.UpdateDataById(
          this.apiPath,
          'id?id=' + this.groupGuid,   // !รอ api แก้
          this.registerForm.value).subscribe((res) => {
            console.log('Success Update Form With Update Status');
            // เปลี่ยนสถานะ
            this._apiService.SetIsActiveData(
              this.apiPath,
              this.groupGuid,
              this.registerForm.value.isActive).subscribe((res) => {
                console.log('Success Update Status');
                this._componentsService.SuccessSwal();
                this._router.navigate([this.pathUrl]);
              }), (err) => {
                this._componentsService.ErrorSwal();
              };
          });
      } else {
        console.log('create');
        this._apiService.AddData(this.apiPath,this.registerForm.value).subscribe((res) => {
          console.log('Create Success Form With Create Status');
          this._apiService.SetIsActiveData(
            this.apiPath,
            res.data.resultData[0],
            this.registerForm.value.isActive).subscribe((res) => {
              console.log('Success Create Status');
              this._componentsService.SuccessSwal();
              this._router.navigate([this.pathUrl]);
            });
        }), (err) => {
          this._componentsService.ErrorSwal();
        };
      }
    }
  }

  buildForm(itemObj: any): void {

    let tempDateStart = new Date(itemObj.startDate);
    let tempDateEnd = new Date(itemObj.endDate);

    this.startDateOptions = {
      dateFormat: 'd/m/Y H:i:s',
      enableTime: true,
      defaultDate: tempDateStart,
    }
    this.endDateOptions = {
      dateFormat: 'd/m/Y H:i:s',
      enableTime: true,
      defaultDate: tempDateEnd,
    }

    this.registerForm.patchValue({
      codeName: itemObj.codeName,
      redeemCode: itemObj.redeemCode,
      amount: itemObj.amount,
      discount: itemObj.discount,
      isDiscountByPercent: itemObj.isDiscountByPercent,
      isAmountUnlimit: itemObj.isAmountUnlimit,
      isUsedUnlimit: itemObj.isUsedUnlimit,
      isExpireUnlimit: itemObj.isExpireUnlimit,
      amountPerCustomer: itemObj.amountPerCustomer,
      startDate: tempDateStart.toISOString(),
      endDate: tempDateEnd.toISOString(),
      isActive: itemObj.isActive,
    });
  }

}
