import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ICMSConfig, FormType } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';

@Component({
  selector: 'app-how-to-buy',
  templateUrl: './how-to-buy.component.html',
  styleUrls: ['./how-to-buy.component.scss']
})
export class HowToBuyComponent implements OnInit {

  config:ICMSConfig = {
    apiPath: 'HowToBuy',
    pathUrl: '/cms/home/how-to-buy',
    tableName: 'Content',
    model: new Home(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.howToBuy',
    formConfig: {
      howToBuyImage1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'How to buy Image',
        description: 'รูปภาพ How to buy',
      },
    },
  }


  constructor() { }

  ngOnInit(): void {
  }
}
