<block-ui> </block-ui>
<div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
    <app-loading></app-loading>
</div>
<div *ngIf="!isLoading" class="row">
    <div class="col-md-12">
        <form [formGroup]="registerForm">
            <h4 class="mb-2">{{ componentName | translate }}</h4>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">
                    {{"ViewTable.ItemName" | translate}}
                </label>
                <div class="col-sm-10" style="padding: 7px;">
                    {{ itemObj ? itemObj.itemName : "-" }}
                </div>
            </div>
            <!-- ===================================== -->
            <div class="form-group row">
                <label class="col-sm-2 col-form-label" >
                    {{ "ViewTable.Status" | translate }}
              
                </label>
                <div class="col-sm-10" style="padding: 7px;"[ngClass]="{
                    'text-success': itemObj.isActive,
                    'text-danger': !itemObj.isActive
                  }">
                    {{ itemObj ? 
                    ((itemObj.isActive 
                    ? "Status.Published" 
                    : "Status.Unpublished") | translate) : "-" }}
                </div>
            </div>
            <!-- ===================================== -->
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">
                    {{ "ViewTable.ReviewDate" | translate }}
                    
                
                </label>
                <div class="col-sm-10" style="padding: 7px;">
                    <span *ngIf="itemObj.reviewDate; else noStartDate">
                        <span *ngIf="currentLang != 'th'" >
                            {{ itemObj.reviewDate | date : "dd MMM YYYY" }}
                        </span>
                        <span *ngIf="currentLang == 'th'" >
                            {{ itemObj.reviewDate | thaidate : "DD MMM YYYY" }}
                        </span>
                    </span>
                    <ng-template #noStartDate>-</ng-template>
                </div>
            </div>
            <!-- ===================================== -->
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">
                    {{"ViewTable.ReviewBy" | translate}}
                    
                
                </label>
                <div class="col-sm-10" style="padding: 7px;">
                    {{ itemObj ? itemObj.reviewBy : "-" }}
                </div>
            </div>
            <!-- ===================================== -->
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">
                    {{"ViewTable.Message" | translate}}
                </label>
                <div class="col-sm-10" style="padding: 7px;">
                    {{ itemObj ? itemObj.message : "-" }}
                </div>
            </div>
            <!-- ===================================== -->

            <br />
            <!-- <div class="form-group d-flex justify-content-between">
                <a class="btn btn-secondary" (click)="openCancelModal()">
                    {{ "Form.Cancel" | translate }}
                </a>

                <a class="btn btn-primary" (click)="openConfirmModal(itemObj)">
                    {{ "Form.Submit" | translate }}
                </a>
            </div> -->
        </form>
    </div>
</div>