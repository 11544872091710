import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'app/auth/service';
import { Token } from 'app/auth/models/token';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  private apiBaseUrl: string;
  private apiPath: string = 'user';
  private httpHeaders: HttpHeaders;

  constructor(
    private http: HttpClient,
    private _apiService: ApiService,
    private _authenticationService: AuthenticationService
  ) {
    this.apiBaseUrl = this._apiService.API_URL;
    // this.httpHeaders = this._apiService.API_HEADERS;

    // this.SetHttpHeaders(this._authenticationService.tokenValue);
  }

  SetHttpHeaders(token: Token): void {
    this.httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token.accessToken}`);
  }

  GetAll(params?: { [key: string]: any }): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}`;

    if (params) {
      let httpParams = new HttpParams();

      Object.keys(params).forEach((key) => {
        httpParams = httpParams.set(key, params[key]);
      });

      return this.http
        .get(API_URL, { headers: this.httpHeaders, params: params })
        .pipe(catchError(this._apiService.handleError));
    }

    return this.http
      .get(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  GetByID(guid: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}/${guid}`;
    return this.http
      .get(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  AddUser(body: object): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}`;
    return this.http
      .post(API_URL, body, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  UpdateByID(guid: string, body: object): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}/${guid}`;
    return this.http
      .put(API_URL, body, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  DeleteByID(guid: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}/${guid}`;
    return this.http
      .delete(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  SetIsActiveStatus(guid: string, status: boolean): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}/${guid}/active/${status}`;
    return this.http
      .patch(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  SoftDelete(guid: string, status: boolean = true): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}/${guid}/delete/${status}`;
    return this.http
      .patch(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  ResetPasswordByID(guid: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${this.apiPath}/resetPassword/${guid}`;
    return this.http
      .patch(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }
}
