import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from 'app/main/components/components.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { ApiService } from 'app/main/service/api.service';
import { SettingsApiService } from 'app/main/service/settings-api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-document-info',
  templateUrl: './document-info.component.html',
  styleUrls: ['./document-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentInfoComponent implements OnInit {
  formGroup: FormGroup;
  formDisabled: boolean = true;

  currentLang: string;

  isLoadingProvince: boolean = false;
  isLoadingDistrict: boolean = false;
  isLoadingSubDistrict: boolean = false;

  provinceList: any[] = [];
  districtList: any[] = [];
  subDistrictList: any[] = [];

  provinceName: string;
  districtName: string;
  subDistrictName: string;

  isSubmit: boolean = false;

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _modalService: NgbModal,
    private _componentsService: ComponentsService,
    private _translateService: TranslateService,
    private _settingsApiService: SettingsApiService
  ) {
    this.formGroup = this._formBuilder.group({
      taxId: [
        { value: '', disabled: this.formDisabled },
        [Validators.required],
      ],
      name: [{ value: '', disabled: this.formDisabled }, [Validators.required]],
      phone: [
        { value: '', disabled: this.formDisabled },
        [Validators.required],
      ],
      email: [
        { value: '', disabled: this.formDisabled },
        [Validators.required],
      ],
      website: [
        { value: '', disabled: this.formDisabled },
        [Validators.required],
      ],
      addressNumber: [
        { value: '', disabled: this.formDisabled },
        [Validators.required],
      ],
      provinceId: [
        { value: '', disabled: this.formDisabled },
        [Validators.required],
      ],
      districtId: [
        { value: '', disabled: this.formDisabled },
        [Validators.required],
      ],
      subDistrictId: [
        { value: '', disabled: this.formDisabled },
        [Validators.required],
      ],
      postCode: [{ value: '', disabled: true }],
    });

    this.currentLang = this._translateService.currentLang;
    this.getProvince();
    this.setFormValue();
  }

  get f() {
    return this.formGroup.controls;
  }

  toggleFormState() {
    this.formDisabled = !this.formDisabled;
    const state = this.formDisabled ? 'disable' : 'enable';

    Object.keys(this.formGroup.controls).forEach((controlName) => {
      this.formGroup.controls[controlName][state]();
    });
  }

  setFormValue() {
    this.blockUI.start();
    this._settingsApiService.GetGeneralSettings().subscribe((res) => {
      let tempData = res.data.resultData[0];

      this.formGroup.patchValue({
        taxId: tempData.taxId,
        name: tempData.name,
        phone: tempData.phone,
        email: tempData.email,
        fax: tempData.fax,
        website: tempData.website,
        addressNumber: tempData.addressNumber,
        provinceId: tempData.provinceId,
        districtId: tempData.districtId,
        subDistrictId: tempData.subDistrictId,
        postCode: tempData.postCode,
      });

      this.getProvince();
      this.getDistrict(tempData.provinceId);
      this.getSubDistrict(tempData.districtId);
      this.blockUI.stop();
    });
  }

  openConfirmModal() {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.title = this._translateService.instant(
      'Settings.Modal.GeneralConfirmTitle'
    );
    modalRef.componentInstance.detail = this._translateService.instant(
      'Settings.Modal.GeneralConfirmDetail'
    );
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.submit();
    });
  }

  submit() {
    this.blockUI.start();
    this._settingsApiService
      .UpdateGeneralSettings(this.formGroup.value)
      .subscribe((res) => {
        this.toggleFormState();
        this.blockUI.stop();
        this._componentsService.SuccessSwal();
      });
  }

  ngOnInit(): void {
    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }

  getProvince(): Promise<any> {
    this.isLoadingProvince = true;

    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterProvince`, {
          SortPath: 'provinceName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.provinceList = res.data.resultData;
          this.isLoadingProvince = false;

          console.log('getProvince');
        }, rejects);
    });
  }

  isSelectProvice(id: string) {
    this.formGroup.get('districtId').setValue(null);
    this.formGroup.get('subDistrictId').setValue(null);
    this.formGroup.get('postCode').setValue(null);

    this.districtList = [];
    this.subDistrictList = [];
    this.getDistrict(id);
    let tempProvice = this.provinceList.find((val) => (val.id = id));
    this.provinceName = tempProvice.provinceName;
  }

  getDistrict(id): Promise<any> {
    this.isLoadingDistrict = true;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterDistrict`, {
          ProvinceId: id,
          SortPath: 'districtName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.districtList = res.data.resultData;
          this.isLoadingDistrict = false;
        }, rejects);
    });
  }

  isSelectDistrict(id: string) {
    this.formGroup.get('subDistrictId').setValue(null);
    this.formGroup.get('postCode').setValue(null);
    this.subDistrictList = [];
    this.getSubDistrict(id);
    let tempDistrict = this.districtList.find((val) => (val.id = id));
    this.districtName = tempDistrict.districtName;
  }

  getSubDistrict(id): Promise<any> {
    this.isLoadingSubDistrict = true;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`MasterSubDistrict`, {
          districtId: id,
          SortPath: 'subDistrictName',
          Direction: 0,
        })
        .subscribe((res) => {
          this.subDistrictList = res.data.resultData;
          this.isLoadingSubDistrict = false;

          let tempSubDistrict = this.subDistrictList.find(
            (val) => (val.id = id)
          );
          console.log(tempSubDistrict);
          this.subDistrictName = tempSubDistrict.subDistrictName;
        }, rejects);
      return true;
    });
  }

  getPostCode(event: any): Promise<any> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetDataById(`MasterSubDistrict`, event)
        .subscribe((res) => {
          console.log(res.data.resultData[0].subDistrictPostCode);
          this.formGroup.patchValue({
            postCode: res.data.resultData[0].subDistrictPostCode,
          });
        }, rejects);
    });
  }
}
