import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';

@Component({
  selector: 'app-suitable-for',
  templateUrl: './suitable-for.component.html',
  styleUrls: ['./suitable-for.component.scss']
})
export class SuitableForComponent implements OnInit {
  config:ICMSConfig = {
    apiPath: 'SuitableFor',
    pathUrl: '/cms/home/suitable-for',
    tableName: 'Content',
    model: new Home(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.suitableFor',
    formConfig: {
      suitableImage: {
        type: FormType.SingleImage,
        // validator: [Validators.required],
        label: 'Suitable Image',
        description: 'รูปภาพ Suitable',
      },

      suitableText:{
        type: FormType.RichText,
        label: 'Suitable List',
        description: 'คำอธิบาย'
      }
    },
  }


  constructor() { }

  ngOnInit(): void {
  }

}
