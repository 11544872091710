<div class="paper-tool-btn container-xxl">
    <button class="btn btn-secondary" (click)="goBackPage()">
        {{ "General.Back" | translate }}
    </button>

    <div>
        {{ "General.PrintShippingLabel" | translate }} : {{ quotationObj ? quotationObj[docCodeColumn] : "-" }}
    </div>

    <div>
        <!-- <button
        class="btn mr-1"
        [ngClass]="{
          'btn-outline-primary': showPaperTool,
          'btn-dark': !showPaperTool
        }"
        (click)="showPaperTool = !showPaperTool"
      >
        <i data-feather="settings"></i>
        {{ "DocumentPaper.FixedPage" | translate }}
      </button> -->
        <button class="btn btn-warning" (click)="printWindow()">
            <i data-feather="printer"></i>
            {{ "DocumentPaper.Print" | translate }}
        </button>
    </div>
</div>

<table class="paper">
    <tr>
        <td colspan="4" style="height: 120px;">

            <app-barcode-generator [value]="727997677993"></app-barcode-generator>

            <!-- <ng-container *ngTemplateOutlet="textBox;
            context: {text: 'Barcode', align: 'center', fontWeight: 'bold'}">
            </ng-container> -->


        </td>
    </tr>

    <tr>
        <td colspan="4" style="height: 50px;">
            <ng-container *ngTemplateOutlet="textBox;
            context: {text: 'L1 39-12 003', align: 'center', fontSize: '32px', fontWeight: 'bold'}">
            </ng-container>
        </td>
    </tr>

    <tr style="vertical-align:top;">
        <td colspan="2" style="height: 70px; width: 70%;">
            <ng-container *ngTemplateOutlet="SenderAddress;">
            </ng-container>
        </td>

        <td colspan="2">
            <ng-container *ngTemplateOutlet="textBox;
            context: {text: ''}">
            </ng-container>
        </td>
    </tr>

    <tr style="vertical-align:top;">
        <td colspan="2" style="height: 80px; width: 700%;">
            <ng-container *ngTemplateOutlet="CustomerAddress;">
            </ng-container>
        </td>

        <td colspan="2">
            <img src=".\assets\qrcode.png">
        </td>
    </tr>

    <tr>
        <td style="height: 40px; width: 40%;">
            <ng-container
                *ngTemplateOutlet="textBox;
            context: {text: 'ชำระโดยไม่ใช้เงินสด', fontSize: '14px', fontWeight: 'bold' ,text2: ' ผู้ซื้อไม่ต้องชำระเงินกับพนักงานขนส่ง', fontSize2: '10px'}">
            </ng-container>
        </td>

        <td style="width: 30%;">
            <ng-container *ngTemplateOutlet="textBox;
            context: {text: 'Weight: 0.030 KG', align: 'center', fontSize: '14px'}">
            </ng-container>
        </td>

        <td style="vertical-align: top;" colspan="2">
            <ng-container *ngTemplateOutlet="textBox;
            context: {text: 'Signature:', fontSize: '12px'}">
            </ng-container>
        </td>
    </tr>
    <ng-container *ngTemplateOutlet="Footer" ;>
    </ng-container>
    <tr>

    </tr>
</table>



<ng-template #textBox let-text="text" let-text2="text2" let-align="align" let-fontSize="fontSize"
    let-fontSize2="fontSize2" let-fontWeight="fontWeight">
    <div>
        <p class="text-dark" [ngStyle]="{'text-align': align, 'font-size': fontSize, 'font-weight': fontWeight}">
            {{text}}
            <span [ngStyle]="{'font-size': fontSize2}">
                {{text2}}
            </span>
        </p>
    </div>
</ng-template>

<ng-template #CustomerAddress>
    <div>
        <p style="text-align: left">
            ถึง {{ quotationObj ? quotationObj.customerName : "-" }}
            <span style="float: right">
                {{ quotationObj ? quotationObj.phone : "-" }}
            </span>
            <br>
            <span>
                {{
                quotationObj ? quotationObj.shippingAddressDetail : "-"
                }}
            </span>
        </p>
    </div>
</ng-template>

<ng-template #SenderAddress>
    <div>
        <p style="text-align: left">
            จาก {{ quotationObj ? quotationObj.issuerName : '-'}}
            <span style="float: right">
                {{ quotationObj ? quotationObj.issuerPhone : '-' }}
            </span>
            <br>
            <span>
                {{ quotationObj ? quotationObj.issuerAddressDetail : '-' }}
            </span>
        </p>
    </div>
</ng-template>

<ng-template #Footer>
    <td style="height: 40px; width: 40%;">
        <p>Order ID:</p>
        {{ quotationObj ? quotationObj[docCodeColumn] : "-" }}
    </td>

    <td colspan="2" style="width: 30%;">
        <p>Ship by time:</p>
        {{
        quotationObj
        ? (quotationObj.documentCreateDate | date : "d/M/yyyy")
        : "-"
        }}
    </td>

    <td style="width: 20%; vertical-align: middle;">
        <ng-container *ngTemplateOutlet="textBox;
        context: {text: 'PICK-UP', align: 'center', fontSize: '18px', fontWeight: 'bold'}">
        </ng-container>
    </td>
</ng-template>