import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';
import { CustomerService } from 'app/main/pages/customer/customer.service';
import { CustomerReviewService } from './customer-review.service';

@Component({
  selector: 'app-customer-review',
  templateUrl: './customer-review.component.html',
  styleUrls: ['./customer-review.component.scss']
})
export class CustomerReviewComponent implements OnInit {
 config!: ICMSConfig;
 keyName: String;
  constructor(private _customerReviewService: CustomerReviewService) {
    this.config=this._customerReviewService.config;
    this.keyName=this._customerReviewService.keyName
   }

  ngOnInit(): void {
  }

}
