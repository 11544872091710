import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';

@Injectable({
  providedIn: 'root'
})
export class HowToUseService {
  config: ICMSConfig = {
    apiPath: 'HowToUse',
    pathUrl: '/cms/home/how-to-use',
    tableName: 'Content',
    model: new Home(),
    isList: true,
    componentName: 'BreadCrumbs.CMS.howToUse',
    formConfig: {
      howToUseImage1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'How to use Image',
        description: 'รูปภาพวิธีการใช้',
      }, 
      title: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Name of Description',
        description: 'หัวข้อคำอธิบาย',
      },
      detail: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'How to use Description',
        description: 'คำอธิบายวิธีการใช้',
      },

      isActive: {
        type: FormType.IsActive,
        label: 'Active',
        description: 'เปิดใช้งาน'
      }
    },
  }

  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'title';

  constructor() { }
}
