<div class="row">
  <ng-container
    *ngTemplateOutlet="
      totalCard;
      context: { unit: 'บาท', value: 500, detail: 'ยอดขายทั้งหมด', icon: 'user' }
    "
  ></ng-container>

  <ng-container
    *ngTemplateOutlet="
      totalCard;
      context: { unit: 'บาท', value: 1500, detail: 'ยอดขายทั้งหมด 2', icon: 'user' }
    "
  ></ng-container>

  <ng-container
    *ngTemplateOutlet="
      totalCard;
      context: { unit: 'บาท', value: 2500, detail: 'ยอดขายทั้งหมด 3', icon: 'user' }
    "
  ></ng-container>
</div>

<div style="background: #f0f0f0" class="col-md-12 form-group border">
  <div class="d-flex p-1 justify-content-between align-items-center">
    <b class="title-customer"
      ><i [size]="22" class="feather_title mr-1" data-feather="user"></i
      >Downline</b
    >
  </div>
</div>

<table class="table" datatable [dtOptions]="dtOptions">
  <thead>
    <tr>
      <th class="text-center" width="5%">#</th>
      <th width="10%">Code</th>
      <th width="30%">Name</th>
      <th class="text-center" width="5%">Role</th>
      <th class="text-center" width="15%">SV</th>
      <th class="text-center" width="15%">PS</th>
    </tr>
  </thead>
  <tbody>
    <tr class="w-100" *ngIf="isLoading">
      <td class="text-center" *ngFor="let num of [].constructor(6)">
        <div class="skeleton-loading"></div>
      </td>
    </tr>

    <tr *ngIf="downlineList.length < 1 && !isLoading">
      <td colspan="100%" class="text-center">
        <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
      </td>
    </tr>

    <tr *ngFor="let downline of downlineList; index as ind">
      <td class="text-center">
        {{ ind + 1 }}
      </td>
      <td>
        <a [routerLink]="[detailPathURL + '/' + downline.id]" target="_blank">
          {{ downline.repoveCode }}
        </a>
      </td>
      <td>
        <a
          class="text-secondary"
          [routerLink]="[detailPathURL + '/' + downline.id]"
          target="_blank"
        >
          {{ downline.detail }}
        </a>
      </td>
      <td class="text-center">MP</td>
      <td class="text-end">0.00</td>
      <td class="text-end">0.00</td>
    </tr>
  </tbody>
</table>

<ng-template
  #totalCard
  let-unit="unit"
  let-value="value"
  let-detail="detail"
  let-icon="icon"
>
  <div class="col-lg-4 col-sm-6 col-12 d-grid">
    <div class="card" style="border: 1px solid #a7a7a7">
      <div class="card-header my-auto flex-nowrap">
        <div>
          <h2 class="font-weight-bolder mb-0">
            {{ value | number : '1.2-2' }} {{ unit }}
          </h2>
          <p class="card-text">{{ detail }}</p>
        </div>
        <i [data-feather]="icon" size="22" class="text-dark"></i>
      </div>
    </div>
  </div>
</ng-template>
