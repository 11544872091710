<div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
  <app-loading></app-loading>
</div>

<div class="card" *ngIf="!isLoading">
  <div class="card-body">
    <section id="detail-section">
      <div class="d-flex">
        <div class="col-12 col-xl-4 text-center">
          <div class="mt-2">
            <img
              [src]="profileIMG ? profileIMG : coreConfig.app.defaultIMG"
              alt=""
              style="
                object-fit: cover;
                aspect-ratio: 1/1;
                max-height: 200px;
                max-width: 200px;
                border-radius: 5px;
              "
            />
          </div>

          <div class="d-flex">
            <div class="mx-auto my-1">
              <label class="btn btn-primary mb-0" for="change-picture">
                <span class="d-none d-sm-block">
                  {{ "General.Change" | translate }}
                </span>
                <input
                  class="form-control"
                  type="file"
                  id="change-picture"
                  [(ngModel)]="imageBase64"
                  hidden
                  accept="image/png, image/jpeg, image/jpg"
                  (change)="
                    uploadProfilePicture($event, changeProfileImageModal)
                  "
                />
                <span class="d-block d-sm-none">
                  <i class="mr-0" data-feather="edit"></i>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-8">
          <div class="row my-1">
            <th class="col-md-4 col-sm-3 col-12 primary-color">
              {{ "User.Username" | translate }}:
            </th>
            <td class="col-md-8 col-sm-9 col-12">
              {{ this.user.username ? this.user.username : "-" }}
            </td>
          </div>
          <div class="row my-1">
            <th class="col-md-4 col-sm-3 col-12 primary-color">
              {{ "User.Name" | translate }}:
            </th>
            <td class="col-md-8 col-sm-9 col-12">
              {{ this.user.firstName ? this.user.firstName : "-" }}
              {{ this.user.lastName ? this.user.lastName : "-" }}
            </td>
          </div>
          <div class="row my-1">
            <th class="col-md-4 col-sm-3 col-12 primary-color">
              {{ "User.Email" | translate }}:
            </th>
            <td class="col-md-8 col-sm-9 col-12">
              {{ this.user.email ? this.user.email : "-" }}
            </td>
          </div>
          <div class="row my-1">
            <th class="col-md-4 col-sm-3 col-12 primary-color">
              {{ "User.Phone" | translate }}:
            </th>
            <td class="col-md-8 col-sm-9 col-12">
              {{ this.user.phone ? this.user.phone : "-" }}
            </td>
          </div>
          <div class="row my-1">
            <th class="col-md-4 col-sm-3 col-12 primary-color">
              {{ "User.Role" | translate }}:
            </th>
            <td class="col-md-8 col-sm-9 col-12">
              <app-role-badge
                [roleSetName]="
                  this.user.roleSetName ? this.user.roleSetName : '-'
                "
              ></app-role-badge>
            </td>
          </div>

          <div class="row my-1" *ngIf="this.user.isCompanyUser">
            <th class="col-md-4 col-sm-3 col-12 primary-color">
              {{ "User.Organization" | translate }}:
            </th>
            <td class="col-md-8 col-sm-9 col-12">
              {{ this.user.organization ? this.user.organization : "-" }}
            </td>
          </div>

          <div class="row my-1" *ngIf="this.user.isCompanyUser">
            <th class="col-md-4 col-sm-3 col-12 primary-color">
              {{ "User.Branch" | translate }}:
            </th>
            <td class="col-md-8 col-sm-9 col-12">
              {{ this.user.branch ? this.user.branch : "-" }}
            </td>
          </div>

          <div class="row my-1" *ngIf="this.user.isCompanyUser">
            <th class="col-md-4 col-sm-3 col-12 primary-color">
              {{ "User.Department" | translate }}:
            </th>
            <td class="col-md-8 col-sm-9 col-12">
              {{ this.user.department ? this.user.department : "-" }}
            </td>
          </div>

          <div class="row my-1" *ngIf="this.user.isCompanyUser">
            <th class="col-md-4 col-sm-3 col-12 primary-color">
              {{ "User.Position" | translate }}:
            </th>
            <td class="col-md-8 col-sm-9 col-12">
              {{ this.user.position ? this.user.position : "-" }}
            </td>
          </div>

          <div class="row my-1">
            <th class="col-md-4 col-sm-3 col-12 primary-color">
              {{ "User.CreateOn" | translate }}:
            </th>
            <td class="col-md-8 col-sm-9 col-12">
              <!-- {{ this.user.createDate ? (this.user.createDate | date) : "-" }} -->
              {{
                this.user.createDate
                  ? currentLang == "th"
                    ? (this.user.createDate | thaidate : "DD MMMM YYYY")
                    : (this.user.createDate | date : "dd MMMM YYYY")
                  : "-"
              }}
            </td>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<ng-template #changeProfileImageModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">
      Change Profile Picture
    </h5>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center" tabindex="0" ngbAutofocus>
    <div class="" style="object-fit: cover">
      <img
        [src]="previewIMG"
        alt=""
        width="200"
        height="200"
        style="
          object-fit: cover;
          max-height: 200px;
          max-width: 200px;
          border-radius: 10px;
        "
      />
    </div>
    <p class="mt-1">{{ previewIMGName }}</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-dark"
      (click)="modal.close()"
      rippleEffect
    >
      {{ "Form.Cancel" | translate }}
    </button>
    <button
      type="button"
      ngSubmit
      (click)="SaveProfilePicture()"
      class="btn btn-primary"
      rippleEffect
    >
      {{ "Form.Submit" | translate }}
    </button>
  </div>
</ng-template>
