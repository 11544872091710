import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReviewManageService {

  componentName: string = 'General.Review';
  apiUrl: string = 'ItemReview';
  pathUrl: string = '/review-manage/review';

  
  review = {
    ApiPath: 'ItemReview',
    PathURL: '/review-manage/review',
    EditPathURL: '/review-manage/review/edit',
    DetailPathURL: '/review-manage/review/detail',
    DetailExport: '/review-manage/review/detail/export',
    ComponentsName: 'Review',
  };

  constructor() { }
}
