import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';

@Injectable({
  providedIn: 'root'
})
export class IngredientsService {
  config:ICMSConfig = {
    apiPath: 'Ingredients',
    pathUrl: '/cms/home/home-ingredients',
    tableName: 'Content',
    model: new Home(),
    isList: true,
    componentName: 'BreadCrumbs.CMS.ingredients',
    formConfig: {
      title:{
        type:FormType.Text,
        validator: [Validators.required],
        label: 'Ingredients Name',
        description:'ชื่อ Ingredients'
      },
      ingredientsImage: {
        type: FormType.SingleImage,
        // validator: [Validators.required],
        label: 'Ingredients Image',
        description: 'รูปภาพ Ingredients',
      },
      detail:{
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Ingredients Description',
        description:'คำอธิบาย'
      },
      isActive: {
        type: FormType.IsActive,
        label: 'Active',
        description: 'เปิดใช้งาน'
        
      }
      
    },
  }
  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'title';

  constructor() { }
}
