import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { AuthGuard } from 'app/auth/helpers';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';
import { HomeLandingComponent } from './home-landing/home-landing.component';
import { IngredientsComponent } from './ingredients/ingredients.component';

import { SuitableForComponent } from './suitable-for/suitable-for.component';
import { CmsListModule } from '../cms-list/cms-list.module';
import { HowToUseComponent } from './how-to-use/how-to-use.component';
import { CustomerReviewComponent } from './customer-review/customer-review.component';
import { IngredientsCreateComponent } from './ingredients/ingredients-create/ingredients-create.component';
import { HowToUseCreateComponent } from './how-to-use/how-to-use-create/how-to-use-create.component';
import { CustomerReviewCreateComponent } from './customer-review/customer-review-create/customer-review-create.component';
import { FAQCreateComponent } from './faq/faq-create/faq-create.component';
import { FAQComponent } from './faq/faq.component';
import { HowToBuyComponent } from './how-to-buy/how-to-buy.component';

const path: string = 'cms/home';

const routes: Routes = [
  {
    path: path,
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-home' },
  },
  {
    path: `${path}/home-landing`,
    component: HomeLandingComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-landing' },
  },
  {
    path: `${path}/home-ingredients`,
    component: IngredientsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-ingredients' },
  },
  {
    path: `${path}/home-ingredients/create`,
    component: IngredientsCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-ingredients-create' },
  },
  {
    path: `${path}/home-ingredients/edit/:id`,
    component: IngredientsCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-ingredients-edit' },
  },
  {
    path: `${path}/suitable-for`,
    component: SuitableForComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-suitable-for' },
  },
  {
    path: `${path}/how-to-use`,
    component: HowToUseComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-how-to-use' },
  },
  {
    path: `${path}/how-to-use/create`,
    component: HowToUseCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-how-to-use-create' },
  },
  {
    path: `${path}/how-to-use/edit/:id`,
    component: HowToUseCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-how-to-use-edit' },
  },

  {
    path: `${path}/customer-review`,
    component: CustomerReviewComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-how-to-use' },
  },
  {
    path: `${path}/customer-review/create`,
    component: CustomerReviewCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-how-to-use-create' },
  },
  {
    path: `${path}/customer-review/edit/:id`,
    component: CustomerReviewCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-how-to-use-edit' },
  },
  {
    path: `${path}/how-to-buy`,
    component: HowToBuyComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-how-to-buy' },
  },
  {
    path: `${path}/faq`,
    component: FAQComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-faq' },
  },
  {
    path: `${path}/faq/create`,
    component: FAQCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-faq-create' },
  },
  {
    path: `${path}/faq/edit/:id`,
    component: FAQCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'home-faq-edit' },
  },
];

@NgModule({
  declarations: [
    HomeComponent,
    HomeLandingComponent,
    IngredientsComponent,
    SuitableForComponent,
    HowToUseComponent,
    CustomerReviewComponent,
    IngredientsCreateComponent,
    CustomerReviewCreateComponent,
    HowToUseCreateComponent,
    FAQComponent,
    FAQCreateComponent,
    HowToBuyComponent,
  ],
  exports: [HomeComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CmsFormpageModule,
    CmsListModule,
  ],
})
export class HomeModule {}

//test
