import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { ComponentsService } from '../../../components/components.service';
import { ContentListComponent } from '../../../components/content-list/content-list.component';
import { ApiService } from '../../../service/api.service';
import { Subject, Subscription } from 'rxjs';
import { SubcategoryService } from './subcategory.service';
import SubCategory from '../../../model/SubCategory';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.scss'],
})
export class SubcategoryTemplateComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription;
  componentName: string;

  apiPath: string;
  pathUrl: string;

  contentHeader: object;
  itemList: SubCategory[];

  isLoading: boolean;
  isGridView: boolean = false;
  isShowChildList: boolean = false;
  isForGroupTemplate: boolean = false;
  isGoEditPage: boolean = false;

  activeStatus: string;
  searchText: string;
  searchData: { [k: string]: any } = {};

  public page = 1;
  public pageSize = 10;

  @ViewChild(ContentListComponent) contentListTable: ContentListComponent;

  // @ViewChild(DataTableDirective, { static: false })
  // datatableElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  // dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _apiService: ApiService,
    private _subCategoryService: SubcategoryService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService
  ) {
    this.SetLoadingState();
    this.initConfig();
    this.initData();
  }

  initConfig(): void {
    this.componentName = this._subCategoryService.componentName;
    this.apiPath = this._subCategoryService.apiUrl;
    this.pathUrl = this._subCategoryService.pathUrl;
  }

  initData(obj?: { [key: string]: any }): void {
    this.SetLoadingState();
    this.itemList = [];

    if (obj) {
      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = {
          SortPath: 'Name',
          Direction: 0,
          isDelete: false,
        };
      } else {
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = { SortPath: 'Name', Direction: 0, isDelete: false };
    }

    this.searchData.name = this.searchText;
    this.searchData.includeMediaURL = false;
    
    if (this.contentListTable) {
      this.contentListTable.loadData(this.searchData);
    }
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  listView() {
    this.isGridView = false;
  }

  gridView() {
    this.isGridView = true;
  }

  SearchFilter(val: string) {
    this.searchText = val;
    this.SetLoadedState();
  }

  setPageSize(val: number): void {
    this.pageSize = val;
  }

  ActiveStatusFilter(val: string) {
    this.activeStatus = val;
  }

  SortFilter(key: string) {
    var self = this;

    let objKey = JSON.parse(key);
    let SortPath = objKey.SortPath;
    let Direction = objKey.Direction;

    this.SetLoadingState();
    this.initData({
      SortPath: SortPath,
      Direction: Number(Direction),
    });
  }

  exportXLSX() {
    this.contentListTable.ExportXLSX();
  }

  setActiveContent(val): void {
    var self = this;
    this._apiService
      .SetIsActiveData(this.apiPath, val.id, val.status)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          self._componentsService.SuccessSwal();
          self.SetLoadingState();
          self.initData();
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
  }

  deleteContent(guid: string): void {
    var self = this;
    this._apiService
      .SoftDelete(this.apiPath, guid)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          self._componentsService.SuccessSwal();
          self.SetLoadingState();
          self.initData();
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'BreadCrumbs.Manage.SubCategory',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    // this.drawDatatables();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
}
