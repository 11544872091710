import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import Customer from 'app/main/model/Customer';
import { CustomerService } from 'app/main/pages/customer/customer.service';
import { ApiService } from 'app/main/service/api.service';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-downline-detail',
  templateUrl: './downline-detail.component.html',
  styleUrls: ['./downline-detail.component.scss'],
})
export class DownlineDetailComponent implements OnInit {
  downlineList: Customer[] = [];

  private _unsubscribeAll: Subject<any> = new Subject();
  dataSubscription: Subscription;

  searchData: any = {};

  detailPathURL: string;
  customerId: string;

  public page = 1;
  public pageSize = 25;

  public rows;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  isLoading: boolean = false;

  constructor(
    private _apiService: ApiService,
    private _customerService: CustomerService,
    private _route: ActivatedRoute
  ) {
    this.detailPathURL = this._customerService.detailPathURL;

    this._route.paramMap.subscribe((val) => {
      this.customerId = this._route.snapshot.paramMap.get('id');
    });
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }


  ngOnInit(): void {
    var self = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: 10,
      order: [[1, 'asc']],
      ordering: true,
      serverSide: true,
      lengthChange: false,
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: 'RepoveCode', targets: 1 },
        { name: 'Name', targets: 2 },
        { name: 'RoleSetName', targets: 3 },
        { name: 'SV', targets: 5 },
        { name: 'PS', targets: 6 },
        { orderable: false, targets: 7 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.downlineList = [];
        this.SetLoadingState()

        let defaultSortName = 'RepoveCode';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'asc' ? 0 : 1;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.uplineId = this.customerId;
        this.searchData.isActive = true;
        this.searchData.isDelete = false;

        console.log(this.searchData);

        this._apiService
          .GetAllData('Customer', this.searchData)
          .subscribe((response) => {
            console.log(response);
            this.downlineList = response.data.resultData;
            this.SetLoadedState()
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          });
      },
    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
}
