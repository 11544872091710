import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-barcode-generator',
  templateUrl: './barcode-generator.component.html',
  styleUrls: ['./barcode-generator.component.scss']
})
export class BarcodeGeneratorComponent implements OnInit {

  @Input() value: any;
  @Input() displayValue = true;

  constructor() { }

  ngOnInit(): void {
  }

}
