import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Token } from 'app/auth/models/token';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentApiService {
  private apiBaseUrl: string;
  private apiPath: string;
  private httpHeaders: HttpHeaders;

  get API_URL() {
    return this.apiBaseUrl;
  }

  get API_HEADERS() {
    return this.httpHeaders;
  }

  constructor(private http: HttpClient, private _apiService: ApiService) {
    this.apiBaseUrl = this._apiService.API_URL;
    this.httpHeaders = this._apiService.API_HEADERS;
  }

  GetArgumentTransaction(): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/Transactions/Argument`;
    return this.http
      .get(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  GetDocRunningNumber(ApiPath: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/RunningNumber`;
    return this.http
      .get(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  GetDocData(ApiPath: string, DocID: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/${DocID}`;
    return this.http
      .get(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  GetRemainingDocData(ApiPath: string, DocID: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/Remain/Check/${DocID}`;
    return this.http
      .get(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  GetReceipt(DocID: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/GoodsReceive/GotReceipt/${DocID}`;
    return this.http
      .patch(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  VoidGotReceipt(DocID: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/GoodsReceive/Void/GotReceipt/${DocID}`;
    return this.http
      .patch(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  AddDocData(ApiPath: string, body: any): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}`;
    return this.http
      .post(API_URL, body, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  AddTaxInvoice(ApiPath: string, DocID: string, body?: any): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/Register/TaxInvoice/${DocID}`;
    return this.http
      .post(API_URL, body ? body : null, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }
  VoidTaxInvoice(
    ApiPath: string,
    DocID: string,
    params?: any
  ): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/Void/TaxInvoice/${DocID}`;
    return this.http
      .patch(API_URL, params ? params : null, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  UpdateDocData(ApiPath: string, body: any, DocID: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/${DocID}`;
    return this.http
      .put(API_URL, body, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  ApproveDocData(
    ApiPath: string,
    DocID: string,
    params?: any
  ): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/Accepted/${DocID}`;
    return this.http
      .post(API_URL, params ? params : null, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  SetInventoryStatus(ApiPath: string, DocID: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/SetInventoryStatus/${DocID}`;
    return this.http
      .patch(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  SetInventoryCompleteAll(ApiPath: string, DocID: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/SetInventoryStatus/Complete/All/${DocID}`;
    return this.http
      .patch(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  Payment(ApiPath: string, DocID: string, params?: any): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/Pay/${DocID}`;
    return this.http
      .post(API_URL, params ? params : null, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  CashPayment(ApiPath: string, DocID: string, params?: any): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/Cash/Pay/${DocID}`;
    return this.http
      .post(API_URL, params ? params : null, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  TransferPayment(ApiPath: string, DocID: string, params?: any): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/Transfer/Pay/${DocID}`;
    return this.http
      .post(API_URL, params ? params : null, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  GenerateTaxInvoice(DocID: string, params?: any): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/TaxInvoice/issueTaxInvoice/${DocID}`;
    return this.http
      .post(API_URL, params ? params : null, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  GetNetTotal(ApiPath: string, DocID: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/NetTotal/${DocID}`;
    return this.http
      .get(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  GetPaymentHistory(ApiPath: string, DocID: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/Payment/History/${DocID}`;
    return this.http
      .get(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  VoidDocument(ApiPath: string, DocID: string, params?: any): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/Accepted/${DocID}`;
    return this.http
      .patch(API_URL, params ? params : null, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  CancelVoidDocument(
    ApiPath: string,
    DocID: string,
    params?: any
  ): Observable<any> {
    let action = 'Void';
    if (
      ApiPath === 'Quotation' ||
      ApiPath === 'SaleOrder' ||
      ApiPath === 'PurchaseRequisition' ||
      ApiPath === 'PurchaseOrder'
    ) {
      action = 'Cancel';
    }

    let API_URL = `${this.apiBaseUrl}/${ApiPath}/${action}/${DocID}`;
    return this.http
      .patch(API_URL, params ? params : null, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  CancelOrder(
    orderID: string,
    params?: any
  ): Observable<any> {

    let API_URL = `${this.apiBaseUrl}/Order/Cancel/${orderID}`;
    return this.http
      .patch(API_URL, params ? params : null, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  ExportExcel(ApiPath: string, params?: { [key: string]: any }): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/${ApiPath}/ExportExcel`;

    if(params){
      return this.http.get(API_URL, { 
        headers: this.httpHeaders,
        responseType: 'blob',
        params:params
      }).pipe(catchError(this._apiService.handleError));
    }
    
    return this.http.get(API_URL, { 
        headers: this.httpHeaders,
        responseType: 'blob',
      }).pipe(catchError(this._apiService.handleError));
  }
}
