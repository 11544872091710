import { CoreCommonModule } from '@core/common.module';
import { ContentCardComponent } from './content-card/content-card.component';
import { ContentListComponent } from './content-list/content-list.component';
import { BrandFormComponent } from './form/brand-form/brand-form.component';
import { CategoryFormComponent } from './form/category-form/category-form.component';
import { ClassFormComponent } from './form/class-form/class-form.component';
import { ContentFormComponent } from './form/content-form/content-form.component';
import { GroupFormComponent } from './form/group-form/group-form.component';
import { ItemFormComponent } from './form/item-form/item-form.component';
import { SubcategoryFormComponent } from './form/subcategory-form/subcategory-form.component';
import { SubclassFormComponent } from './form/subclass-form/subclass-form.component';
import { UserFormComponent } from './form/user-form/user-form.component';
import { LoadingComponent } from './loading/loading.component';
import { ModalComponent } from './modal/modal.component';
import { SearchPanelComponent } from './search-panel/search-panel.component';
import { CommonModule } from '@angular/common';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NgModule } from '@angular/core';
import { AclFormComponent } from './form/acl-form/acl-form.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { MultiFileUploadComponent } from './multi-file-upload/multi-file-upload.component';
import { DataTablesModule } from 'angular-datatables';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { CustomerFormComponent } from './form/customer-form/customer-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoleBadgeComponent } from './role-badge/role-badge.component';
import { RoleFormComponent } from './form/role-form/role-form.component';
import { FormTemplateComponent } from './form/form-template/form-template.component';
import { CustomerAddrComponent } from './form/customer-form/customer-addr/customer-addr.component';
import { CustomerBankComponent } from './form/customer-form/customer-bank/customer-bank.component';
import { CustomerAccComponent } from './form/customer-form/customer-acc/customer-acc.component';
import { SingleFileUploadComponent } from './single-file-upload/single-file-upload.component';
import { HorizontalTimelineComponent } from './horizontal-timeline/horizontal-timeline.component';
import { AttachedFileUploaderComponent } from './document/attached-file-uploader/attached-file-uploader.component';
import { DocumentStatusBadgeComponent } from './document/document-status-badge/document-status-badge.component';
import { AddBillComponent } from './document/template/add-bill/add-bill.component';
import { DragulaModule } from 'ng2-dragula';
import { ContentDocumentComponent } from './content-document/content-document.component';
import { SearchPanelDocumentComponent } from './search-panel-document/search-panel-document.component';
import { SearchPanelStockComponent } from './search-panel-stock/search-panel-stock.component';
import { BarcodeGeneratorComponent } from './misc/barcode-generator/barcode-generator.component';
import { QrcodeGeneratorComponent } from './misc/qrcode-generator/qrcode-generator.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { NgxBarcodeModule } from 'ngx-barcode';
import { ModalPaymentComponent } from './modal-payment/modal-payment.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DocumentPapersComponent } from './document/template/document-papers/document-papers.component';
import { SelectCreditDebitComponent } from './document/select-credit-debit/select-credit-debit.component';
import { PaymentComponent } from './payment/payment.component';

import { PaymentDetailComponent } from './payment-detail/payment-detail.component';
import { ActionHistoryComponent } from './document/action-history/action-history.component';
import { BlockUIModule } from 'ng-block-ui';
import { ItemSetFormComponent } from './form/item-set-form/item-set-form.component';
import { CustomDropzoneComponent } from './custom-dropzone/custom-dropzone.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddTaxInvoiceComponent } from './content-document/add-tax-invoice/add-tax-invoice.component';
import { AdjustItemModalComponent } from './adjust-item-modal/adjust-item-modal.component';
import { ContentChildDocumentComponent } from './content-child-document/content-child-document.component';
import { InventoryStatusBadgeComponent } from './document/inventory-status-badge/inventory-status-badge.component';
import { CustomerAddrModalComponent } from './form/customer-form/customer-addr-modal/customer-addr-modal.component';
import { ModalManagePaymentComponent } from './modal-manage-payment/modal-manage-payment.component';
import { TransferFormComponent } from './modal-manage-payment/transfer-form/transfer-form.component';
import { ApproveDocModalComponent } from './approve-doc-modal/approve-doc-modal.component';
import { RelatedDocModalComponent } from './related-doc-modal/related-doc-modal.component';
import { SearchPanelFrontendUserComponent } from './search-panel-frontend-user/search-panel-frontend-user.component';
import { DownlineDetailComponent } from './downline-detail/downline-detail.component';
import { ShippingPaperComponent } from './document/template/shipping-paper/shipping-paper.component';
import { PaymentEvidenceComponent } from './payment-evidence/payment-evidence.component';
import { ModalTrackingNumberComponent } from './modal-tracking-number/modal-tracking-number.component';
import { ShippingPaperV2Component } from './document/template/shipping-paper-v2/shipping-paper-v2.component';
import { PromotionFormComponent } from './form/promotion-form/promotion-form.component';
import { SearchPanelCodeManageComponent } from './search-panel-code-manage/search-panel-code-manage.component';
import { ContentCodeManageComponent } from './content-code-manage/content-code-manage.component';
import { SearchPanelReviewManageComponent } from './search-panel-review-manage/search-panel-review-manage.component';
import { ReviewFormComponent } from './form/review-form/review-form.component';
@NgModule({
  declarations: [
    SearchPanelComponent,
    ContentCardComponent,
    ModalComponent,
    LoadingComponent,
    ContentListComponent,
    ItemFormComponent,
    GroupFormComponent,
    CategoryFormComponent,

    BrandFormComponent,
    ContentFormComponent,
    SubcategoryFormComponent,
    SubclassFormComponent,
    ClassFormComponent,
    UserFormComponent,
    AclFormComponent,
    ImageUploadComponent,
    MultiFileUploadComponent,
    CustomerFormComponent,
    RoleBadgeComponent,
    RoleFormComponent,
    FormTemplateComponent,
    CustomerAddrComponent,
    CustomerBankComponent,
    CustomerAccComponent,
    SingleFileUploadComponent,

    ContentDocumentComponent,

    HorizontalTimelineComponent,
    AttachedFileUploaderComponent,
    DocumentStatusBadgeComponent,
    AddBillComponent,
    SearchPanelDocumentComponent,
    SearchPanelStockComponent,
    BarcodeGeneratorComponent,
    QrcodeGeneratorComponent,
    ModalPaymentComponent,
    DocumentPapersComponent,
    SelectCreditDebitComponent,
    PaymentComponent,
    PaymentDetailComponent,
    ActionHistoryComponent,
    ItemSetFormComponent,
    CustomDropzoneComponent,
    AddTaxInvoiceComponent,
    AdjustItemModalComponent,
    ContentChildDocumentComponent,
    InventoryStatusBadgeComponent,
    CustomerAddrModalComponent,
    ModalManagePaymentComponent,
    TransferFormComponent,
    ApproveDocModalComponent,
    RelatedDocModalComponent,
    SearchPanelFrontendUserComponent,
    SearchPanelCodeManageComponent,
    DownlineDetailComponent,
    ShippingPaperComponent,
    PaymentEvidenceComponent,
    ModalTrackingNumberComponent,
    ShippingPaperV2Component,
    PromotionFormComponent,
    ContentCodeManageComponent,
    SearchPanelReviewManageComponent,
    ReviewFormComponent,
    
  ],
  exports: [
    SearchPanelComponent,
    ModalPaymentComponent,
    SearchPanelDocumentComponent,
    ContentCardComponent,
    ModalComponent,
    LoadingComponent,
    ContentListComponent,
    ItemFormComponent,
    GroupFormComponent,
    CategoryFormComponent,

    BrandFormComponent,
    ContentFormComponent,
    SubcategoryFormComponent,
    SubclassFormComponent,
    ClassFormComponent,
    UserFormComponent,
    AclFormComponent,
    ImageUploadComponent,
    MultiFileUploadComponent,
    CustomerFormComponent,
    RoleBadgeComponent,
    RoleFormComponent,
    FormTemplateComponent,
    CustomerAddrComponent,
    CustomerBankComponent,
    ContentDocumentComponent,
    HorizontalTimelineComponent,
    AttachedFileUploaderComponent,
    DocumentStatusBadgeComponent,
    AddBillComponent,
    BarcodeGeneratorComponent,
    ModalPaymentComponent,
    QrcodeGeneratorComponent,
    DocumentPapersComponent,
    SelectCreditDebitComponent,
    PaymentComponent,
    PaymentDetailComponent,
    ActionHistoryComponent,
    ItemSetFormComponent,
    CustomDropzoneComponent,
    NgSelectModule,
    ContentChildDocumentComponent,
    InventoryStatusBadgeComponent,
    ModalManagePaymentComponent,
    TransferFormComponent,
    SearchPanelFrontendUserComponent,
    SearchPanelCodeManageComponent,
    DownlineDetailComponent,
    ShippingPaperComponent,
    PaymentEvidenceComponent,
    ShippingPaperV2Component,
    ContentCodeManageComponent,
    PromotionFormComponent,
    SearchPanelReviewManageComponent,
    ReviewFormComponent
  ],
  imports: [
    FormsModule,
    CoreCommonModule,
    CommonModule,
    CorePipesModule,
    RouterModule,
    NgbModule,
    TranslateModule,
    NgxDropzoneModule,
    NgxSummernoteModule,
    DataTablesModule,
    Ng2FlatpickrModule,
    ReactiveFormsModule,
    DragulaModule.forRoot(),
    FormsModule,
    NgxQRCodeModule,
    NgxBarcodeModule,
    NgSelectModule,
    DragDropModule,
    BlockUIModule.forRoot(),
    
  ],
})
export class ComponentsModule {}
