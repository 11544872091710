<div class="modal-header">
  <h5 class="modal-title" id="exampleModalCenterTitle">
    {{ title }}
  </h5>
  <button type="button" class="close" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">
      <i data-feather="x" size="22"></i>
    </span>
  </button>
</div>
<form [formGroup]="trackingForm">
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div>
      <label for="trackingNumber">{{ title }}</label>
      <input class="form-control" type="text"  formControlName="trackingNumber" >
    </div>
   <div class="mt-1">
      <label for="">{{ DeliveryProvider }}</label>
      <ng-select [clearable]="false" [searchable]="false" 
      formControlName="DeliveryProviderId"
      >
        <ng-option value="{{Delivery.id}}" *ngFor="let Delivery of Deliverys" >{{currentLang == 'th' ? Delivery.nameTh : Delivery.nameEn}}</ng-option>
      </ng-select>
   </div>
  </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-secondary m-0" (click)="closeModal()">
      {{ "Form.Cancel" | translate }}
    </button>
    <button type="submit" class="btn btn-primary m-0" (click)="Submit()">
      {{ "Form.Submit" | translate}}
    </button>
  </div>
</form>