import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { FAQService } from '../faq.service';

@Component({
  selector: 'app-faq-create',
  templateUrl: './faq-create.component.html',
  styleUrls: ['./faq-create.component.scss']
})
export class FAQCreateComponent implements OnInit, OnDestroy {
  config: ICMSConfig

  constructor(private _faqService: FAQService) {
    this.config = this._faqService.config;
   }

  ngOnInit(): void {
  }
  
  ngOnDestroy(): void {}
}
