import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ComponentsService } from 'app/main/components/components.service';
import { ContentListComponent } from 'app/main/components/content-list/content-list.component';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import News from 'app/main/model/News';
import { ApiService } from 'app/main/service/api.service';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cms-list',
  templateUrl: './cms-list.component.html',
  styleUrls: ['./cms-list.component.scss'],
})
export class CmsListComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;
  private dataSubscription: Subscription;

  @Input() config!: ICMSConfig;
  @Input() keyName: string;

  componentName: string;
  apiPath: string;
  pathUrl: string;

  contentHeader: object;
  contentList: any[];

  isLoading: boolean;
  isGridView: boolean;
  isGoEditPage: boolean = true;
  isForGroupTemplate: boolean = false;
  activeStatus: string;
  searchText: string;
  searchData: { [k: string]: any } = {};

  public page = 1;
  public pageSize = 10;

  @ViewChild(ContentListComponent) contentListTable: ContentListComponent;

  constructor(
    private _apiService: ApiService,
    private _componentsService: ComponentsService
  ) {}

  initData(obj?): void {
    this.SetLoadingState();
    this.contentList = [];

    if (obj) {
      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = {
          sortPath: this.keyName,
          direction: 1,
          isDelete: false,
        };
      } else {
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = {
        sortPath: this.keyName,
        direction: 1,
        isDelete: false,
      };
    }

    this.searchData.name = this.searchText;
    this.searchData.verbose = false;
    this.searchData.includeMediaURL = false;

    if (this.contentListTable) {
      this.contentListTable.loadData(this.searchData);
    }
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  listView() {
    this.isGridView = false;
  }

  gridView() {
    this.isGridView = true;
  }

  SearchFilter(val: string) {
    this.searchText = val;
    this.isLoading = false;
  }

  ActiveStatusFilter(val: string) {
    this.activeStatus = val;
    console.log(val);
  }

  SortFilter(key: string) {
    let objKey = JSON.parse(key);
    let sortPath = objKey.sortPath;
    let direction = objKey.direction;
  }

  exportXLSX() {
    this.contentListTable.ExportXLSX();
  }

  setPageSize(val: number): void {
    this.pageSize = val;
    console.log(this.pageSize);
  }

  openModal(modal: any): void {}

  setActiveContent(val): void {
    this._apiService
      .SetIsActiveData(this.apiPath, val.id, val.status)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this.isLoading = true;
          this.initData();
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
  }

  deleteContent(guid: string): void {
    console.log('delele Complete' + guid);
    this._apiService
      .SoftDelete(this.apiPath, guid)
      .pipe()
      .subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this.isLoading = true;
          this.initData();
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
  }

  ngOnInit(): void {
    this.isLoading = true;

    this._unsubscribeAll = new Subject();
    this.componentName = this.config.componentName;
    this.apiPath = this.config.apiPath;
    this.pathUrl = this.config.pathUrl;

    this.contentHeader = {
      headerTitle: this.componentName,
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this.initData();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
}
