import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { TempRowQuotation } from 'app/main/model/Quotation';
import { DocumentService } from 'app/main/pages/document/document.service';
import { ApiService } from 'app/main/service/api.service';
import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { ModalPaymentComponent } from '../modal-payment/modal-payment.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsService } from '../components.service';
import { DocumentStatus } from 'app/main/model/DocumentStatus';
import { PaymentMethodList } from 'app/main/model/PaymentMethod';

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss'],
})
export class PaymentDetailComponent implements OnInit {
  contentHeader: object;
  currentUser: User;
  receipt: any[] = [];
  currentLang: string;
  @BlockUI() blockUI: NgBlockUI;
  @Input() titleDoc: string;
  @Input() refDocID: string;
  @Input() refDetailUrl: string;
  @Input() typeDocName: string;
  @Input() detailObj: any;
  @Input() docId: string;
  @Input() apiPath: string;
  @Input() detailPathUrl: string;
  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  @Input() componentName: string;
  @Input() isExpenses: boolean = false;
  @Input() isCreditNote: boolean = false;
  @Input() isDebitNote: boolean = false;
  @Input() isPurchaseOrder: boolean = false;

  sumNetPrice: number;
  sumVatPrice: number;
  isLoading: boolean;

  documentStatus = DocumentStatus;

  paymentDetail: any;
  receiptList: any[] = [];
  creditNoteList: any[] = [];
  goodsReceiveList: any[] = [];
  debitNoteList: any[] = [];

  mergedArray: any[] = [];
  sortedItems: any[] = [];

  receiptCounter: number = 0;
  creditNoteCounter: number = 0;

  isJoinIV_TX_RE: boolean = false;
  isPaymentModalOpening: boolean = false;

  detailRCPath: string;

  constructor(
    private _documentService: DocumentService,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _documentApiService: DocumentApiService,
    private _route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private _apiService: ApiService,
    private _calculateService: CalculateFuncService,
    private _globalFuncService: GlobalFuncService,
    private _modalService: NgbModal,
    private _componentsService: ComponentsService,
    private _router: Router
  ) {
    this.detailRCPath = this._documentService.receipt.DetailPathURL;
  }

  SetLoadingState() {
    this.isLoading = true;
  }
  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;

    this._authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.contentHeader = {
      headerTitle: 'Document.Quotation',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
          {
            name: this._translateService.instant('Document.Quotation'),
            isLink: true,
            //link: this.apiPath,
          },
          {
            name:
              'Detail ' + this._translateService.instant('Document.Quotation'),
            isLink: false,
            //link: this.detailPathUrl,
          },
        ],
      },
    };

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.initData();
    console.log(this.detailObj);
    
  }

  getPaymentOrdinal(obj: any): number {
    if (obj.source == 'receipt') {
      this.receiptCounter += 1;
      return this.receiptList.length - this.receiptCounter;
    } else {
      this.creditNoteCounter += 1;
      return this.creditNoteList.length - this.creditNoteCounter;
    }
  }

  initData(): void {
    var self = this;

    this.SetLoadingState();
    this.getPaymentHistory().then((res) => {
      self.paymentDetail = res;
      console.log('paymentDetail : ', self.paymentDetail);

      if (!this.isExpenses) {
        self.receiptList = res.receipt;
        self.creditNoteList = res.creditNote;
      }

      if (this.isPurchaseOrder && !this.isExpenses) {
        self.goodsReceiveList = res.goodsReceive;
      }

      self.debitNoteList = res.debitNote;

      if (!this.isCreditNote && !this.isDebitNote) {
        this.mergedArray = this.mergeArrays(
          this.receiptList,
          this.creditNoteList
        );
      } else if (!this.isCreditNote && !this.isDebitNote) {
        this.mergedArray = this.mergeArrays(this.creditNoteList);
      } else if (!this.isCreditNote && !this.isDebitNote) {
        this.mergedArray = this.mergeArrays(this.debitNoteList);
      } else if (
        this.isPurchaseOrder &&
        !this.isCreditNote &&
        !this.isDebitNote
      ) {
        this.mergedArray = this.mergeArrays(this.goodsReceiveList);
      }

      this.sortedItems = this.mergedArray.sort(
        (a, b) =>
          new Date(b.createDate).getTime() - new Date(a.createDate).getTime()
      );

      const sourceCounts: { [key: string]: number } = {
        receipt: 0,
        creditNote: 0,
        debitNote: 0,
      };

      this.sortedItems
        .slice()
        .reverse()
        .forEach((obj) => {
          const source = obj.source;
          if (!sourceCounts[source]) {
            sourceCounts[source] = 1;
          } else {
            sourceCounts[source]++;
          }

          obj.ordinal = sourceCounts[source];
        });

      console.log('sortedItems : ', this.sortedItems);
      self.SetLoadedState();
    });
  }

  mergeArrays(...arrays: any[]): any[] {
    // Helper function to add the "source" property to each object in an array
    const markSource = (arr: any[], source: string) =>
      arr.map((item) => ({ ...item, source }));

    // Convert ISO strings to Date objects and mark the source for each array
    let convertedAndMerged = [];
    if (!this.isCreditNote && !this.isDebitNote && !this.isPurchaseOrder) {
      convertedAndMerged = [
        ...markSource(
          this.receiptList.map((item) => ({
            ...item,
            createDate: new Date(item.createDate),
          })),
          'receipt'
        ),
        ...markSource(
          this.creditNoteList.map((item) => ({
            ...item,
            createDate: new Date(item.createDate),
          })),
          'creditNote'
        ),
      ];
    } else if (
      !this.isCreditNote &&
      !this.isDebitNote &&
      !this.isPurchaseOrder
    ) {
      convertedAndMerged = [
        ...markSource(
          this.creditNoteList.map((item) => ({
            ...item,
            createDate: new Date(item.createDate),
          })),
          'creditNote'
        ),
      ];
    } else if (
      !this.isCreditNote &&
      !this.isDebitNote &&
      !this.isPurchaseOrder
    ) {
      convertedAndMerged = [
        ...markSource(
          this.debitNoteList.map((item) => ({
            ...item,
            createDate: new Date(item.createDate),
          })),
          'debitNote'
        ),
        // Add more arrays if needed...
      ];
    } else if (
      this.isPurchaseOrder &&
      !this.isCreditNote &&
      !this.isDebitNote
    ) {
      convertedAndMerged = [
        ...markSource(
          this.goodsReceiveList.map((item) => ({
            ...item,
            createDate: new Date(item.createDate),
          })),
          'goodsReceive'
        ),
      ];
    }

    return convertedAndMerged;
  }

  async getPaymentHistory(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._documentApiService
        .GetPaymentHistory(this.apiPath, this.docId)
        .subscribe(
          (res) => {
            resolve(res.data.resultData[0]);
          },
          (error) => {
            reject(error); // Handle any error that might occur during the API call
          }
        );
    });
  }

  getSummary(data: any[], property: string): number {
    return data.reduce((total, item) => total + (item[property] || 0), 0);
  }

  calculateSummary() {
    let itemList = [];
    this.detailObj.item.forEach((element, index) => {
      let tempRow: TempRowQuotation = new TempRowQuotation();
      tempRow.rowType = 'item';
      tempRow.itemId = element.itemId;
      tempRow.description = element.description[0].description;
      tempRow.discount = element.discount;
      tempRow.quantity = element.quantity;
      tempRow.unit = element.unit;
      tempRow.unitPrice = element.unitPrice;
      tempRow.whtRate = 0.0;

      itemList.push(tempRow);
    });

    this.sumNetPrice = this._calculateService.calculateNet(
      itemList,
      true,
      this.detailObj.taxRate,
      this.detailObj.discount
    );

    this.sumVatPrice = this._calculateService.calculateVat(
      this.sumNetPrice,
      this.detailObj.taxRate
    );
    this.SetLoadedState();
  }

  //length of array receipt
  getLengthReceipt(): number {
    return this.receipt.length;
  }

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }

  getInvoiceData(invoiceId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.apiPath, invoiceId)
        .subscribe((res) => {
          self.detailObj = res.data.resultData[0];
          self.calculateSummary();
          self.SetLoadedState();
        });
    });
  }

  async getNetTotal(docId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._documentApiService.GetNetTotal(this.apiPath, docId).subscribe(
        (res) => {
          resolve(res.data.resultData[0]);
        },
        (error) => {
          reject(error); // Handle any error that might occur during the API call
        }
      );
    });
  }

  triggerRefreshData() {
    this.refreshData.emit();
  }

  openPaymentModal(objData?: any): void {
    this.isPaymentModalOpening = true;
    this.getNetTotal(objData.id)
      .then((netData) => {
        const modalRef = this._modalService.open(ModalPaymentComponent, {
          centered: true,
          backdrop: 'static',
          size: 'lg',
        });

        modalRef.componentInstance.objData = objData;
        modalRef.componentInstance.netData = netData;
        modalRef.componentInstance.docCodeColumn = this.typeDocName;

        if (this.isExpenses) {
          modalRef.componentInstance.isGoodsReceive = false;
        }

        if (this.isDebitNote || this.isCreditNote) {
          modalRef.componentInstance.notSplitPay = false;
        }

        this.isPaymentModalOpening = false;

        modalRef.componentInstance.callBackFunc.subscribe((res) => {
          let isTX = this.isJoinIV_TX_RE ? true : false;
          let isRE = this.isJoinIV_TX_RE ? true : false;

          // let rcId = res.data.resultData[0].id;
          // let rcCode = res.data.resultData[0].code;

          console.log(res);

          let params = {
            paymentTypeId: res.paymentTypeId,
            paymentDate: res.paymentDate,
            bankAccountId: res.bankAccountId,
            total: res.payAmount,
            salesWHT: res.salesWHT,
            paymentRemarks: res.remarks,
            isTaxInvoice: isTX,
            isReceipt: isRE,
          };
          this.blockUI.start();

          console.log(params.paymentTypeId);

          if (params.paymentTypeId == PaymentMethodList.Cash.id) {
            this._documentApiService
              .CashPayment(this.apiPath, res.docId, params)
              .subscribe(
                (res) => {
                  console.log(res); /*return iv */

                  this.initData();
                  this.triggerRefreshData();

                  const titleLinkDocButton =
                    this._translateService.instant('Alert.GoToRC');

                  this._componentsService.SuccessSwal(
                    undefined,
                    undefined
                    // true,
                    // titleLinkDocButton,
                    // this.detailRCPath,
                    // rcCode,
                    // rcId
                  );
                  // modalRef.componentInstance.triggerRefreshData(this.initData);
                },
                (err) => {
                  this._componentsService.ErrorSwal();
                }
              );
          } else if (params.paymentTypeId == PaymentMethodList.Transfer.id) {
            this._documentApiService
              .TransferPayment(this.apiPath, res.docId, params)
              .subscribe(
                (res) => {
                  console.log(res); /*return iv */

                  this.initData();
                  this.triggerRefreshData();

                  const titleLinkDocButton =
                    this._translateService.instant('Alert.GoToRC');

                  this._componentsService.SuccessSwal(
                    undefined,
                    undefined
                    // true,
                    // titleLinkDocButton,
                    // this.detailRCPath,
                    // rcCode,
                    // rcId
                  );
                  // modalRef.componentInstance.triggerRefreshData(this.initData);
                },
                (err) => {
                  this._componentsService.ErrorSwal();
                }
              );
          } else {
            this._documentApiService
              .Payment(this.apiPath, res.docId, params)
              .subscribe(
                (res) => {
                  console.log(res); /*return iv */

                  this.initData();
                  this.triggerRefreshData();

                  const titleLinkDocButton =
                    this._translateService.instant('Alert.GoToRC');

                  this._componentsService.SuccessSwal(
                    undefined,
                    undefined
                    // true,
                    // titleLinkDocButton,
                    // this.detailRCPath,
                    // rcCode,
                    // rcId
                  );
                  // modalRef.componentInstance.triggerRefreshData(this.initData);
                },
                (err) => {
                  this._componentsService.ErrorSwal();
                }
              );
          }
        });
      })
      .catch((error) => {
        console.error('Error occurred:', error);
      });
  }
}
