import { AfterViewInit, Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/main/service/api.service';
import { ComponentsService } from 'app/main/components/components.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DocumentStatus } from 'app/main/model/DocumentStatus';
import * as XLSX from 'xlsx';
import { CurrencyPipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';

import { ReviewManageService } from '../review-manage.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
  contentHeader: object;

  apiPath: string;
  pathUrl: string;

  editPathUrl: string;
  detailPathUrl: string;
  currentLang: string;


  isLoading: boolean;
  isGridView: boolean = false;
  isShowChildList: boolean = false;
  isForGroupTemplate: boolean = false;
  isGoEditPage: boolean = false;
  activeTab: string = 'all';

  @BlockUI()blockUI: NgBlockUI;
  deleteFunc: EventEmitter<any> = new EventEmitter();

  selectedColumns: string[];

  itemList: any[] = [];
  itemProductList: any[] = [];

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  page: number = 1;
  pageSize: number = 10;

  searchData: { [k: string]: any } = {};
  constructor(
    private _reviewManageService: ReviewManageService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _apiService: ApiService,
    private _modalService: NgbModal,
    private currencyPipe: CurrencyPipe,
    private _globalFuncService: GlobalFuncService,
    private _router: Router,
  ) 
  { 
    this.apiPath = this._reviewManageService.review.ApiPath;
    this.pathUrl = this._reviewManageService.review.PathURL;
    this.editPathUrl = this._reviewManageService.review.EditPathURL;
    this.detailPathUrl = this._reviewManageService.review.DetailPathURL;
    // this.selectedColumns = [
    //   'codeName',
    //   'redeemCode',
    //   'startDate',
    //   'endDate',
    //   'createBy',
    // ];
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'General.Review',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    var self = this;
    const date = new Date();

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    // server side pagination datatable (TODO)

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      order: [[1, 'desc']],
      ordering: true,
      serverSide: true,
      lengthChange: false,
      columnDefs: [
        { "orderable": false, "targets": 0 },
        { "name": this.apiPath + 'Code', "targets": 1 },
        { "name": "CustomerName", "targets": 2 },
        { "name": "TransactionStatusId", "targets": 3 },
        { "name": "DocumentCreateDate", "targets": 4 },
        { "name": "DocumentExpireDate", "targets": 5 },
        { "name": "GrandTotal", "targets": 6 },
        { "orderable": false, "targets": 7 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.itemList = [];
        self.SetLoadingState();

        let defaultSortName = this.apiPath + 'Code';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter((item: any) => item.data === order.column)[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        }
        else {
          self.searchData.SortPath = !!column_filter_name ? column_filter_name : defaultSortName;
        }

        this.searchData.Direction = order?.dir == "desc"? 1 : 0;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );

        

        this.page = this.searchData.Page;

        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;
        this.searchData.includeMediaURL= true;

        // console.log(this.searchData)
        this._apiService
          .GetAllData(this.apiPath, this.searchData)
          .subscribe((response) => {
            console.log(response);
            this.itemList = response.data.resultData;
            
            self.SetLoadedState();
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          });
      },
    };
  }

  loadData(searchData?: any) {
    if (searchData) {
      this.searchData = searchData;
      console.log('have searchData',searchData);
      
    } else {
      this.searchData = {
        SortPath: this.apiPath + 'Code',  //! มาเปลี่ยนทีหลังด้วย
        Direction: 1,
        isDelete: false,
      };
      console.log('no searchData');
      
    }

    if (this.dtTrigger) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }
  }

  openIsActiveModal(itemObj, status): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    let modeText = status
      ? this._translateService.instant('Status.Published')
      : this._translateService.instant('Status.Unpublished');
    modalRef.componentInstance.title = `${modeText}`;
    modalRef.componentInstance.detail =
      this._translateService.instant('Brand.Active') +
      `${modeText} ${itemObj.codeName}`;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.setIsActiveContent(itemObj.id, status);
    });
  }

  setIsActiveContent(id: string, status: boolean): void {
    this.blockUI.start();
    this._apiService
      .SetIsActiveData(this.apiPath, id, status)
      .subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this.SetLoadingState();
          this.loadData();
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }
}
