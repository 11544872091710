<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="card">
      <div class="card-body">
        <div class="nav-vertical d-flex">
          <ul
            ngbNav
            #navVertical="ngbNav"
            class="nav nav-pills flex-column text-nowrap sidebar-nav-custom"
            [destroyOnHide]="false"
            style="width: 250px"
          >
            <li ngbNavItem>
              <a ngbNavLink>{{ "Settings.General" | translate }}</a>
              <ng-template ngbNavContent>
                <h4 class="general-title">
                  {{ "Settings.General" | translate }}
                </h4>
                <app-general></app-general>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink>{{ "Settings.BankAccount" | translate }}</a>
              <ng-template ngbNavContent>
                <h4 class="general-title">
                  {{ "Settings.BankAccount" | translate }}
                </h4>
                <app-bank-manage></app-bank-manage>
              </ng-template>
            </li>

            <!-- <li ngbNavItem>
              <a ngbNavLink>{{ "Settings.Branch" | translate }}</a>
              <ng-template ngbNavContent>
                <h4 class="general-title">
                  {{ "Settings.Branch" | translate }}
                </h4>
                <app-branch></app-branch>
              </ng-template>
            </li> -->
          </ul>
          <div
            [ngbNavOutlet]="navVertical"
            class="ml-1"
            style="width: -webkit-fill-available"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
