<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <!-- <section id="home-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">
                Updated:
                {{
                  currentLang == "en"
                    ? (currentDate | date : "dd MMM YYYY")
                    : (currentDate | thaidate : "DD MMM YYYY")
                }}
              </h4>
            </div>
            <div class="card-body pb-1">
              <p class="card-text">All the best for your new project.</p>
              <p class="card-text">
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-sm-6 col-12 d-grid">
          <div class="card" #test>
            <div class="card-header my-auto flex-nowrap">
              <div>
                <h2 class="font-weight-bolder mb-0">
                  {{ 100 | number }} บัญชี
                </h2>
                <p class="card-text">บัญชีผู้ใช้งานใหม่ในเดือนนี้</p>
              </div>
              <i data-feather="user-plus" size="22" class="text-dark"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 d-grid">
          <div class="card">
            <div class="card-header my-auto flex-nowrap">
              <div>
                <h2 class="font-weight-bolder mb-0">
                  {{ 1500 | number }} บัญชี
                </h2>
                <p class="card-text">บัญชีผู้ใช้งานทั้งหมดในเว็บไซต์</p>
              </div>
              <i data-feather="users" size="22" class="text-dark"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-sm-6 col-12 d-grid">
          <div class="card">
            <div class="card-header my-auto flex-nowrap">
              <div>
                <h2 class="font-weight-bolder mb-0">
                  {{ 300000 | currency : "" : "" }}
                </h2>
                <p class="card-text">ยอดขายทั้งเดือนนี้ (THB)</p>
              </div>
              <img src="assets/images/general/vuesax-outline-wallet-add.svg" />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 d-grid">
          <div class="card">
            <div class="card-header my-auto flex-nowrap">
              <div>
                <h2 class="font-weight-bolder mb-0">
                  {{ 100000 | currency : "" : "" }}
                </h2>
                <p class="card-text">ยอดขายจากโปรโมชั่นในเดือนนี้ (THB)</p>
              </div>
              <img
                src="assets/images/general/vuesax-outline-ticket-discount.svg"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 d-grid">
          <div class="card">
            <div class="card-header my-auto flex-nowrap">
              <div>
                <h2 class="font-weight-bolder mb-0">
                  {{ 200000 | currency : "" : "" }}
                </h2>
                <p class="card-text">ยอดขายปกติในเดือนนี้ (THB)</p>
              </div>
              <img src="assets/images/general/vuesax-outline-wallet-3.svg" />
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- Basic Alerts end -->

    <!-- Basic Alerts start -->
    <section id="home-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div
              class="card-header"
              style="display: grid; align-self: center; height: 74vh"
            >
              <div class="text-center mx-auto my-5">
                <img [src]="appLogoImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
