import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';
import { HowToUseCreateComponent } from './how-to-use-create/how-to-use-create.component';
import { HowToUseService } from './how-to-use.service';

@Component({
  selector: 'app-how-to-use',
  templateUrl: './how-to-use.component.html',
  styleUrls: ['./how-to-use.component.scss']
})
export class HowToUseComponent implements OnInit {
  config!: ICMSConfig;
  keyName: string;

  constructor(private _howToUseService: HowToUseService) {
    this.config=this._howToUseService.config;
    this.keyName=this._howToUseService.keyName;
   }

  ngOnInit(): void {
  }

}
