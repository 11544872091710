<div *ngIf="isLoading" class="text-center my-5">
  <app-loading></app-loading>
</div>
<div *ngIf="!isLoading" class="row">
  <div class="col-md-12">
    <!-- <a (click)="" class="btn btn-primary"></a> -->
  </div>
  <div class="col-md-12">
    <form [formGroup]="formGroup">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">
          {{ "User.Username" | translate }}
        </label>
        <div class="col-sm-9">
          <input
            class="form-control"
            type="text"
            formControlName="username"
            placeholder="{{ 'User.Username' | translate }}"
            [ngClass]="{
              'is-invalid error':
                (f.username?.invalid &&
                  (f.username?.dirty || f.username?.touched)) ||
                (f.username.invalid && isSubmitted)
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (f.username?.invalid &&
                  (f.username?.dirty || f.username?.touched)) ||
                (f.username.invalid && isSubmitted)
            }"
          >
            <div>
              <div *ngIf="f.username.errors?.required">
                {{ "Handle.UserName" | translate }}
              </div>
              <div *ngIf="f.username.errors?.maxlength">
                {{ "Handle.ExceedCharUserName" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-3 col-form-label">{{
          "User.Cover" | translate
        }}</label>
        <div class="col-sm-9">
          <app-image-upload #imageUploadComponent></app-image-upload>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{
          "User.Email" | translate
        }}</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            type="email"
            formControlName="email"
            placeholder="{{ 'User.Email' | translate }}"
            disabled
            [ngClass]="{
              'is-invalid error':
                (f.email.invalid && (f.email.dirty || f.email.touched)) ||
                (f.email.invalid && isSubmitted)
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (f.email.invalid && (f.email.dirty || f.email.touched)) ||
                (f.email.invalid && isSubmitted)
            }"
          >
            <div>
              <div *ngIf="f.email.errors?.required">
                {{ "Handle.Email" | translate }}
              </div>
              <div *ngIf="f.email.errors?.email">
                {{ "Handle.EmailSyntax" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 class="mb-2">{{ "User.UserStatus" | translate }}</h4>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{
          "User.IsActive" | translate
        }}</label>
        <div class="col-sm-9">
          <div class="demo-inline-spacing">
            <div class="custom-control custom-radio my-auto">
              <input
                type="radio"
                id="isActive1"
                class="custom-control-input"
                [checked]="
                  userObj.isActive === true ||
                  userObj.isActive === null ||
                  userObj.isActive === undefined
                "
                [(ngModel)]="userObj.isActive"
                [value]="true"
                formControlName="isActive"
              />
              <label class="custom-control-label" for="isActive1">{{
                "User.Active" | translate
              }}</label>
            </div>
            <div class="custom-control custom-radio my-auto">
              <input
                type="radio"
                id="isActive2"
                class="custom-control-input"
                [checked]="userObj.isActive === false"
                [(ngModel)]="userObj.isActive"
                [value]="false"
                formControlName="isActive"
              />
              <label class="custom-control-label" for="isActive2">{{
                "User.InActive" | translate
              }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{
          "User.Role" | translate
        }}</label>
        <div class="col-sm-9">
          <div class="input-group">
            <select
              class="custom-select"
              formControlName="roleSetId"
              [ngClass]="{
                'is-invalid error':
                  (f.roleSetId.value == 'default' &&
                    (f.roleSetId.dirty || f.roleSetId.touched)) ||
                  (f.roleSetId.value == 'default' && isSubmitted)
              }"
            >
              <option value="default" disabled>
                -- {{ "User.SelectRole" | translate }} --
              </option>
              <option
                value="{{ role.id }}"
                *ngFor="let role of roleSetList"
                [selected]="userObj?.roleSetId === role.id"
              >
                {{ role.roleName }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <h4 class="mb-2">{{ "User.UserDetail" | translate }}</h4>

      <div class="form-group row">
        <div class="col-md-2 col-12">
          <label for="first-name-vertical">{{
            "User.Prefix" | translate
          }}</label>
          <div class="input-group">
            <select
              class="custom-select"
              formControlName="namePrefixId"
              [ngClass]="{
                'is-invalid error':
                  (f.namePrefixId.value == 'default' &&
                    (f.namePrefixId.dirty || f.namePrefixId.touched)) ||
                  (f.namePrefixId.value == 'default' && isSubmitted)
              }"
            >
              <option value="default" disabled>
                -- {{ "User.SelectPrefix" | translate }} --
              </option>
              <option
                *ngFor="let prefix of prefixList"
                [value]="prefix.id"
                [selected]="prefix.id === userObj.namePrefixId"
              >
                {{ prefix.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-5 col-12">
          <label for="first-name-vertical">{{
            "User.FirstName" | translate
          }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="firstName"
              placeholder="{{ 'User.FirstName' | translate }}"
              [ngClass]="{
                'is-invalid error':
                  f.firstName.invalid &&
                  (f.firstName.dirty || f.firstName.touched)
              }"
            />
            <div
              class="invalid-feedback"
              [ngClass]="{
                'd-block':
                  f.firstName.invalid &&
                  (f.firstName.dirty || f.firstName.touched)
              }"
            >
              <div>
                <div *ngIf="f.firstName.errors?.required">
                  {{ "Handle.Name" | translate }}
                </div>
                <div *ngIf="f.firstName.errors?.maxlength">
                  {{ "Handle.ExceedCharName" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5 col-12">
          <label for="first-name-vertical">{{
            "User.LastName" | translate
          }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="lastName"
              placeholder="{{ 'User.LastName' | translate }}"
              [ngClass]="{
                'is-invalid error':
                  f.lastName.invalid && (f.lastName.dirty || f.lastName.touched)
              }"
            />
            <div
              class="invalid-feedback"
              [ngClass]="{
                'd-block':
                  f.lastName.invalid && (f.lastName.dirty || f.lastName.touched)
              }"
            >
              <div>
                <div *ngIf="f.lastName.errors?.required">
                  {{ "Handle.LastName" | translate }}
                </div>
                <div *ngIf="f.lastName.errors?.maxlength">
                  {{ "Handle.ExceedCharLastName" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-12">
          <label for="first-name-vertical">{{
            "User.Gender" | translate
          }}</label>
          <div class="input-group">
            <select
              class="custom-select"
              formControlName="genderId"
              [ngClass]="{
                'is-invalid error':
                  (f.genderId.value == 'default' &&
                    (f.genderId.dirty || f.genderId.touched)) ||
                  (f.genderId.value == 'default' && isSubmitted)
              }"
            >
              <option value="default" disabled>
                -- {{ "User.SelectGender" | translate }} --
              </option>
              <option
                *ngFor="let gender of genderList"
                [value]="gender.value"
                [selected]="gender.value === userObj.genterId"
              >
                {{ "Gender." + gender.name | translate }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <div class="col-md-3 col-12">
          <label for="first-name-vertical">{{
            "User.Organization" | translate
          }}</label>
          <div class="input-group">
            <select class="custom-select" formControlName="organization">
              <option value="default" disabled>-- กรุณาเลือกองค์กร --</option>
              <option
                *ngFor="let organization of organizationList"
                [value]="organization.id"
              >
                {{ organization.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-3 col-12">
          <label for="first-name-vertical">{{
            "User.Branch" | translate
          }}</label>
          <div class="input-group">
            <select class="custom-select" formControlName="branch">
              <option value="default" disabled>-- กรุณาเลือกสาขา --</option>
              <option *ngFor="let branch of branchList" [value]="branch.id">
                {{ branch.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-3 col-12">
          <label for="first-name-vertical">{{
            "User.Department" | translate
          }}</label>
          <div class="input-group">
            <select class="custom-select" formControlName="department">
              <option value="default" disabled>-- กรุณาเลือกแผนก --</option>
              <option
                *ngFor="let department of departmentList"
                [value]="department.id"
              >
                {{ department.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-3 col-12">
          <label for="first-name-vertical">{{
            "User.Position" | translate
          }}</label>
          <div class="input-group">
            <select class="custom-select" formControlName="position">
              <option value="default" disabled>
                -- กรุณาเลือกตำแหน่ง --
              </option>
              <option
                *ngFor="let position of positionList"
                [value]="position.id"
              >
                {{ position.name }}
              </option>
            </select>
          </div>
        </div>
      </div> -->

      <br />

      <div class="form-group d-flex justify-content-between">
        <a class="btn btn-secondary" (click)="openCancelModal()">
          {{ "Form.Cancel" | translate }}
        </a>

        <a class="btn btn-primary" (click)="openConfirmModal()">
          {{ "Form.Submit" | translate }}
        </a>
      </div>
    </form>
  </div>
</div>
