import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/main/service/api.service';
import { ComponentsService } from 'app/main/components/components.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DocumentStatus } from 'app/main/model/DocumentStatus';
import * as XLSX from 'xlsx';
import { CurrencyPipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { GlobalFuncService } from 'app/main/service/global-func.service';


@Component({
  selector: 'app-content-code-manage',
  templateUrl: './content-code-manage.component.html',
  styleUrls: ['./content-code-manage.component.scss']
})
export class ContentCodeManageComponent implements OnInit {

  apiPath: string;
  pathUrl: string;
  

  dtOptions: any = {};

  isLoading: boolean;
  activeTab: string = 'all';

  searchData: any = {};

  itemList: any[] = [];

  constructor(
    private _translateService: TranslateService,
    private _apiService: ApiService,
    private _componentsService: ComponentsService,
    private _globalFuncService: GlobalFuncService
  ) { }

  ngOnInit(): void {
  }

  // getDatabyState(state, tab: string): Promise<any> {
  //   var self = this;
  //   self.isLoading = true;
  //   self.activeTab = tab;
  //   const searchParams = this.searchData;

  //   return new Promise((resolve, reject) => {
  //     const combinedParams = {
  //       ...state,
  //       ...searchParams?.params,
  //     };

  //     this._apiService
  //       .GetAllData(this.apiPath, combinedParams)
  //       .subscribe((res) => {
  //         self.itemList = res.data.resultData;
  //         console.log(this.itemList);
  //         self.isLoading = false;
  //         self.SetLoadedState();
  //         resolve(self.itemList);
  //       }, reject);
  //   });
  // }

  SetLoadingState(): void {
    this.isLoading = true;
  }

}
