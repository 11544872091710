<section id="knowledge-base-search">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div
            class="col-2 px-0 d-flex justify-content-start"
            *ngIf="!notShowCreateBtn"
          >
            <a [routerLink]="[pathUrl]" class="btn btn-primary">
              <i data-feather="plus" class="mr-1"></i
              >{{ "General.Create" | translate }}
            </a>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <!-- คำในรีวิว -->
            <div class="col-12 col-sm-12 col-md-6">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "General.Search" | translate
                }}</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    #searchbar
                    name="searchbar"
                    placeholder="{{ 'General.Search' | translate }}"
                  />
                </div>
              </div>
            </div>
            <!-- สถานะ -->
            <div class="col-12 col-sm-12 col-md-6">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "General.Status" | translate
                }}</label>
                <select name="sorting" class="custom-select" #activeFil>
                  <option value="all">{{ "General.All" | translate }}</option>
                  <option value="active">
                    {{
                      (notForContentMode ? "Status.Active" : "Status.Published")
                        | translate
                    }}
                  </option>
                  <option value="inactive">
                    {{
                      (notForContentMode
                        ? "Status.Inactive"
                        : "Status.Unpublished"
                      ) | translate
                    }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mt-1">
            <!-- สินค้า -->
            <div class="col-12 col-sm-12 col-md-6">
              <div class="scope-custom">
                <label for="first-name-vertical">{{ "General.ItemName" | translate }}</label>
                <select name="sorting" class="custom-select" #item>
                    <option value="all">{{ "General.All" | translate }}</option>
                    <option *ngFor="let item of ItemList" value="{{item.id}}">{{item.name}}</option>
                  </select>
              </div>
            </div>
            <!-- จากวันที่ -->
            <div class="col-12 col-sm-12 col-md-3">
              <div class="scope-custom">
                <label for="first-name-vertical">{{ "General.FromDate" | translate }}</label>
                <ng2-flatpickr
                [config]="startDateOptions"
                [setDate]="startDateOptions.defaultDate"
                class="date-form-control"
                placeholder="dd/mm/yyyy"
                #ReviewDateFrom
              ></ng2-flatpickr>
              </div>
            </div>
            <!-- ถึงวันที่ -->
            <div class="col-12 col-sm-12 col-md-3">
              <div class="scope-custom">
                <label for="first-name-vertical">{{ "General.ToDate" | translate }}</label>
                <ng2-flatpickr
                [config]="endDateOptions"
                [setDate]="endDateOptions.defaultDate"
                class="date-form-control"
                placeholder="dd/mm/yyyy"
                #EndDateValue
              ></ng2-flatpickr>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body row">
          <div class="col-12 px-0 d-flex justify-content-between">
            <div class="col-12 px-0 d-flex justify-content-center">
              <button
                (click)="ResetSearch()"
                class="btn btn-secondary"
                style="margin-right: 0.5rem"
              >
                <i data-feather="refresh-ccw" class="mr-1"></i
                >{{ "General.Reset" | translate }}
              </button>

              <button
                (click)="SubmitSearch()"
                class="btn btn-primary"
                style="margin-left: 0.5rem"
              >
                <i data-feather="search" class="mr-1"></i
                >{{ "General.Search" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <ng2-flatpickr
class="date-form-control"
[setDate]="createDateOptions.defaultDate"
[config]="createDateOptions"
formControlName="taxInvoiceDate"
#createDate
></ng2-flatpickr> -->
