import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/main/service/api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { CustomerService } from '../customer.service';
import { TranslateService } from '@ngx-translate/core';
import Customer from 'app/main/model/Customer';
import { CustomerAddrComponent } from 'app/main/components/form/customer-form/customer-addr/customer-addr.component';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { RelatedDocModalComponent } from 'app/main/components/related-doc-modal/related-doc-modal.component';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss'],
})
export class CustomerDetailComponent implements OnInit, AfterViewInit {
  contentHeader: object;
  private _unsubscribeAll: Subject<any>;
  componentName: string;

  apiPath: string;
  pathUrl: string;
  editPathUrl: string;
  customerId: string;
  customerObj: Customer;
  contactAddrObj: CustomerAddress;

  orderList: any[] = [];
  buyOrderList: any[] = [];

  downlineList: Customer[]

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  buyOrderDtOptions: DataTables.Settings = {};
  orderDtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild('CustomerAddress') CustomerAddress: CustomerAddrComponent;

  public page = 1;
  public pageSize = 10;

  isLoading: boolean = false;
  orderSearchData: any = {};
  buyOrderSearchData: any = {};

  currentLang: string;

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _customerService: CustomerService,
    private _translateService: TranslateService,
    private _modalService: NgbModal,
    private _globalFuncService: GlobalFuncService
  ) {
    this._unsubscribeAll = new Subject();
    this.componentName = this._customerService.componentName;
    this.apiPath = this._customerService.apiPath;
    this.pathUrl = this._customerService.pathUrl;
    this.editPathUrl = this._customerService.editPathURL;

    this._route.paramMap.subscribe((val) => {
      this.customerId = this._route.snapshot.paramMap.get('id');
    });
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  openRelatedDoc(id: string, orderCodeColumn: string) {
    const modalRef = this._modalService.open(RelatedDocModalComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
    });
  
    let isBuyOrder = orderCodeColumn == 'buyOrderCode' ? true : false

    console.log(orderCodeColumn)

    modalRef.componentInstance.orderId = id;
    modalRef.componentInstance.isBuyOrder = isBuyOrder;
  }

  initData() {
    this.blockUI.start();
    this._apiService
      .GetAllData('CustomerAddress', {
        UseForContact: true,
        UseForShipping: false,
        UseForBilling: false,
        IsDefault: true,
        IsDelete: false,
        CustomerId: this.customerId,
      })
      .subscribe((res) => {
        this.contactAddrObj = res.data.resultData[0];
      });

    this._apiService
      .GetDataById(this.apiPath, this.customerId)
      .subscribe((res) => {
        this.customerObj = res.data.resultData[0];
        this.blockUI.stop()
      });
  }



  ngAfterViewInit(): void {
    this.CustomerAddress.buildForm(this.customerId, true);
  }

  ngOnInit(): void {
    var self = this;

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.contentHeader = {
      headerTitle: this.componentName,
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this.componentName,
            isLink: true,
            link: this.pathUrl,
          },
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this.orderSearchData.CustomerId = this.customerId;
    this.buyOrderSearchData.CustomerId = this.customerId;

    this.orderDtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      order: [[1, 'desc']],
      serverSide: true,
      lengthChange: false,
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: 'orderCode', targets: 1 },
        { orderable: false, name: 'transactionStatusId', targets: 2 },
        { name: 'startDate', targets: 3 },
        { name: 'grandTotal', targets: 4 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.orderList = [];
        self.SetLoadingState();

        let defaultSortName = 'orderCode';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.orderSearchData.SortPath = defaultSortName;
        } else {
          self.orderSearchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.orderSearchData.Direction = order?.dir == 'asc' ? 0 : 1;

        this.orderSearchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.orderSearchData.PageLength = dataTablesParameters.length;
        this.orderSearchData.isDelete = false;

        console.log(this.orderSearchData);

        this._apiService
          .GetAllData('Order', this.orderSearchData)
          .subscribe((response) => {
            console.log(response);
            this.orderList = response.data.resultData;
            self.SetLoadedState();
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          });
      },
    };

    this.buyOrderDtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      order: [[1, 'desc']],
      serverSide: true,
      lengthChange: false,
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: 'buyOrderCode', targets: 1 },
        { orderable: false, name: 'transactionStatusId', targets: 2 },
        { name: 'startDate', targets: 3 },
        { name: 'grandTotal', targets: 4 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.buyOrderList = [];
        self.SetLoadingState();

        let defaultSortName = 'buyOrderCode';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.buyOrderSearchData.SortPath = defaultSortName;
        } else {
          self.buyOrderSearchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.buyOrderSearchData.Direction = order?.dir == 'asc' ? 0 : 1;

        this.buyOrderSearchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.buyOrderSearchData.PageLength = dataTablesParameters.length;
        this.buyOrderSearchData.isDelete = false;

        console.log(this.buyOrderSearchData);

        this._apiService
          .GetAllData('BuyOrder', this.buyOrderSearchData)
          .subscribe((response) => {
            console.log(response);
            this.buyOrderList = response.data.resultData;
            self.SetLoadedState();
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          });
      },
    };

    this.initData();
  }

  GetAddressTemplate(customerAddressObj): string {
    return this._globalFuncService.GetAddressTemplate(customerAddressObj);
  }
}
