import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ApiService } from 'app/main/service/api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-warehouse-table',
  templateUrl: './warehouse-table.component.html',
  styleUrls: ['./warehouse-table.component.scss']
})
export class WarehouseTableComponent implements OnInit {
  @Input() itemID: string;
  wareHouseList: any[] = [];
  wareHouseSearchData: any = {};

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  isLoading: boolean;

  public page = 1;
  public pageSize = 10;

  constructor(private _apiService: ApiService) { }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: 10,
      // deferLoading: 10,
      order: [[0, 'asc']],
      serverSide: true,
      // processing: true,
      lengthChange: false,

      ajax: (dataTablesParameters: any, callback: any) => {
        this.wareHouseList = [];
        self.SetLoadingState();

        this.wareHouseSearchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.wareHouseSearchData.PageLength = dataTablesParameters.length;
        this.wareHouseSearchData.isDelete = false;
        this.wareHouseSearchData.Direction = 1;
        this.wareHouseSearchData.SortPath = 'CreateDate';

        this._apiService
          .GetDataById(
            'itemMovement/item',
            this.itemID,
            this.wareHouseSearchData
          )
          .subscribe((response) => {
            this.wareHouseList = response.data.resultData;
            self.SetLoadedState();
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          });
      },
    };
  }

  loadData(searchData?: any) {
    if (searchData) {
      this.wareHouseSearchData = searchData;
    }

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload(() => {
        this.SetLoadedState();
      });
    });
  }

}
