<section id="knowledge-base-search">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">

          <ul class="nav nav-tabs mb-0 bg-white border">
            <li class="nav-item" *ngFor="let status of statusList">
              <a
                class="nav-link"
                [class.active]="activeTab === status.name"
                (click)="getDatabyState(status.value, status.name)"
                >{{ status.translate | translate }}</a
              >
            </li>
          </ul>

          <div class="col-2 px-0 d-flex justify-content-end">
          </div>
        </div>

        <div class="card-body">
          <div class="row mb-1">
            <div class="col-12 col-sm-12"
            [ngClass]="isShowPaymentType ? 'col-md-4' : 'col-md-6'">
              <div class="scope-custom">
                <label for="first-name-vertical">
                  {{ "SearchPanel.Document.CustomerName" | translate }}
                </label>

                <input
                  type="text"
                  class="form-control"
                  #searchname
                  name="searchname"
                  placeholder="{{
                    'SearchPanel.Document.CustomerName' | translate
                  }}"
                />
              </div>
            </div>

            <div class="col-12 col-sm-12"
            [ngClass]="isShowPaymentType ? 'col-md-4' : 'col-md-6'">
              <div class="scope-custom">
                <label for="first-name-vertical">
                  {{ "SearchPanel.Document.DocCode" | translate }}
                </label>

                <input
                  type="text"
                  class="form-control"
                  #searchid
                  name="searchid"
                  placeholder="{{ 'SearchPanel.Document.DocCode' | translate }}"
                />
              </div>
            </div>

            <div *ngIf="isShowPaymentType"  class="col-12 col-sm-12 col-md-4">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "General.PaymentType" | translate
                }}</label>
                <select name="sorting" class="custom-select" #paymentTypeFil>
                  <option value="">{{ "General.All" | translate }}</option>
                  <option value="5">{{ "General.CashOnDelivery" | translate}}</option>
                  <option value="2">{{ "General.BankTransfer" | translate}}</option>
                </select>
              </div>
            </div>

      
          </div>
          <div class="row">
            <div class="col-3 form-group">
              <div class="scope-custom">
                <label for="">
                  {{ "SearchPanel.Document.CreateDateFrom" | translate }}
                </label>
                <ng2-flatpickr
                  class="date-form-control"
                  [setDate]="createDateOptions.defaultDate"
                  [config]="createDateOptions"
                  (change)="onDateFromChange($event)"
                  #createDate
                  placeholder="dd/mm/yyyy"
                >
                </ng2-flatpickr>
              </div>
            </div>

            <div class="col-3 form-group">
              <div class="scope-custom">
                <label for="">{{
                  "SearchPanel.Document.CreateDateTo" | translate
                }}</label>
                <ng2-flatpickr
                  class="date-form-control"
                  [setDate]="expiredDateOptions.defaultDate"
                  [config]="expiredDateOptions"
                  #expriedDate
                  placeholder="dd/mm/yyyy"
                >
                </ng2-flatpickr>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-3">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "SearchPanel.Document.PriceFrom" | translate
                }}</label>

                <!-- <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i data-feather="search"></i
                ></span>
              </div> -->
                <input
                  type="text"
                  class="form-control"
                  id="searchpricefrom"
                  #searchpricefrom
                  name="searchpricefrom"
                  placeholder="{{
                    'SearchPanel.Document.PriceFrom' | translate
                  }}"
                  (blur)="formatToMoney('searchpricefrom', $event.target.value)"
                />
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-3">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "SearchPanel.Document.PriceTo" | translate
                }}</label>

                <!-- <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i data-feather="search"></i
                ></span>
              </div> -->
                <input
                  type="text"
                  class="form-control"
                  id="searchpriceto"
                  #searchpriceto
                  name="searchpriceto"
                  placeholder="{{ 'SearchPanel.Document.PriceTo' | translate }}"
                  (blur)="formatToMoney('searchpriceto', $event.target.value)"
                />
              </div>
            </div>
          </div>

          <div class="row" *ngIf="isShowSortByGroup">
            <div class="col-12 col-sm-12 col-md-4 mt-1" *ngIf="isShowGroup">
              <label for="first-name-vertical">{{
                "General.Group" | translate
              }}</label>
              <select
                class="custom-select"
                [(ngModel)]="groupId"
                (change)="getCategoryData($event.target.value)"
                #selectGroup
              >
                <option value="All" selected>All</option>
                <option [value]="group.id" *ngFor="let group of groupList">
                  {{ group.name }}
                </option>
              </select>
            </div>

            <div class="col-6 col-sm-6 col-md-4 mt-1" *ngIf="isShowCategory">
              <label for="first-name-vertical">{{
                "General.Category" | translate
              }}</label>
              <select
                class="custom-select"
                [(ngModel)]="categoryId"
                (change)="getSubCategoryData($event.target.value)"
                #selectCategory
              >
                <option value="All" selected>All</option>
                <option
                  [value]="category.id"
                  *ngFor="let category of categoryList"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>

            <div class="col-6 col-sm-6 col-md-4 mt-1" *ngIf="isShowSubCategory">
              <label for="first-name-vertical">{{
                "General.SubCategory" | translate
              }}</label>
              <select
                class="custom-select"
                [(ngModel)]="subCategoryId"
                (change)="getClassData($event.target.value)"
                #selectSubCategory
              >
                <option value="All" selected>All</option>
                <option
                  [value]="subCategory.id"
                  *ngFor="let subCategory of subCategoryList"
                >
                  {{ subCategory.name }}
                </option>
              </select>
            </div>

            <div class="col-6 col-sm-6 col-md-4 mt-1" *ngIf="isShowClass">
              <label for="first-name-vertical">{{
                "General.Class" | translate
              }}</label>
              <select
                class="custom-select"
                [(ngModel)]="classId"
                (change)="getSubClassData($event.target.value)"
                #selectClass
              >
                <option value="All" selected>All</option>
                <option [value]="class.id" *ngFor="let class of classList">
                  {{ class.name }}
                </option>
              </select>
            </div>

            <div class="col-6 col-sm-6 col-md-4 mt-1" *ngIf="isShowSubClass">
              <label for="first-name-vertical">{{
                "General.SubClass" | translate
              }}</label>
              <select
                class="custom-select"
                [(ngModel)]="subClassId"
                #selectSubClass
              >
                <option value="All" selected>All</option>
                <option
                  [value]="subClass.id"
                  *ngFor="let subClass of subClassList"
                >
                  {{ subClass.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="col-12 px-0 d-flex justify-content-between">
            <!-- <div class="col-3 d-flex justify-content-start my-auto">
            </div> -->
            <div class="col-12 px-0 d-flex justify-content-center">
              <button
                (click)="ResetSearch()"
                class="btn btm-sm btn-secondary"
                style="margin-right: 0.5rem"
              >
                <i data-feather="refresh-ccw" class="mr-1"></i
                >{{ "General.Reset" | translate }}
              </button>

              <button
                (click)="Submit()"
                class="btn btn-primary"
                style="margin-left: 0.5rem"
              >
                <i data-feather="search" class="mr-1"></i
                >{{ "General.Search" | translate }}
              </button>
            </div>
            <!-- <div *ngIf="ExportXLSX()" class="col-3 px-0 d-flex justify-content-end">
              <button (click)="ExportXLSX()" class="btn btn-primary mr-1">
                <i data-feather="file" class="mr-1"></i>Export
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
