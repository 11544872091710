import {
  Component,
  OnInit,
  ElementRef,
  Input,
  ViewChild,
  QueryList,
  SimpleChanges,
  ChangeDetectorRef,
  ViewChildren,
  OnDestroy,
} from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';

import { TranslateService } from '@ngx-translate/core';
import THBText from 'thai-baht-text';
import {
  Quotation,
  QuotationItem,
  TempRowQuotation,
} from 'app/main/model/Quotation';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { TimelineItem } from 'app/main/components/horizontal-timeline/horizontal-timeline.component';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { ApiService } from 'app/main/service/api.service';
import { CurrencyPipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Customer from 'app/main/model/Customer';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DocumentService } from 'app/main/pages/document/document.service';
import { DocumentStatus } from 'app/main/model/DocumentStatus';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { environment } from 'environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { PaymentTypeExpenseEnum, PaymentTypeRevenueEnum } from 'app/main/model/PaymentMethod';

@Component({
  selector: 'app-document-papers',
  templateUrl: './document-papers.component.html',
  styleUrls: ['./document-papers.component.scss'],
})
export class DocumentPapersComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject();

  @ViewChild('tableElement')
  tableElement: ElementRef<HTMLElement>;

  @ViewChild('trClild')
  trClild: ElementRef<HTMLElement>;

  @ViewChild('mainElement')
  mainElement: ElementRef<HTMLElement>;

  @ViewChild('overElement')
  overElement: ElementRef<HTMLElement>;

  @ViewChild('trcHeaderElement') trcHeaderElement: ElementRef<HTMLElement>;

  @ViewChildren('itemListElements')
  itemListElements: QueryList<ElementRef<HTMLElement>>;
  contentHeader: object;

  @Input() data: any;
  @Input() PreAmount: any;
  @Input() isPayments: Boolean;
  @Input() docCodeColumn: string;
  @Input() isDebit: Boolean;
  @Input() isShowShippingFee: boolean = true;
  currentUser: User;
  timelineData: TimelineItem[];
  Excess: Boolean = false;
  quotationObj: Quotation;
  customer: Customer;
  customerAddressObj: CustomerAddress;
  itemList: any[];
  apiPath: string;
  editPathUrl: string;
  THBgrantotal: string;
  docID: string;
  isLoading: boolean;

  showDiscount: Boolean = true;
  showWithheldtax: Boolean = environment.transaction.isUseWht;
  showPageNumber: Boolean = true;
  showManuScr: Boolean = true;
  fxTable: Boolean = true;
  showWaterMsk: Boolean = false;
  showDummyRow: boolean = true;
  isOriginalDoc: boolean = true;
  showShippingFee: boolean = true;

  @Input() isRevenue: boolean = true;
  @Input() showValidDate: boolean = true;
  @Input() isShowPayment: boolean = false;
  @Input() isPaymentDataInDocument: boolean = false;

  showPaperTool: Boolean = false;
  sumNetPrice: number;
  sumVatPrice: number;

  documentStatus = DocumentStatus;
  
  paymentTypeRevenueEnum = PaymentTypeRevenueEnum
  paymentTypeExpenseEnum = PaymentTypeExpenseEnum

  issuerData: any;
  logoOnPaper: string = environment.transaction.logoOnPaperPath;

  shippingFee: number = environment.transaction.shippingFee;

  dummyRowAmountList: number[] = [];
  bankAccountList: any[] = [];



  @BlockUI() loadingScreen: NgBlockUI;

  constructor(
    private cdr: ChangeDetectorRef,
    private _modalService: NgbModal,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _documentService: DocumentService,
    private _documentApiService: DocumentApiService,
    private _route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private _apiService: ApiService,
    private _router: Router,
    private _globalFuncService: GlobalFuncService,
    private _calculateService: CalculateFuncService,
    private _title: Title
  ) {
    this.SetLoadingState();
    this.getShippingFee();
    this.getIssuerData();
  }

  async getShippingFee() {
    try {
      let res = await this._documentService.getDefaultArgument();
      if (res) {
        this.shippingFee = res.shippingFee;
      } else {
        console.error('Default argument is not available.');
      }
    } catch (error) {
      console.error('Error while getting default argument:', error);
    }
  }

  getIssuerData() {
    this._apiService.GetAllData('Setting/General').subscribe((res) => {
      this.issuerData = res.data.resultData[0];
    });
  }

  goBackPage() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      console.log('data is here');

      this.quotationObj = this.data.quotationObj;
      this._title.setTitle(
        this.quotationObj ? this.quotationObj[this.docCodeColumn] : '-'
      );
    }

    this.THBgrantotal = THBText(this.data.quotationObj.grandTotal);
    this.customer = this.data.customerObj;
    this.customerAddressObj = this.data.customerAddressObj;
    if (
      this.quotationObj.transactionStatusId == this.documentStatus.Voided ||
      this.quotationObj.transactionStatusId == this.documentStatus.Cancel
    ) {
      this.showWaterMsk = true;
    }
    this.SetLoadedState();
  }

  ngOnInit(): void {
    var self = this;
    if (this.isPayments) {
      self.isPayments = true;
    } else {
      self.isPayments = false;
    }
    this.initData();
    this.checkTableHeight();
    this._authenticationService.currentUser.subscribe((user) => {
      self.currentUser = user;
    });

    self.contentHeader = {
      headerTitle: 'Document.Quotation',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    this._title.setTitle(environment.appTitle);
  }

  printWindow(): void {
    window.print();
  }
  SetLoadingState() {
    this.isLoading = true;
    this.loadingScreen.start();
  }
  SetLoadedState() {
    this.isLoading = false;
    this.loadingScreen.stop();
  }

  initData(): void {
    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
    });
    // this.getQuotationData();

    if (!this.isPaymentDataInDocument && this.isShowPayment) {
      this.getBankAccount();
    }
  }

  getBankAccount(): Promise<void> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('BankAccount', {
          isActive: true,
          isDelete: false,
          page: 1,
          pageLength: 2,
        })
        .subscribe((res) => {
          this.bankAccountList = res.data.resultData;
          console.log(this.bankAccountList);
        });
    });
  }

  getQuotationData(): Promise<any> {
    var self = this;

    console.log(this.data);
    self.SetLoadedState();
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.apiPath, this.docID)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          self.quotationObj = res.data.resultData[0];
          if (
            self.quotationObj.transactionStatusId ==
              this.documentStatus.Voided ||
            self.quotationObj.transactionStatusId == this.documentStatus.Cancel
          ) {
            self.showWaterMsk = true;
          }
          this.THBgrantotal = THBText(this.quotationObj.grandTotal);
          self.getCustomerAddress(self.quotationObj.customerId);
          self.calculateSummary();
        });
    });
  }
  showAllObj() {
    var self = this;
    self.showDiscount = true;
    self.showWithheldtax = true;
    self.showPageNumber = true;
    self.showManuScr = true;
    self.fxTable = true;
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData.filter((it) => {
            return it['useForShipping'] == true;
          });
        });
    });
  }

  ngAfterViewInit() {
    this.itemListElements.changes.subscribe((res) => {
      this.calculateSummary();
    });
  }

  calculateSummary() {
    this.itemList = [];
    const { reback } = this.checkTableHeight();

    console.log(reback);

    const result: any[][] = [];
    let startIndex = 0;

    for (const index of reback) {
      if (index <= this.quotationObj.item.length) {
        const chunk = this.quotationObj.item.slice(startIndex, index);
        result.push(chunk);
        startIndex = index;
      }
    }
    if (startIndex < this.quotationObj.item.length) {
      const remainingChunk = this.quotationObj.item.slice(startIndex);
      result.push(remainingChunk);
    }

    result.forEach((subItemList) => {
      const tempRowList: TempRowQuotation[] = [];

      subItemList.forEach((element: QuotationItem) => {
        const tempRow = new TempRowQuotation();
        tempRow.rowType = 'item';
        tempRow.itemId = element.itemId;
        tempRow.itemCode = element.itemCode;
        tempRow.description = element.description;
        tempRow.isDiscountByPercent = element.isDiscountByPercent;
        tempRow.discount = element.isDiscountByPercent
          ? element.percentDiscount
          : element.discount;
        tempRow.quantity = element.quantity;
        tempRow.preTaxAmount = element.preTaxAmount;
        tempRow.unit = element.unit;
        tempRow.unitPrice = element.unitPrice;
        tempRow.whtRate = 0.0;
        tempRowList.push(tempRow);
      });

      this.itemList.push(tempRowList);
    });
    if (reback[reback.length - 1] == this.quotationObj.item.length) {
      this.itemList.push([]);
      console.log(this.itemList);
    }

    this.itemList.forEach((subItemList) => {
      subItemList.forEach((element) => {
        this.sumNetPrice += this._calculateService.calculateNet(
          [element],
          true,
          this.quotationObj.taxRate,
          this.quotationObj.discount
        );

        this.sumVatPrice += this._calculateService.calculateVat(
          this.sumNetPrice,
          this.quotationObj.taxRate
        );
      });
    });
  }

  checkTableHeight() {
    if (this.quotationObj) {
      const table = this.tableElement.nativeElement as HTMLElement;
      const mainHeight =
        this.mainElement.nativeElement.getBoundingClientRect().height;
      const overHeight = 950;
      const trcHeader = this.trcHeaderElement.nativeElement;
      const trElements = Array.from(table.querySelectorAll('tr.trc'));
      const trcHeaderHeight = trcHeader.getBoundingClientRect().height;

      let reback = [];
      let cumulativeHeight = 0;
      let excessTrCount = 0;
      let splitIndex = -1;
      let forTwoPage = 0;
      let lastIndexofExcess = [];
      for (let i = 0; i < trElements.length; i++) {
        const tr = trElements[i] as HTMLElement;

        if (tr.classList[0] == 'trc') {
          cumulativeHeight += Math.round(
            tr.getBoundingClientRect().height * (9.5 / 10)
          );
        }
        if (cumulativeHeight > mainHeight) {
          excessTrCount++;
          this.Excess = true;
          lastIndexofExcess.push(i);
          forTwoPage++;
        }
        if (cumulativeHeight >= overHeight) {
          forTwoPage = 0;
          reback.push(i + 1);
          excessTrCount++;

          if (this.showDummyRow) {
            let dummyRowAmount =
              (overHeight -
                (cumulativeHeight - tr.getBoundingClientRect().height)) /
              31.5;
            if (dummyRowAmount < 1) {
              dummyRowAmount = 0;
            }
            this.dummyRowAmountList.push(Math.floor(dummyRowAmount));
          }

          cumulativeHeight = tr.getBoundingClientRect().height;
          if (splitIndex === -1) {
            splitIndex = i;
          }
        }
      }

      if (excessTrCount >= 1) {
        if (forTwoPage == 1) {
          reback.push(lastIndexofExcess[0] + 1);
        } else {
          if (this.showDummyRow) {
            let dummyRowAmount = (mainHeight - 65 - cumulativeHeight) / 31.5;
            if (dummyRowAmount < 1) {
              dummyRowAmount = 0;
            }
            this.dummyRowAmountList.push(Math.floor(dummyRowAmount));
          }
        }
      } else {
        if (this.showDummyRow) {
          let dummyRowAmount = (mainHeight - 65 - cumulativeHeight) / 31.5;
          if (dummyRowAmount < 1) {
            dummyRowAmount = 0;
          }
          this.dummyRowAmountList.push(Math.floor(dummyRowAmount));
        }
      }
      this.cdr.detectChanges();
      return { reback };
    }
  }

  openCancelModal(): void {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  splitArray(arr, chunkSize) {
    const splitItems = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      splitItems.push(chunk);
    }

    return splitItems;
  }

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }

  parseInt(value: string): number {
    return parseInt(value, 10);
  }

  GetAddressTemplate(customerAddressObj): string {
    return this._globalFuncService.GetAddressTemplate(customerAddressObj);
  }
}
