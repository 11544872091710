import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormTemplateComponent } from 'app/main/components/form/form-template/form-template.component';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';
import { ApiService } from 'app/main/service/api.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  config!: ICMSConfig;
  keyName: string;


  
  

  constructor() {
    
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
