import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';

@Injectable({
  providedIn: 'root',
})
export class FAQService {
  
  config: ICMSConfig = {
    apiPath: 'FAQ',
    pathUrl: '/cms/home/faq',
    tableName: 'Content',
    model: new Home(),
    isList: true,
    componentName: 'BreadCrumbs.CMS.Faq',
    formConfig: {
      title: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'FAQ',
        description: 'หัวข้อคำถาม',
      },
      detail: {
        type: FormType.RichText,
        validator: [Validators.required],
        label: 'FAQ description',
        description: 'คำอธิบาย',
      },
      isActive: {
        type: FormType.IsActive,
        label: 'Active',
        description: 'เปิดใช้งาน'
      }
    },
  };

  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'title';

  constructor() {}
}
