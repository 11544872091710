<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="home-page">
      <div class="card">
        <div class="card-header">
          <ul
            ngbNav
            #nav="ngbNav"
            class="nav nav-tabs mb-0 bg-white border"
            [destroyOnHide]="false"
            [activeId]="1"
          >
            <li [ngbNavItem]="1" class="nav-item">
              <a ngbNavLink>{{ "General.Detail" | translate }}</a>
              <ng-template ngbNavContent>
                <ng-container *ngTemplateOutlet="customerDetail"></ng-container>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" class="nav-item">
              <a ngbNavLink>{{ "General.OrderHistory" | translate }}</a>
              <ng-template ngbNavContent>
                <ng-container *ngTemplateOutlet="orderHistory"></ng-container>
              </ng-template>
            </li>
          </ul>
        </div>
        <div [ngbNavOutlet]="nav" class="card-body"></div>
      </div>
    </section>
  </div>
</div>

<ng-template #customerDetail>
  <div class="d-flex justify-content-between mb-1">
    <h3 class="mt-1 text-primary">
      {{
        customerObj?.islegalEntity
          ? customerObj.businessName
          : customerObj.customerFirstName + " " + customerObj.customerLastName
      }}

      (
      {{
        customerObj?.isHeadBranch
          ? ("Customer.headOffice" | translate)
          : customerObj.branchNumber.toString().padStart(5, "0") +
            " (" +
            customerObj.branchName +
            ")"
      }}
      )
    </h3>

    <a
      [routerLink]="[editPathUrl + '/' + customerId]"
      class="btn btn-primary"
      style="align-self: center"
      >{{ "General.Edit" | translate }}</a
    >
  </div>
  <div class="row">
    <div class="col-md-6 col-12">
      <div style="background: #f0f0f0" class="col-md-12 form-group border">
        <div class="d-flex p-1 justify-content-between align-items-center">
          <b class="title-customer"
            ><i [size]="22" class="feather_title mr-1" data-feather="user"></i
            >{{ "Customer.contactInfo" | translate }}</b
          >
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          <strong>{{ "Customer.contactType" | translate }} :</strong>
        </div>
        <div class="col-md-9 col-form-label">
          {{
            (customerObj.islegalEntity
              ? "Customer.JuristicPerson"
              : "Customer.OrdinaryPerson"
            ) | translate
          }}
        </div>
      </div>

      <div class="form-group row" *ngIf="!customerObj.islegalEntity">
        <div class="col-md-3 col-form-label">
          <strong>{{ "General.Gender" | translate }} :</strong>
        </div>
        <div class="col-md-9 col-form-label">
          {{ customerObj.genderName }}
        </div>
      </div>

      <div class="form-group row" *ngIf="customerObj.islegalEntity">
        <div class="col-md-3 col-form-label"><strong>สาขา :</strong></div>
        <div class="col-md-9 col-form-label">
          {{
            customerObj.isHeadBranch
              ? ("Customer.headOffice" | translate)
              : customerObj.branchNumber.toString().padStart(5, "0") +
                " (" +
                customerObj.branchName +
                ")"
          }}
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          <strong>{{ "Customer.idNumber" | translate }} :</strong>
        </div>
        <div class="col-md-9 col-form-label">
          {{ customerObj.taxId }}
        </div>
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div style="background: #f0f0f0" class="col-md-12 form-group border">
        <div class="d-flex p-1 justify-content-between align-items-center">
          <b class="title-customer">
            <i
              [size]="22"
              class="feather_title mr-1"
              data-feather="user-check"
            ></i>
            {{ "Customer.personalInfo" | translate }}
          </b>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          <strong>{{ "Customer.FullName" | translate }} :</strong>
        </div>
        <div class="col-md-9 col-form-label">
          {{
            customerObj.personalFirstName || customerObj.personalLastName
              ? customerObj.personalFirstName +
                " " +
                customerObj.personalLastName
              : "-"
          }}
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          <strong>{{ "Customer.nickname" | translate }} :</strong>
        </div>
        <div class="col-md-9 col-form-label">
          {{
            customerObj.personalNickName ? customerObj.personalNickName : "-"
          }}
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          <strong>{{ "Customer.position" | translate }} :</strong>
        </div>
        <div class="col-md-9 col-form-label">
          {{
            customerObj.personalPosition ? customerObj.personalPosition : "-"
          }}
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          <strong>{{ "Customer.email" | translate }} :</strong>
        </div>
        <div class="col-md-9 col-form-label">
          {{ customerObj.personalEmail ? customerObj.personalEmail : "-" }}
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          <strong>{{ "Customer.phone" | translate }} :</strong>
        </div>
        <div class="col-md-9 col-form-label">
          {{ customerObj.personalPhone ? customerObj.personalPhone : "-" }}
        </div>
      </div>
    </div>
  </div>

  <div style="background: #f0f0f0" class="col-md-12 form-group border">
    <div class="d-flex p-1 justify-content-between align-items-center">
      <b class="title-customer">
        <i [size]="22" class="phone mr-1" data-feather="user"></i>
        {{ "Customer.contactChannelInfo" | translate }}
      </b>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-md-3 col-form-label">
      <strong>{{ "Customer.centralPhoneNumber" | translate }} :</strong>
    </div>
    <div class="col-md-9 col-form-label">
      {{ customerObj.contactPhone ? customerObj.contactPhone : "-" }}
    </div>
  </div>

  <div class="form-group row">
    <div class="col-md-3 col-form-label">
      <strong>{{ "Customer.centralEmail" | translate }} :</strong>
    </div>
    <div class="col-md-9 col-form-label">
      {{ customerObj.contactEmail ? customerObj.contactEmail : "-" }}
    </div>
  </div>

  <div class="form-group row">
    <div class="col-md-3 col-form-label">
      <strong>{{ "Customer.faxNumber" | translate }} :</strong>
    </div>
    <div class="col-md-9 col-form-label">
      {{ customerObj.contactFax ? customerObj.contactFax : "-" }}
    </div>
  </div>

  <div class="form-group row">
    <div class="col-md-3 col-form-label">
      <strong>{{ "Customer.website" | translate }} :</strong>
    </div>
    <div class="col-md-9 col-form-label">
      {{ customerObj.contactWebsite ? customerObj.contactWebsite : "-" }}
    </div>
  </div>

  <div class="form-group row">
    <div class="col-md-3 col-form-label">
      <strong>{{ "Address.address" | translate }} :</strong>
    </div>
    <div class="col-md-9 col-form-label">
      {{ GetAddressTemplate(contactAddrObj) }}
    </div>
  </div>

  <app-customer-addr #CustomerAddress></app-customer-addr>
</ng-template>

<ng-template #orderHistory>
  <div style="background: #f0f0f0" class="col-md-12 form-group border">
    <div class="d-flex p-1 justify-content-between align-items-center">
      <b class="title-customer"
        ><i [size]="22" class="feather_title mr-1" data-feather="user"></i
        >{{ "General.OrderHistory" | translate }}</b
      >
    </div>
  </div>

  <div class="nav-vertical d-flex">
    <ul
      ngbNav
      #navVertical="ngbNav"
      class="nav nav-pills flex-column text-nowrap"
      [destroyOnHide]="false"
      style="width: 200px"
    >
      <li ngbNavItem>
        <a ngbNavLink>{{ "General.Buy" | translate }}</a>
        <ng-template ngbNavContent>
          <h4 class="general-title">{{ "Customer.BuyHistory" | translate }}</h4>
          <ng-container
            *ngTemplateOutlet="
              orderTable;
              context: {
                dtOptions: buyOrderDtOptions,
                list: buyOrderList,
                orderCodeColumn: 'buyOrderCode'
              }
            "
          ></ng-container>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink>{{ "General.Sell" | translate }}</a>
        <ng-template ngbNavContent>
          <h4 class="general-title">
            {{ "Customer.SellHistory" | translate }}
          </h4>
          <ng-container
            *ngTemplateOutlet="
              orderTable;
              context: {
                dtOptions: orderDtOptions,
                list: orderList,
                orderCodeColumn: 'orderCode'
              }
            "
          ></ng-container>
        </ng-template>
      </li>
    </ul>
    <div
      [ngbNavOutlet]="navVertical"
      class="ml-1"
      style="width: -webkit-fill-available"
    ></div>
  </div>
</ng-template>

<ng-template
  #orderTable
  let-dtOptions="dtOptions"
  let-list="list"
  let-orderCodeColumn="orderCodeColumn"
>
  <table [dtOptions]="dtOptions" class="table" datatable>
    <thead>
      <tr style="width: 100% !important">
        <th width="10%" class="text-center">#</th>
        <th width="35%">Order Code</th>
        <th width="20%" class="text-center">
          {{ "General.Status" | translate }}
        </th>
        <th width="15%" class="text-center">
          {{ "General.Date" | translate }}
        </th>
        <th width="20%" class="text-center">
          {{ "General.Total" | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="w-100" *ngIf="isLoading">
        <td class="text-center" *ngFor="let num of [].constructor(5)">
          <div class="skeleton-loading"></div>
        </td>
      </tr>

      <tr *ngIf="list.length < 1 && !isLoading">
        <td colspan="100%" class="text-center">
          <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
        </td>
      </tr>

      <tr *ngFor="let order of list; let ind = index">
        <td class="text-center">{{ ind + 1 }}</td>
        <td>
          <a
            (click)="openRelatedDoc(order.id, orderCodeColumn)"
            class="text-primary"
          >
            {{ order[orderCodeColumn] }}
          </a>
        </td>
        <td class="text-center">
          <app-document-status-badge
            [status]="order.transactionStatusId"
            [isDelete]="order.isDelete"
          ></app-document-status-badge>
        </td>
        <td class="text-center">
          <span *ngIf="currentLang == 'en'">
            {{ order.startDate | date : "dd MMM YYYY" }}
          </span>
          <span *ngIf="currentLang == 'th'">
            {{ order.startDate | thaidate : "DD MMM YYYY" }}
          </span>
        </td>
        <td class="text-end">
          <div class="mr-2">
            {{ order.grandTotal | number : "1.2-2" }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>


