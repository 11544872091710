import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FrontendUserComponent } from './frontend-user/frontend-user.component';
import { AuthGuard } from 'app/auth/helpers';
import { FrontendRolesComponent } from './frontend-roles/frontend-roles.component';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { CoreCommonModule } from '@core/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'app/main/components/components.module';
import { CsvModule } from '@ctrl/ngx-csv';
import { DataTablesModule } from 'angular-datatables';

const routes: Routes = [
  {
    path: 'admin/frontend/users',
    component: FrontendUserComponent,
    canActivate: [AuthGuard],
    data: { animation: 'users-manage', roles: ['SuperAdmin'] },
  },
  {
    path: 'admin/frontend/users/create',
    component: FrontendUserComponent,
    canActivate: [AuthGuard],
    data: { animation: 'users-create', roles: ['SuperAdmin'] },
  },
  {
    path: 'admin/frontend/users/edit/:id',
    component: FrontendUserComponent,
    canActivate: [AuthGuard],
    data: { animation: 'users-edit', roles: ['SuperAdmin'] },
  },
  {
    path: 'admin/frontend/roleset',
    component: FrontendRolesComponent,
    canActivate: [AuthGuard],
    data: { animation: 'roles-manage', roles: ['SuperAdmin'] },
  },
  {
    path: 'admin/frontend/roleset/create',
    component: FrontendRolesComponent,
    canActivate: [AuthGuard],
    data: { animation: 'roles-create', roles: ['SuperAdmin'] },
  },
  {
    path: 'admin/frontend/roleset/edit/:id',
    component: FrontendRolesComponent,
    canActivate: [AuthGuard],
    data: { animation: 'roles-create', roles: ['SuperAdmin'] },
  },
];

@NgModule({
  declarations: [
    FrontendUserComponent,
    FrontendRolesComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    CoreCommonModule,
    NgbModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    CsvModule,
    DataTablesModule
  ]
})
export class FrontendModule { }
