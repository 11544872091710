import { CurrencyPipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { TempRowQuotation } from 'app/main/model/Quotation';
import { DocumentService } from 'app/main/pages/document/document.service';
import { ApiService } from 'app/main/service/api.service';
import { CalculateFuncService } from 'app/main/service/calculate-func.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { ComponentsService } from '../components.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../modal/modal.component';
import { ModalTrackingNumberComponent } from '../modal-tracking-number/modal-tracking-number.component';
import { environment } from 'environments/environment';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { StockType, kStockType } from 'app/main/model/StockType';
import { env } from 'process';
import { swalProviderToken } from '@sweetalert2/ngx-sweetalert2/lib/di';
import { creditNoteReasons } from 'app/main/model/CreditNoteReason';
import { debitNoteReasons } from 'app/main/model/DebitNoteReason';

@Component({
  selector: 'app-content-document',
  templateUrl: './content-document.component.html',
  styleUrls: ['./content-document.component.scss'],
})
export class ContentDocumentComponent implements OnInit, AfterViewInit {
  contentHeader: object;
  currentUser: User;
  currentLang: string;
  DeliveryProvider: any;
  @BlockUI() blockUI: NgBlockUI;
  @Input() titleDoc: string;
  @Input() customerAddressObj: CustomerAddress;
  @Input() apiPath: string;
  @Input() editPathUrl: string;
  @Input() detailPathUrl: string;
  @Input() typeDocName: string;
  @Input() isDisabledEditBtn: boolean = false;
  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  @Input() docId: string;
  @Input() docStatus: number;
  @Input() refDocID: string;
  @Input() refDetailUrl: string;
  @Input() refEditUrl: string;
  @Input() docCodeColumn: string;

  sumNetPrice: number;
  sumVatPrice: number;
  isLoading: boolean;
  @Input() detailObj!: any;
  @Input() invoiceDetailObj: any;
  @Input() isEditRefUrl: boolean = false;
  @Input() isCreateTaxInvoice: boolean;
  @Input() isRevenueDoc: boolean = false;
  isJoinIV_TX_RE: boolean = false;
  isQuantityDecimal: boolean = environment.transaction.isQuantityDecimal;
  shippingFee: number = environment.transaction.shippingFee;

  detailTXPath: string;

  stockType = StockType;
  kStockType = kStockType;

  @Input() pageName: string;

  constructor(
    private _documentService: DocumentService,
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _documentApiService: DocumentApiService,
    private _route: ActivatedRoute,
    public _router: Router,
    private currencyPipe: CurrencyPipe,
    private _apiService: ApiService,
    private _calculateService: CalculateFuncService,
    private _componentsService: ComponentsService,
    private _modalService: NgbModal,
    private _globalFuncService: GlobalFuncService
  ) {
    this.isJoinIV_TX_RE = environment.transaction.isJoinIV_TX_RE;
    this.detailTXPath = this._documentService.Tax.DetailPathURL;
    // console.log(this.apiPath);
    // console.log(this.pageName);
  }

  SetLoadingState() {
    this.isLoading = true;
  }
  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;

    this._authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.contentHeader = {
      headerTitle: 'Document.Quotation',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
          {
            name: this._translateService.instant('Document.Quotation'),
            isLink: true,
            link: this.apiPath,
          },
          {
            name:
              'Detail ' + this._translateService.instant('Document.Quotation'),
            isLink: false,
            link: this.detailPathUrl,
          },
        ],
      },
    };

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailObj) {
      // console.log('changes.detailObj', changes.detailObj.currentValue.deliveryProviderId);
      let deliveryProviderId =
        changes.detailObj.currentValue.deliveryProviderId;

      if (deliveryProviderId) {
        this._apiService
          .GetDataById('MasterDeliveryProvider', deliveryProviderId)
          .subscribe((res) => {
            console.log('DATA ', res.data.resultData[0]);
            this.DeliveryProvider = res.data.resultData[0];
          });
      }
    }
  }

  ngAfterViewInit(): void {}

  get() {
    console.log(this.detailObj);
  }
  handleChildValueChange(newValue: string) {
    const subparentValue = newValue;
    this.detailObj.taxInvoiceNo = subparentValue;
  }

  initData(): void {}

  // getCustomerAddress(customerId: string): Promise<any> {
  //   var self = this;
  //   return new Promise((resolve, rejects) => {
  //     this._apiService
  //       .GetAllData('customerAddress', {
  //         customerId: customerId,
  //         isDelete: false,
  //       })
  //       .subscribe((res) => {
  //         self.customerAddressObj = res.data.resultData[0];
  //       });
  //   });
  // }

  calculateSummary() {
    let itemList = [];
    this.detailObj.item.forEach((element, index) => {
      let tempRow: TempRowQuotation = new TempRowQuotation();
      tempRow.rowType = 'item';
      tempRow.itemId = element.itemId;
      tempRow.description = element.description[0].description;
      // tempRow.discount = element.discount;
      tempRow.preTaxDiscount = element.preTaxDiscount;
      tempRow.quantity = element.quantity;
      tempRow.unit = element.unit;
      tempRow.unitPrice = element.unitPrice;
      tempRow.whtRate = 0.0;

      itemList.push(tempRow);
    });

    this.sumNetPrice = this._calculateService.calculateNet(
      itemList,
      true,
      this.detailObj.taxRate,
      this.detailObj.preTaxDiscount
      // this.detailObj.discount
    );

    this.sumVatPrice = this._calculateService.calculateVat(
      this.sumNetPrice,
      this.detailObj.taxRate
    );
    this.SetLoadedState();
  }

  convertNumbertoMoneyFormat(
    value: number,
    disableDecimal: boolean = false
  ): string {
    return this._globalFuncService.FormatToMoney(value, false, disableDecimal);
  }

  openGenTXModal(): void {
    let title = this._translateService.instant(
      'Modal.ConfirmTheCreateTaxInvoice'
    );
    let detail = this._translateService.instant(
      'Modal.AreYouSureToCreateTaxInvoice'
    );
    this.openModal(title, detail);
  }

  openModal(title: string, detail: string): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.generateTaxInvoice();
    });
  }

  openCancelDocModal(): void {
    let title =
      this._translateService.instant('Modal.ConfirmTheCancellation') +
      this.titleDoc;
    let detail = this._translateService.instant(
      'Modal.AreYouSureToCancellation',
      {
        title: this.titleDoc,
        docCode: '#' + this.detailObj[this.typeDocName],
      }
    );
    this.openCancelModal(title, detail);
  }

  openCancelModal(title: string, detail: string): void {
    var self = this;
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res: any) => {
      this.blockUI.start();
      this._documentApiService
        .CancelVoidDocument(this.apiPath, this.docId)
        .subscribe(
          (res) => {
            let title: string;
            if (
              this.apiPath == 'Quotation' ||
              this.apiPath == 'SaleOrder' ||
              this.apiPath == 'PurchaseRequisition' ||
              this.apiPath == 'PurchaseOrder'
            ) {
              title = this._translateService.instant(
                'Alert.CancelDocumentSuccess'
              );
            } else {
              title = this._translateService.instant(
                'Alert.VoidDocumentSuccess'
              );
            }
            this.blockUI.stop();
            this._componentsService.SuccessSwal(title);
            this.refreshData.emit();
          },
          (err) => {
            let errorMessage = this._translateService.instant(
              'Alert.PleaseCancelVoid'
            );
            this.blockUI.stop();
            let SwalOption: SweetAlertOptions = {};
            SwalOption.title = `${self._translateService.instant(
              'Alert.Error'
            )}`;
            SwalOption.text = `${errorMessage}`;
            SwalOption.html =
              errorMessage +
              '<br/>' +
              `<a class="link-relatedDoc" href='javascript:void(0)' routerLink="${
                this.detailPathUrl
              }/${this.docId}#relatedDoc">${self._translateService.instant(
                'General.RelatedDocument'
              )}</a>`;
            SwalOption.onOpen = () => {
              const b =
                Swal.getHtmlContainer().getElementsByClassName(
                  'link-relatedDoc'
                )[0];
              b.addEventListener('click', function (e) {
                let url = b.getAttribute('routerlink');
                self._router.navigateByUrl(url);
                Swal.close();
              });
            };
            SwalOption.icon = 'error';
            Swal.fire(SwalOption);
          }
        );
    });
  }

  openConfirmCODModal(): void {
    if (this.detailObj.customerPaymentTypeEnum == 5) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });

      modalRef.componentInstance.title = this._translateService.instant('General.DeliveryProvider');
      modalRef.componentInstance.detail = this._translateService.instant('General.AreYouSureToReleaseCOD');
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        this.openModalTracking();
      });
      modalRef.componentInstance.cancelFunc.subscribe((res) => {
        modalRef.close();
        let subModalRef = this._modalService.open(ModalComponent, {
          centered: true,
          backdrop: 'static',
        });

        subModalRef.componentInstance.title = this._translateService.instant('General.ConfirmCancelOrdertitle');
        subModalRef.componentInstance.detail = this._translateService.instant('General.ConfirmCancelOrdertitleDetail');
        subModalRef.componentInstance.callBackFunc.subscribe((res) => {
          this.blockUI.start();
          this._documentApiService
            .CancelOrder(this.detailObj.orderId)
            .subscribe((res) => {
              Swal.fire(this._translateService.instant('General.Success'), this._translateService.instant('General.CancelOrderSuccess'), 'success');
              this.refreshData.emit();
              this.blockUI.stop();
            });
        });
      });
    } else {
      this.openModalTracking();
    }
  }

  openModalTracking(): void {
    const modalRef = this._modalService.open(ModalTrackingNumberComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = this._translateService.instant(
      'ContentDocument.TrackingNumber'
    );
    modalRef.componentInstance.DeliveryProvider =
      this._translateService.instant('General.DeliveryProvider');
    modalRef.componentInstance.id = this.detailObj.id;
  }

  SetInventoryStatus(): void {
    var self = this;
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = 'Set Delivery';
    modalRef.componentInstance.detail =
      'Are you sure to transfer all items into stock?';
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.blockUI.start();
      this._documentApiService
        .SetInventoryStatus(this.apiPath, this.docId)
        .subscribe(
          (res) => {
            this.blockUI.stop();
            this.refreshData.emit();
            this._componentsService.SuccessSwal();
          },
          (err) => {
            this.blockUI.stop();
            this._componentsService.ErrorSwal();
          }
        );
    });
  }

  SetInventoryCompleteAll(): void {
    var self = this;
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = 'Transfer Items to Stock';
    modalRef.componentInstance.detail =
      'Are you sure to transfer all items into stock?';
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.blockUI.start();
      this._documentApiService
        .SetInventoryCompleteAll(this.apiPath, this.docId)
        .subscribe(
          (res) => {
            this.blockUI.stop();
            this.refreshData.emit();
            this._componentsService.SuccessSwal();
          },
          (err) => {
            this.blockUI.stop();
            this._componentsService.ErrorSwal();
          }
        );
    });
  }

  generateTaxInvoice(): void {
    this.blockUI.start();
    this._documentApiService.GenerateTaxInvoice(this.detailObj.id).subscribe(
      (res) => {
        this.blockUI.start();
        let txId = res.data.resultData[0].id;
        let txCode = res.data.resultData[0].code;

        const titleLinkDocButton =
          this._translateService.instant('Alert.GoToTX');

        const textCode = this._translateService.instant('Alert.CodeTX', {
          docCode: txCode,
        });

        this.refreshData.emit();
        this.blockUI.stop();

        this._componentsService.SuccessSwal(
          undefined,
          undefined,
          true,
          titleLinkDocButton,
          this.detailTXPath,
          textCode,
          txId
        );
      },
      (err) => {
        this.blockUI.stop();
        this._componentsService.ErrorSwal();
      }
    );
  }

  GetAddressTemplate(customerAddressObj): string {
    return this._globalFuncService.GetAddressTemplate(customerAddressObj);
  }

  GetReasonDocument(reasonId: number, typeDoc: string) {
    if (typeDoc == 'CreditNote') {
      let reason = creditNoteReasons.find((item) => item.value === reasonId);
      return reason.label;
    } else {
      let reason = debitNoteReasons.find((item) => item.value === reasonId);
      return reason.label;
    }
  }

  openVoidReceiptModal(): void {
    let title = this._translateService.instant('Modal.ConfirmVoidGotReceipt');
    let detail = this._translateService.instant(
      'Modal.AreYouSureToVoidGotReceipt'
    );
    this.openReceiptModal(title, detail);
  }

  openReceiptModal(title: string, detail: string): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.voidGotReceipt();
    });
  }

  voidGotReceipt(): Promise<any> {
    return new Promise((resolve, rejects) => {
      this._documentApiService.VoidGotReceipt(this.docId).subscribe(
        (res) => {
          let title = this._translateService.instant(
            'Alert.VoidGotReceiptSuccess'
          );
          this._componentsService.SuccessSwal(title);
          // this.initData();
          this.refreshData.emit();
        },
        (error) => {
          this._componentsService.ErrorSwal();
        }
      );
    });
  }

  createDocFromThisData() {
    let path = this.isRevenueDoc
      ? this._documentService.quotation.PathURL
      : this._documentService.purchaseRequest.PathURL;

    this._router.navigate([
      `${path}/create`,
      { copyObj: JSON.stringify(this.detailObj) },
    ]);
  }
}
