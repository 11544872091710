<div class="paper-tool-btn container-xxl">
    <button class="btn btn-secondary" (click)="goBackPage()">
        {{ "General.Back" | translate }}
    </button>

    <div>
        พิมพ์ใบปะหน้าพัสดุ : {{ quotationObj ? quotationObj[docCodeColumn] : "-" }}
    </div>

    <div>
        <!-- <button
        class="btn mr-1"
        [ngClass]="{
          'btn-outline-primary': showPaperTool,
          'btn-dark': !showPaperTool
        }"
        (click)="showPaperTool = !showPaperTool"
      >
        <i data-feather="settings"></i>
        {{ "DocumentPaper.FixedPage" | translate }}
      </button> -->
        <button class="btn btn-warning" (click)="printWindow()">
            <i data-feather="printer"></i>
            {{ "DocumentPaper.Print" | translate }}
        </button>
    </div>
</div>

<div>
    <table class="paper">
        <tr>
            <td colspan="4" style="border: 0px;">
                <span class="text-dark" style="float: right; font-size: 20px; font-weight: bold;">
                    TEL:1436
                </span>
            </td>
        </tr>
        <tr>
            <td colspan="4" style="text-align: center; border: 0px; height: 50px;">
                <span class="text-dark" style="font-weight: bold; vertical-align: bottom; font-size: 36px;">
                    05C-19022-03
                </span>
                <app-barcode-generator value="{{quotationObj ? quotationObj.trackingNumber : ''}}"></app-barcode-generator>
            </td>
        </tr>

        <tr>
            <td colspan="4" style="height: 50px;">
                <span class="text-dark" style="font-size: 18px;">
                    DST <strong>NPA_SP-นาป่า</strong>
                </span>
            </td>
        </tr>

        <tr style="vertical-align:top;">
            <td colspan="3" style="height: 70px; width: 70%;">
                <ng-container *ngTemplateOutlet="SenderAddress;">
                </ng-container>
            </td>

            <td style="text-align: center; vertical-align:middle; background-color: black;" colspan="2">
                <span style="font-size: 48px; font-weight: bold; color: white;">
                    E08
                </span>
            </td>
        </tr>

        <tr style="vertical-align:top;">
            <td colspan="4" style="height: 80px;">
                <ng-container *ngTemplateOutlet="CustomerAddress;">
                </ng-container>
            </td>
        </tr>

        <tr>
            <td colspan="4" style="height: 40px;">
                <span>
                    Order ID : {{ quotationObj ? quotationObj[docCodeColumn] : "-" }}
                </span>
            </td>
        </tr>

        <tr style="height: 40px;">
            <td colspan="2">

            </td>
            <td colspan="2">

            </td>
        </tr>
        <tr>
            <td class="text-dark" colspan="4" style="border: 0px;">
                <span style="float: right;">Print: {{ thaiBuddhistYear }}{{dateTime | date: '-MM-dd HH:mm:ss' | translate}}</span>
            </td>
        </tr>
    </table>
</div>

<ng-template #CustomerAddress>
    <div>
        <p style="text-align: left">
            <span style="border: 1px solid black; border-radius: 5px;">ผู้รับ</span> {{ quotationObj ? quotationObj.shippingCustomerName : "-" }}
            <span>
                {{ quotationObj ? formatPhoneNumber(quotationObj.shippingPhone): "-"}}
            </span>
            <br>
            <span>
                {{
                quotationObj ? quotationObj.shippingAddressDetail : "-"
                }}
            </span>
        </p>
    </div>
</ng-template>

<ng-template #SenderAddress>
    <div>
        <p style="text-align: left">
            ผู้ส่ง {{ quotationObj ? quotationObj.issuerName : '-'}}
            <span>
                {{ quotationObj ? quotationObj.issuerPhone : '-' }}
            </span>
            <span>
                {{ quotationObj ? quotationObj.issuerAddressDetail : '-' }}
            </span>
        </p>
    </div>
</ng-template>

<ng-template #textBox let-text="text" let-text2="text2" let-align="align" let-fontSize="fontSize"
    let-fontSize2="fontSize2" let-fontWeight="fontWeight">
    <div>
        <p class="text-dark" [ngStyle]="{'text-align': align, 'font-size': fontSize, 'font-weight': fontWeight}">
            {{text}}
            <span [ngStyle]="{'font-size': fontSize2}">
                {{text2}}
            </span>
        </p>
    </div>
</ng-template>