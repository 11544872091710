<div class="d-flex flex-column" style="height: 100%">
  <div class="modal-header">
    <h5 *ngIf="isBankAccount" class="modal-title" id="exampleModalCenterTitle">
      {{
        isEditing
          ? ("Payment.Modal.EditBankAccount" | translate)
          : ("Payment.Modal.CreateBankAccount" | translate)
      }}
    </h5>
    <button
      type="button"
      class="close"
      (click)="closeModal()"
      aria-label="Close"
    >
      <span aria-hidden="true">
        <i data-feather="x" size="22"></i>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <app-transfer-form
      *ngIf="isBankAccount"
      (callBackFormData)="receiveFormData($event)"
      (CallBackBankID)="getBankId($event)"
      [isSubmit]="isSubmit"
      [editData]="editData"
      [isEditing]="isEditing"
    ></app-transfer-form>
    <div class="col-12 mt-2" style="margin-bottom: 0.5rem">

      <app-image-upload #imageUploadComponent >
      </app-image-upload>

  </div>
  </div>
  <div class="modal-footer justify-content-between">
    <button
      type="button"
      class="btn btn-secondary m-0"
      (click)="openCancelModal()"
      rippleEffect
    >
      {{ "Form.Cancel" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary m-0"
      (click)="openConfirmModal()"
      rippleEffect
    >
      {{ "Form.Confirm" | translate }}
    </button>
  </div>
</div>
