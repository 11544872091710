import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { GroupService } from '../../group/group.service';
import { environment } from 'environments/environment';
import { ItemService } from '../item.service';

@Component({
  selector: 'app-item-formpage',
  templateUrl: './item-formpage.component.html',
  styleUrls: ['./item-formpage.component.scss'],
})
export class ItemFormpageComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();

  componentName: string;
  apiPath: string;
  pathUrl: string;
  contentHeader: object;

  isLoading: boolean;

  selectedLang: string = environment.langDefault;
  langList: string[] = environment.langItem;

  constructor(
    private _route: ActivatedRoute,
    private _itemService: ItemService,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    this.pathUrl = this._itemService.pathUrl;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'General.ItemManage',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.ItemManage',
            isLink: true,
            link: '/item-manage/item',
          },
          {
            name: 'General.Create',
            isLink: false,
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  selectedLanguage(lang: string): void {
    this.selectedLang = lang;
  }
}
