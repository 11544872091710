import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnInit,
  ElementRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CoreConfigService } from '@core/services/config.service';
import { FilesApiService } from 'app/main/service/files-api.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { log } from 'console';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
  public coreConfig: any;
  @Input() idResponse: number;
  @Input() imageMode: boolean;
  
  @Output() uploadFunc: EventEmitter<FormData> = new EventEmitter();
  @Output() inputChanged = new EventEmitter<string>();

  uploadForm: FormGroup;
  selected: Boolean = false;
  haveImage: Boolean = false;
  submition: Boolean = true;
  maxFileSize: number = environment.limitFileSize.maxImageSizeInBytes;

  constructor(
    private formBuilder: FormBuilder,
    private _fileService: FilesApiService,
    private _coreConfigService: CoreConfigService,
    public elementRef: ElementRef,
    private _globalFuncService: GlobalFuncService
  ) {}

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      tablename: 'item',
      contentID: '',
      file: null,
    });

    this._coreConfigService.config.subscribe((config) => {
      this.coreConfig = config;
    });
  }

  imageUrl: string;
  selectedFile: any;
  selectedFileName: string;
  cover: Blob;

  handleImage() {
    if (this.selected) {
      this.submition = true;
    } else {
      this.submition = false;
    }
  }
  onFileSelected(event: any): void {
    this.inputChanged.emit(event);
    console.log('WHAT IS ItemNewPic');

    this.selectedFileName = event.target.files[0].name;
    const file = event.target.files[0];
    const reader = new FileReader();
    const file_input = event.target.files[0];
    this.uploadForm.patchValue({ file: file_input });
    if (file && file.size <= this.maxFileSize) {
      reader.onloadend = () => {
        this.selectedFile = reader.result as string;
      };
      reader.readAsDataURL(file);
      this.cover = file;

      this.selected = true;
      this.handleImage();
    } else {
      console.error(
        `File size exceeds the maximum limit of ${this.bytesToSizes(
          this.maxFileSize
        )}.`
      );
    }
  }

  startLoading() {
    console.log('IMAGE UPLOAD START LOADING....');

    this.haveImage = true;
  }
  setCoverImage(img: Blob, filename: string) {
    var self = this;
    self.selected = false;
    let file = new File([img], filename, {
      type: img.type,
    });
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      self.selected = true;
      let base64str = reader.result;
      self.selectedFile = base64str;
      self.selectedFileName = filename + img.type.replace('image/', '.');
    };
    this.uploadForm.patchValue({ file: file });
  }

  uploadImage(params: {
    isEdit: boolean;
    idResId: any;
    tablename: string;
    collectionName: string;
    ordinal: number;
  }) {
    // const { isUpdatefile_attr, idResId, tablename } = params;

    this.uploadForm.patchValue({ tablename: params.tablename });

    // const tablename = this.uploadForm.value.tablename;
    const contentID = this.uploadForm.value.contentID;
    const file = this.uploadForm.value.file;

    const formData = new FormData();
    formData.append('file', file);
    if (params.isEdit) {
      this._fileService
        .UpdateFile(
          params.tablename,
          params.idResId,
          params.collectionName,
          params.ordinal,
          formData
        )
        .subscribe(
          (response) => {},
          (error) => {
            console.error('Error uploading image:', error);
          }
        );
    } else {
      this._fileService
        .UpdateFile(
          params.tablename,
          params.idResId,
          params.collectionName,
          params.ordinal,
          formData
        )
        .subscribe(
          (response) => {
            console.log('Image uploaded successfully!');
          },
          (error) => {
            console.error('Error uploading image:', error);
          }
        );
    }
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  getFile() {
    return this.uploadForm.value.file;
  }

  bytesToSizes(size: number): string {
    return this._globalFuncService.bytesToSize(size);
  }

  testLog(a: any) {
    console.log(a);
    
  }
}
