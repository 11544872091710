import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralComponent } from './general/general.component';
import { BankManageComponent } from './bank-manage/bank-manage.component';
import { RouterModule, Routes } from '@angular/router';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { CoreCommonModule } from '@core/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'app/main/components/components.module';
import { CsvModule } from '@ctrl/ngx-csv';
import { DataTablesModule } from 'angular-datatables';
import { AuthGuard } from 'app/auth/helpers';
import { SystemSettingsComponent } from './system-settings.component';
import { DocumentInfoComponent } from './general/document-info/document-info.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BranchComponent } from './branch/branch.component';

const routes: Routes = [
  {
    path: 'admin/system-settings',
    component: SystemSettingsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'system-general', roles: ['SuperAdmin'] },
  },
  {
    path: 'admin/system/general',
    component: GeneralComponent,
    canActivate: [AuthGuard],
    data: { animation: 'system-general', roles: ['SuperAdmin'] },
  },
  {
    path: 'admin/system/bank-manage',
    component: BankManageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'bank-manage', roles: ['SuperAdmin'] },
  },
]

@NgModule({
  declarations: [
    GeneralComponent,
    BankManageComponent,
    SystemSettingsComponent,
    DocumentInfoComponent,
    BranchComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    CoreCommonModule,
    NgbModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    CsvModule,
    DataTablesModule,
    DragDropModule
  ]
})
export class SystemSettingsModule { }
