<div class="row ctm-form">
  <div class="col-md-12">
    <form [formGroup]="registerForm" id="customer-form">
      <div style="background: #f0f0f0" class="col-md-12 form-group border">
        <div class="d-flex p-1 justify-content-between align-items-center">
          <b class="title-customer"
            ><i [size]="22" class="feather_title mr-1" data-feather="user"></i
            >{{ "Customer.contactInfo" | translate }}</b
          >
          <!-- <button class="btn btn-sm btn-secondary" *ngIf="isEditing && itemObj">{{ itemObj.customerCode }}</button> -->
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          {{ "Customer.contactType" | translate }}
        </div>
        <div class="col-md-9 d-flex align-items-center">
          <div class="mr-3 custom-control custom-radio">
            <input
              type="radio"
              [value]="true"
              id="businessTRadio1"
              name="islegalEntity"
              formControlName="islegalEntity"
              (change)="isBusinessType(true)"
              class="custom-control-input"
              checked
            />
            <label class="custom-control-label" for="businessTRadio1">{{
              "Customer.JuristicPerson" | translate
            }}</label>
          </div>
          <div class="custom-control custom-radio">
            <input
              type="radio"
              [value]="false"
              id="businessTRadio2"
              name="islegalEntity"
              formControlName="islegalEntity"
              (change)="isBusinessType(false)"
              class="custom-control-input"
              checked=""
            />
            <label class="custom-control-label" for="businessTRadio2">{{
              "Customer.OrdinaryPerson" | translate
            }}</label>
          </div>
        </div>
      </div>

      <div class="form-group row" *ngIf="f.islegalEntity.value">
        <div class="col-md-3 col-form-label">
          {{ "Customer.officeType" | translate }}
          <span class="text-danger">*</span>
        </div>
        <div class="col-md-9">
          <label for="helpInputTop">{{
            "Customer.officeTypeLabel" | translate
          }}</label>
          <small class="text-muted">
            <i> {{ "Customer.pleaseSpecify" | translate }}</i></small
          >
          <select
            class="custom-select"
            formControlName="office_type"
            (change)="isOfficeSelected()"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.office_type?.invalid) ||
                (f.office_type?.invalid &&
                  (f.office_type?.dirty || f.office_type?.touched)) ||
                (f.office_type?.value == '' &&
                  (f.office_type?.dirty || f.office_type?.touched))
            }"
          >
            <option value="">
              -- {{ "Customer.officeTypeLabel" | translate }} --
            </option>
            <option [value]="group.id" *ngFor="let group of BusinessType">
              <span> {{ group.name }} </span>
            </option>
          </select>

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                f.office_type.invalid &&
                (f.office_type.dirty || f.office_type.touched)
            }"
          >
            <div>
              <div *ngIf="f.office_type.errors?.required">
                {{ "Customer.office_type" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block">
        <div class="form-group row">
          <div class="col-md-3 col-form-label">
            {{ "Customer.thaiName" | translate }}
            <span class="text-danger">*</span>
          </div>

          <div class="col-md-9">
            <div class="form-group row">
              <div
                class="col-md-6"
                [ngClass]="{
                  'col-md-6': !f.islegalEntity.value
                }"
                [ngClass]="{
                  'col-md-12': f.islegalEntity.value
                }"
              >
                <label for="helpInputTop"
                  >{{
                    (f.islegalEntity.value
                      ? "Customer.businessName"
                      : "Customer.contactName"
                    ) | translate
                  }}
                  <span class="text-danger">*</span></label
                >

                <input
                  class="form-control"
                  formControlName="customerFirstName"
                  type="text"
                  [ngClass]="{
                    'is-invalid error':
                      (isSubmit && f.customerFirstName?.invalid) ||
                      (f.customerFirstName?.invalid &&
                        (f.customerFirstName?.dirty ||
                          f.customerFirstName?.touched)) ||
                      (f.customerFirstName?.value == '' &&
                        (f.customerFirstName?.dirty ||
                          f.customerFirstName?.touched))
                  }"
                />

                <div
                  class="invalid-feedback"
                  [ngClass]="{
                    'd-block':
                      f.customerFirstName.invalid &&
                      (f.customerFirstName.dirty || f.customerFirstName.touched)
                  }"
                >
                  <div>
                    <div *ngIf="f.customerFirstName.errors?.required">
                      {{ "Customer.nameRequiredError" | translate }}
                    </div>
                    <div *ngIf="f.customerFirstName.errors?.maxlength">
                      {{ "Handle.Exceed" | translate }} 50
                      {{ "Handle.Char" | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-none col-md-6"
                [ngClass]="{
                  'd-none': f.islegalEntity.value
                }"
                [ngClass]="{
                  'd-block': !f.islegalEntity.value
                }"
              >
                <label for="helpInputTop"
                  >{{ "Customer.lastName" | translate }}
                  <span class="text-danger">*</span></label
                >

                <input
                  class="form-control"
                  formControlName="customerLastName"
                  type="text"
                  [ngClass]="{
                    'is-invalid error':
                      (isSubmit &&
                        f.customerLastName?.value == '' &&
                        !f.islegalEntity.value) ||
                      (f.customerLastName?.touched &&
                        f.customerLastName?.value == '' &&
                        !f.islegalEntity.value)
                  }"
                />

                <div
                  class="invalid-feedback"
                  [ngClass]="{
                    'd-block':
                      (isSubmit &&
                        f.customerLastName?.value == '' &&
                        !f.islegalEntity.value) ||
                      (f.customerLastName?.touched &&
                        f.customerLastName?.value == '' &&
                        !f.islegalEntity.value)
                  }"
                >
                  <div>
                    <div
                      *ngIf="
                        (isSubmit &&
                          f.customerLastName?.value == '' &&
                          !f.islegalEntity.value) ||
                        (f.customerLastName?.touched &&
                          f.customerLastName?.value == '' &&
                          !f.islegalEntity.value)
                      "
                    >
                      {{ "Customer.lastNameRequiredError" | translate }}
                    </div>
                    <div *ngIf="f.customerLastName.errors?.maxlength">
                      {{ "Handle.Exceed" | translate }} 50
                      {{ "Handle.Char" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row" *ngIf="f.islegalEntity.value">
        <div class="col-md-3 col-form-label">
          {{ "Customer.office" | translate }}
        </div>
        <div class="col-md-9 d-flex align-items-center">
          <div class="custom-control custom-radio mr-2">
            <input
              type="radio"
              id="havecompany2"
              value="1"
              name="office"
              class="custom-control-input"
              formControlName="office"
              checked
            />
            <label class="custom-control-label" for="havecompany2">{{
              "Customer.headOffice" | translate
            }}</label>
          </div>
          <div class="custom-control custom-radio mr-2">
            <input
              type="radio"
              id="havecompany3"
              value="2"
              name="office"
              (blur)="updateBranchNumberValue()"
              class="custom-control-input"
              formControlName="office"
            />
            <label class="custom-control-label" for="havecompany3">{{
              "Customer.branch" | translate
            }}</label>
          </div>
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="
          registerForm.controls.office.value === '2' && f.islegalEntity.value
        "
      >
        <div class="col-md-3 col-form-label"></div>
        <div class="col-md-9">
          <div class="card mb-0 mt-2">
            <div class="card-body border">
              <div class="form-group">
                <label for="helpInputTop"
                  >{{ "Customer.branchCodeLabel" | translate }}
                  <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="branchNumber"
                  id="helpInputTop"
                  type="number"
                  (blur)="
                    f.branchNumber.setValue(
                      f.branchNumber.value.toString().padStart(5, '0')
                    )
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  [inputMode]="'numeric'"
                  placeholder="{{
                    'Customer.branchCodePlaceholder' | translate
                  }}"
                  pattern="\d*"
                  maxlength="10"
                  [ngClass]="{
                    'is-invalid error':
                      (isSubmit &&
                        registerForm.controls.office.value === '2' &&
                        f.branchNumber?.value == '') ||
                      (f.branchNumber?.value == '' &&
                        f.branchNumber?.touched) ||
                      (f.branchNumber.invalid &&
                        (f.branchNumber.dirty || f.branchNumber.touched))
                  }"
                />

                <div
                  class="invalid-feedback"
                  [ngClass]="{
                    'd-block':
                      f.branchNumber.invalid &&
                      (f.branchNumber.dirty || f.branchNumber.touched)
                  }"
                >
                  <div>
                    <div *ngIf="f.branchNumber.errors?.message">
                      {{ f.branchNumber.errors.message }}
                    </div>
                    <div *ngIf="f.branchNumber.errors?.maxlength">
                      {{ "Handle.Exceed" | translate }} 5
                      {{ "Handle.Char" | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="helpInputTop"
                  >{{ "Customer.branchNameLabel" | translate }}
                  <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="branchName"
                  placeholder="{{
                    'Customer.branchNamePlaceholder' | translate
                  }}"
                  [ngClass]="{
                    'is-invalid error':
                      (isSubmit &&
                        registerForm.controls.office.value === '2' &&
                        f.branchName?.value == '') ||
                      (registerForm.controls.office.value === '2' &&
                        f.branchName?.value == '' &&
                        f.branchName?.touched)
                  }"
                />

                <div
                  class="invalid-feedback"
                  [ngClass]="{
                    'd-block':
                      (isSubmit &&
                        registerForm.controls.office.value === '2' &&
                        f.branchName?.value == '') ||
                      (registerForm.controls.office.value === '2' &&
                        f.branchName?.value == '' &&
                        f.branchName?.touched)
                  }"
                >
                  <div>
                    <div *ngIf="f.branchName.errors?.message">
                      {{ f.branchName.errors.message }}
                    </div>
                    <div *ngIf="f.branchName.errors?.maxlength">
                      {{ "Handle.Exceed" | translate }} 5
                      {{ "Handle.Char" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row" *ngIf="!f.islegalEntity.value">
        <div class="col-md-3 col-form-label">
          {{ "General.Gender" | translate }}
        </div>
        <div class="col-md-9">
          <select
            class="custom-select"
            formControlName="genderId"
          >
            <!-- <option value="">-- {{ 'User.SelectGender' | tsranslate }} --</option> -->
            <option
              [ngValue]="gender.value"
              *ngFor="let gender of kGender"
              [selected]="f.genderId?.value == gender.value"
            >
              <span> {{ "Gender." + gender.name | translate }} </span>
            </option>
          </select>

          
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-form-label">
          {{ "Customer.selectCountry" | translate }}
        </div>
        <div class="col-md-9 d-flex align-items-center">
          <div class="mr-3 custom-control custom-radio">
            <input
              type="radio"
              value="1"
              id="customRadio1"
              name="country"
              formControlName="country"
              class="custom-control-input"
              checked
            />
            <label class="custom-control-label" for="customRadio1">{{
              "Customer.country" | translate
            }}</label>
          </div>
          <div class="custom-control custom-radio">
            <input
              type="radio"
              value="2"
              id="customRadio2"
              name="country"
              formControlName="country"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="customRadio2">{{
              "Customer.countryOther" | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="form-group row pt-2 pb-2">
        <div class="col-md-3">{{ "Customer.idNumber" | translate }}</div>
        <div class="col-md-9">
          <div class="min-h-screen">
            <div id="PersonalID">
              <input
                *ngFor="let i of otpLengthArray; let index = index"
                [ngClass]="'siam_input_p_id'"
                [id]="'otp-field' + i"
                [style.marginRight.px]="
                  index === 0 || index === 4 || index === 9 ? 10 : 0
                "
                [style.borderColor]="check_pid_empty ? '#ea5455' : '#a1a1a180'"
                type="number"
                [inputMode]="'numeric'"
                [pattern]="'[0-9]*'"
                placeholder=""
                maxlength="1"
                min="0"
                (keydown)="onKeyDown($event, i)"
                (input)="onInput($event, i)"
                (paste)="onPaste($event)"
              />
            </div>
            <div
              class="invalid-feedback"
              [ngClass]="{
                'd-block': check_pid_empty
              }"
            >
              <div>{{ "Customer.fillOutCompletely" | translate }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div *ngIf="isEditing" class="col-md-3 col-form-label pb-2">
          <!-- {{ "Customer.office" | translate }} -->
          {{ "General.Status" | translate }}
        </div>
        <div *ngIf="isEditing" class="col-md-9 d-flex align-items-center pb-2">
          <div class="custom-control custom-switch mr-2">
            <input
              class="custom-control-input"
              type="checkbox"
              id="customSwitch1"
              formControlName="isActive"
            />
            <label class="custom-control-label" for="customSwitch1"></label>
          </div>
        </div>
      </div>

      <!-- <app-customer-acc></app-customer-acc> -->

      <div style="background: #f0f0f0" class="col-md-12 form-group border">
        <div class="d-flex p-1 justify-content-between align-items-center">
          <b class="title-customer">
            <i
              [size]="22"
              class="feather_titlemr-1"
              data-feather="user-check"
            ></i>
            {{ "Customer.personalInfo" | translate }}
          </b>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12 col-form-lable mb-2">
          <u>{{ "Customer.primaryContact" | translate }}</u> <br />
          <label for="helpInputTop">{{
            "Customer.specifyContactInfo" | translate
          }}</label>
          <small class="text-muted">
            <i> {{ "Customer.pleaseSpecify" | translate }}</i></small
          >
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-6">
          <div class="col-form-lable">
            {{ "Customer.firstName" | translate }}
            <span class="text-danger" *ngIf="isRequiredDetail">*</span>
          </div>
          <input
            class="form-control"
            formControlName="fname"
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.fname?.invalid) ||
                (f.fname?.invalid && (f.fname?.dirty || f.fname?.touched)) ||
                (isRequiredDetail &&
                  f.fname?.value == '' &&
                  (f.fname?.dirty || f.fname?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.fname?.invalid) ||
                (f.fname.invalid && (f.fname.dirty || f.fname.touched)) ||
                (isRequiredDetail &&
                  f.fname?.value == '' &&
                  (f.fname?.dirty || f.fname?.touched))
            }"
          >
            <div>
              <div *ngIf="f.fname.errors?.required">
                {{ "Customer.nameRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-form-lable">
            {{ "Customer.lastName" | translate }}
            <span class="text-danger" *ngIf="isRequiredDetail">*</span>
          </div>
          <input
            class="form-control"
            formControlName="lname"
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.lname?.invalid) ||
                (f.lname?.invalid && (f.lname?.dirty || f.lname?.touched)) ||
                (isRequiredDetail &&
                  f.lname?.value == '' &&
                  (f.lname?.dirty || f.lname?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.lname?.invalid) ||
                (f.lname.invalid && (f.lname.dirty || f.lname.touched)) ||
                (isRequiredDetail &&
                  f.lname?.value == '' &&
                  (f.lname?.dirty || f.lname?.touched))
            }"
          >
            <div>
              <div *ngIf="f.lname.errors?.required">
                {{ "Customer.lastNameRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-1">
          <div class="col-form-lable">
            {{ "Customer.nickname" | translate }}
            <span class="text-danger" *ngIf="isRequiredDetail">*</span>
          </div>
          <input
            class="form-control"
            formControlName="n_name"
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.n_name?.invalid) ||
                (f.n_name?.invalid && (f.n_name?.dirty || f.n_name?.touched)) ||
                (isRequiredDetail &&
                  f.n_name?.value == '' &&
                  (f.n_name?.dirty || f.n_name?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.n_name?.invalid) ||
                (f.n_name.invalid && (f.n_name.dirty || f.n_name.touched)) ||
                (isRequiredDetail &&
                  f.n_name?.value == '' &&
                  (f.n_name?.dirty || f.n_name?.touched))
            }"
          >
            <div>
              <div *ngIf="f.n_name.errors?.required">
                {{ "Customer.nicknameRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-1">
          <div class="col-form-lable">
            {{ "Customer.position" | translate }}
            <span class="text-danger" *ngIf="isRequiredDetail">*</span>
          </div>
          <input
            class="form-control"
            formControlName="rank"
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.rank?.invalid) ||
                (f.rank?.invalid && (f.rank?.dirty || f.rank?.touched)) ||
                (isRequiredDetail &&
                  f.rank?.value == '' &&
                  (f.rank?.dirty || f.rank?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.rank?.invalid) ||
                (f.rank.invalid && (f.rank.dirty || f.rank.touched)) ||
                (isRequiredDetail &&
                  f.rank?.value == '' &&
                  (f.rank?.dirty || f.rank?.touched))
            }"
          >
            <div>
              <div *ngIf="f.rank.errors?.required">
                {{ "Customer.positionRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-1">
          <div class="col-form-lable">
            {{ "Customer.email" | translate }}
            <span class="text-danger" *ngIf="isRequiredDetail">*</span>
          </div>
          <input
            class="form-control"
            formControlName="email"
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.email?.invalid) ||
                (f.email?.invalid && (f.email?.dirty || f.email?.touched)) ||
                (isRequiredDetail &&
                  f.email?.value == '' &&
                  (f.email?.dirty || f.email?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.email?.invalid) ||
                (f.email.invalid && (f.email.dirty || f.email.touched)) ||
                (isRequiredDetail &&
                  f.email?.value == '' &&
                  (f.email?.dirty || f.email?.touched))
            }"
          >
            <div>
              <div *ngIf="f.email.errors?.required">
                {{ "Customer.emailRequiredError" | translate }}
              </div>
              <div *ngIf="f.businessName.errors?.pattern">
                {{ "Customer.syntaxError" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-1">
          <div class="col-form-lable">
            {{ "Customer.phone" | translate }}
            <span class="text-danger" *ngIf="isRequiredDetail">*</span>
          </div>
          <input
            class="form-control"
            formControlName="phone"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.phone?.invalid) ||
                (f.phone?.invalid && (f.phone?.dirty || f.phone?.touched)) ||
                (isRequiredDetail &&
                  f.phone?.value == '' &&
                  (f.phone?.dirty || f.phone?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.phone?.invalid) ||
                (f.phone.invalid && (f.phone.dirty || f.phone.touched)) ||
                (isRequiredDetail &&
                  f.phone?.value == '' &&
                  (f.phone?.dirty || f.phone?.touched))
            }"
          >
            <div>
              <div *ngIf="f.phone.errors?.required">
                {{ "Customer.phoneRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="background: #f0f0f0" class="col-md-12 form-group border">
        <div class="d-flex p-1 justify-content-between align-items-center">
          <b class="title-customer">
            <i [size]="22" class="phone mr-1" data-feather="user"></i>
            {{ "Customer.contactChannelInfo" | translate }}
          </b>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12 mb-3">
          <label for="helpInputTop">{{
            "Customer.centralContactInfo" | translate
          }}</label>
          <small class="text-muted">
            <i> {{ "Customer.indirectContact" | translate }}</i>
          </small>
        </div>
        <div class="col-md-6">
          <div class="col-form-lable">
            {{ "Customer.centralPhoneNumber" | translate }}
            <span class="text-danger">*</span>
          </div>
          <input
            class="form-control"
            formControlName="gb_phone"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.gb_phone?.invalid) ||
                (f.gb_phone?.invalid &&
                  (f.gb_phone?.dirty || f.gb_phone?.touched)) ||
                (isRequiredCentralContact &&
                  f.gb_phone?.value == '' &&
                  (f.gb_phone?.dirty || f.gb_phone?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.gb_phone?.invalid) ||
                (f.gb_phone.invalid &&
                  (f.gb_phone.dirty || f.gb_phone.touched)) ||
                (isRequiredCentralContact &&
                  f.gb_phone?.value == '' &&
                  (f.gb_phone?.dirty || f.gb_phone?.touched))
            }"
          >
            <div>
              <div *ngIf="f.gb_phone.errors?.required">
                {{ "Customer.gbPhoneRequiredError" | translate }}
              </div>
              <div *ngIf="f.gb_phone.errors?.pattern">
                {{ "Customer.syntaxError" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-form-lable">
            {{ "Customer.faxNumber" | translate }}
          </div>
          <input
            class="form-control"
            value="-"
            formControlName="gb_fax"
            type="text"
          />
          <!-- <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.gb_fax?.invalid) ||
                (f.gb_fax.invalid && (f.gb_fax.dirty || f.gb_fax.touched))
            }"
          >
            <div>
              <div *ngIf="f.gb_fax.errors?.required">
                {{ "Customer.gbFaxRequiredError" | translate }}
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <div class="col-form-lable">
            {{ "Customer.centralEmail" | translate }}
            <span class="text-danger" *ngIf="isRequiredCentralContact">*</span>
          </div>
          <input
            class="form-control"
            formControlName="gb_email"
            type="text"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.gb_email?.invalid) ||
                (f.gb_email?.invalid &&
                  (f.gb_email?.dirty || f.gb_email?.touched)) ||
                (isRequiredCentralContact &&
                  f.gb_email?.value == '' &&
                  (f.gb_email?.dirty || f.gb_email?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.gb_email?.invalid) ||
                (f.gb_email.invalid &&
                  (f.gb_email.dirty || f.gb_email.touched)) ||
                (isRequiredCentralContact &&
                  f.gb_email?.value == '' &&
                  (f.gb_email?.dirty || f.gb_email?.touched))
            }"
          >
            <div>
              <div *ngIf="f.gb_email.errors?.required">
                {{ "Customer.gbEmailRequiredError" | translate }}
              </div>
              <div *ngIf="f.gb_email.errors?.pattern">
                {{ "Customer.syntaxError" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-form-lable">
            {{ "Customer.website" | translate }}
            <span class="text-danger" *ngIf="isRequiredCentralContact">*</span>
          </div>
          <input
            class="form-control"
            value="-"
            type="text"
            formControlName="gb_site"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.gb_site?.invalid) ||
                (f.gb_site?.invalid &&
                  (f.gb_site?.dirty || f.gb_site?.touched)) ||
                (isRequiredCentralContact &&
                  f.gb_site?.value == '' &&
                  (f.gb_site?.dirty || f.gb_site?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.gb_site?.invalid) ||
                (f.gb_site.invalid && (f.gb_site.dirty || f.gb_site.touched)) ||
                (isRequiredCentralContact &&
                  f.gb_site?.value == '' &&
                  (f.gb_site?.dirty || f.gb_site?.touched))
            }"
          >
            <div>
              <div *ngIf="f.gb_site.errors?.required">
                {{ "Customer.gbSiteRequiredError" | translate }}
              </div>
              <div *ngIf="f.gb_site.errors?.pattern">
                {{ "Customer.syntaxError" | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.address" | translate }}
            <span class="text-danger">*</span>
          </div>
          <textarea
            formControlName="addressNumber"
            class="form-control"
            placeholder="{{ 'Address.addressDetails' | translate }}"
            id="label-textarea"
            rows="8"
            maxlength="256"
            #addressNumberInput
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.addressNumber?.invalid) ||
                (f.addressNumber?.invalid &&
                  (f.addressNumber?.dirty || f.addressNumber?.touched)) ||
                (f.addressNumber?.value == '' &&
                  (f.addressNumber?.dirty || f.addressNumber?.touched))
            }"
          ></textarea>
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.addressNumber?.invalid) ||
                (f.addressNumber.invalid &&
                  (f.addressNumber.dirty || f.addressNumber.touched))
            }"
          >
            <div>
              <div *ngIf="f.addressNumber.errors?.required">
                {{ "Address.cAddressRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="col-form-label">
            {{ "Address.province" | translate }}
            <span class="text-danger">*</span>
          </div>
          <div class="skeleton-select" *ngIf="!provinceList">
            <div class="spinner-border spinner-border-sm mr-50" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <ng-select
            *ngIf="provinceList"
            class="custom-select"
            [clearable]="false"
            placeholder="   -- {{ 'Address.selectProvince' | translate }} --"
            #provinceIdInput
            formControlName="provinceId"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.provinceId?.invalid) ||
                (f.provinceId?.invalid &&
                  (f.provinceId?.dirty || f.provinceId?.touched)) ||
                (f.provinceId?.value == '' &&
                  (f.provinceId?.dirty || f.provinceId?.touched))
            }"
            (change)="isSelectProvice($event)"
            [hideSelected]="false"
          >
            <ng-option
              [value]="province.id"
              *ngFor="let province of provinceList"
            >
              <span *ngIf="currentLang == 'th'">
                {{ province.provinceName }}
              </span>
              <span *ngIf="currentLang == 'en'">
                {{ province.provinceNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.provinceId?.invalid) ||
                (f.provinceId.invalid &&
                  (f.provinceId.dirty || f.provinceId.touched))
            }"
          >
            <div>
              <div *ngIf="f.provinceId.errors?.required">
                {{ "Address.cCityRequiredError" | translate }}
              </div>
            </div>
          </div>

          <div class="col-form-label">
            {{ "Address.district" | translate }}
            <span class="text-danger">*</span>
          </div>
          <div class="skeleton-select" *ngIf="isLoadingDistrict">
            <div class="spinner-border spinner-border-sm mr-50" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <ng-select
            *ngIf="!isLoadingDistrict"
            class="custom-select"
            #districtIdInput
            formControlName="districtId"
            placeholder="   -- {{ 'Address.selectDistrict' | translate }} --"
            (change)="isSelectDistrict($event)"
            [hideSelected]="false"
            [clearable]="false"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.districtId?.invalid) ||
                (f.districtId?.invalid &&
                  (f.districtId?.dirty || f.districtId?.touched)) ||
                (f.districtId?.value == '' &&
                  (f.districtId?.dirty || f.districtId?.touched))
            }"
          >
            <ng-option
              [value]="group.id"
              *ngFor="let group of districtList"
              [selected]="group.id === f.districtId?.value"
            >
              <span *ngIf="currentLang != 'en'">
                {{ group.districtName }}
              </span>
              <span *ngIf="currentLang != 'th'">
                {{ group.districtNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.districtId?.invalid) ||
                (f.districtId.invalid &&
                  (f.districtId.dirty || f.districtId.touched))
            }"
          >
            <div>
              <div *ngIf="f.districtId.errors?.required">
                {{ "Address.cDistrictRequiredError" | translate }}
              </div>
            </div>
          </div>

          <div class="col-form-label">
            {{ "Address.subdistrict" | translate }}
            <span class="text-danger">*</span>
          </div>
          <div class="skeleton-select" *ngIf="isLoadingSubDistrict">
            <div class="spinner-border spinner-border-sm mr-50" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <ng-select
            *ngIf="!isLoadingSubDistrict"
            class="custom-select"
            #subDistrictInput
            formControlName="subDistrictId"
            placeholder="   -- {{ 'Address.selectSubDistrict' | translate }} --"
            (change)="getPostCode($event)"
            [hideSelected]="false"
            [clearable]="false"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.subDistrictId?.invalid) ||
                (f.subDistrictId?.invalid &&
                  (f.subDistrictId?.dirty || f.subDistrictId?.touched)) ||
                (f.subDistrictId?.value == '' &&
                  (f.subDistrictId?.dirty || f.subDistrictId?.touched))
            }"
          >
            <ng-option
              [value]="group.id"
              *ngFor="let group of subDistrictList"
              [selected]="group.id === f.subDistrictId?.value"
            >
              <span *ngIf="currentLang != 'en'">
                {{ group.subDistrictName }}
              </span>
              <span *ngIf="currentLang != 'th'">
                {{ group.subDistrictNameEn }}
              </span>
            </ng-option>
          </ng-select>

          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.subDistrictId?.invalid) ||
                (f.subDistrictId.invalid &&
                  (f.subDistrictId.dirty || f.subDistrictId.touched))
            }"
          >
            <div>
              <div *ngIf="f.subDistrictId.errors?.required">
                {{ "Address.cSubDistrictRequiredError" | translate }}
              </div>
            </div>
          </div>

          <div class="col-form-label">
            {{ "Address.postalCode" | translate }}
            <span class="text-danger">*</span>
          </div>
          <input
            #postCodeInput
            class="form-control"
            formControlName="postCode"
            type="text"
            disabled
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.postCode?.invalid) ||
                (f.postCode?.invalid &&
                  (f.postCode?.dirty || f.postCode?.touched)) ||
                (f.postCode?.value == '' &&
                  (f.postCode?.dirty || f.postCode?.touched))
            }"
          />
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.postCode?.invalid) ||
                (f.postCode.invalid && (f.postCode.dirty || f.postCode.touched))
            }"
          >
            <div>
              <div *ngIf="f.postCode.errors?.required">
                {{ "Address.cPostcodeRequiredError" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-customer-addr #CustomerAddress></app-customer-addr>

      <!-- <app-customer-bank></app-customer-bank> -->

      <br />
      <hr />
      <br />

      <div class="auto-form-footer">
        <a class="btn btn-secondary" (click)="openCancelModal(itemObj)">
          {{ "Form.Cancel" | translate }}
        </a>

        <div class="d-flex justify-content-between align-items-center">
          <a class="btn btn-primary" (click)="openConfirmModal(itemObj)">
            {{ "Form.Submit" | translate }}
          </a>
        </div>
      </div>
    </form>
  </div>
</div>
