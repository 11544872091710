import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { ItemSetService } from '../item-set.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-item-set-formpage',
  templateUrl: './item-set-formpage.component.html',
  styleUrls: ['./item-set-formpage.component.scss'],
})
export class ItemSetFormpageComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();

  contentHeader: object;
  selectedLang: string = environment.langDefault;
  langList = environment.langItem;

  pathUrl: string;
  isLoading: boolean = false;

  constructor(private _itemSetService: ItemSetService) {
    this.pathUrl = this._itemSetService.pathUrl;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'BreadCrumbs.Manage.ItemSet',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  selectedLanguage(lang: string): void {
    this.selectedLang = lang;
  }
}
