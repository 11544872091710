import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import Customer from 'app/main/model/Customer';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { DocumentStatus } from 'app/main/model/DocumentStatus';
import { Quotation } from 'app/main/model/Quotation';
import { ApiService } from 'app/main/service/api.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-shipping-paper',
  templateUrl: './shipping-paper.component.html',
  styleUrls: ['./shipping-paper.component.scss']
})
export class ShippingPaperComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject();

  @Input() data: any;
  @Input() docCodeColumn: string;

  showPaperTool: boolean;
  quotationObj: Quotation;
  issuerData: any;
  customer: Customer;
  customerAddressObj: CustomerAddress;
  docID: string;
  apiPath: string;

  documentStatus = DocumentStatus;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _title: Title,
    private _documentApiService: DocumentApiService,
    private _apiService: ApiService,
    ) { 
      this.getIssuerData();
    }

  ngOnDestroy(): void {
    this._title.setTitle(environment.appTitle);
  }

  ngOnInit(): void {
    this.initData();
    console.log(this.data);
    
  }

  goBackPage() {
    this._router.navigate(['../'], {});
  }

  printWindow(): void {
    window.print();
  }

  getIssuerData() {
    this._apiService.GetAllData('Setting/General').subscribe((res) => {
      this.issuerData = res.data.resultData[0];
    });
  }

  initData(): void {
    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
    });
    // this.getQuotationData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      console.log('data is here');

      this.quotationObj = this.data.quotationObj;
      this._title.setTitle(
        this.quotationObj ? this.quotationObj[this.docCodeColumn] : '-'
      );
    }

    this.customer = this.data.customerObj;
    this.customerAddressObj = this.data.customerAddressObj;
    // if (
    //   this.quotationObj.transactionStatusId == this.documentStatus.Voided ||
    //   this.quotationObj.transactionStatusId == this.documentStatus.Cancel
    // )
  }

  getQuotationData(): Promise<any> {
    var self = this;

    console.log(this.data);
    // self.SetLoadedState();
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.apiPath, this.docID)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          self.quotationObj = res.data.resultData[0];
          // if (
          //   self.quotationObj.transactionStatusId ==
          //     this.documentStatus.Voided ||
          //   self.quotationObj.transactionStatusId == this.documentStatus.Cancel
          // ) {
          //   self.showWaterMsk = true;
          // }
          self.getCustomerAddress(self.quotationObj.customerId);
        });
    });
  }

  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData.filter((it) => {
            return it['useForShipping'] == true;
          });
        });
    });
  }
}