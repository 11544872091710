<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section>
      <app-horizontal-timeline
        [timelineItems]="timelineData"
        [currentStep]="
          docStatus == 4
            ? 2
            : docStatus == 5
            ? 4
            : docStatus == 8
            ? 5
            : undefined
        "
        [currentStepNumber]="
          docStatus == 4
            ? 2
            : docStatus == 5
            ? 4
            : docStatus == 8
            ? 5
            : undefined
        "
        [lastItems]="{
          label: 'Void',
          descript: 'ยกเลิก',
          stepCount: 5
        }"
      ></app-horizontal-timeline>
    </section>

    <!-- <section>
      <div class="card">
        <div class="card-body">
          <div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
            <app-loading></app-loading>
          </div>

          <div *ngIf="!isLoading">
            <app-content-document
              [customerAddressObj]="customerAddressObj"
              [titleDoc]="(invoiceObj.isTaxInvoice ? 'Document.Invoice_Tax' : 'Document.Invoice') | translate"
              [apiPath]="apiPath"
              [typeDocName]="typeDocName"
              [detailObj]="invoiceObj"
              [docId]="docID"
              [editPathUrl]="editPathUrl"
              [refDocID]="'saleOrderId'"
              [refDetailUrl]="refDetailUrl"
              [showValidDate]="false"
            >
            </app-content-document>

            <div class="">
              <div class="d-flex">
                <i data-feather="file-text" class="mr-1"></i>
                <span>แนบไฟล์ในเอกสารนี้</span>
              </div>

              <app-attached-file-uploader></app-attached-file-uploader>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section>
      <div class="card">
        <!-- Nav tabs -->
        <div
          class="card-header"
          style="border-bottom: 1px dashed rgba(161, 161, 161, 0.3)"
        >
          <span></span>
          <!-- <h2>{{ "Document.DebitNote" | translate }}</h2> -->
          <ul
            ngbNav
            #navFilled="ngbNav"
            [activeId]="activeTab"
            class="nav nav-tabs mb-0 bg-white border"
            [destroyOnHide]="false"
          >
            <li [ngbNavItem]="'detail'" class="nav-item">
              <a ngbNavLink routerLink="." [fragment]="'detail'">{{
                "General.DocumentInfo" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-content-document
                  [customerAddressObj]="customerAddressObj"
                  [titleDoc]="'Document.Invoice' | translate"
                  [apiPath]="apiPath"
                  [typeDocName]="typeDocName"
                  [detailObj]="invoiceObj"
                  [invoiceDetailObj]="invoiceObj"
                  [docId]="docID"
                  [docStatus]="docStatus"
                  [editPathUrl]="editPathUrl"
                  [detailPathUrl]="detailPathUrl"
                  [refDocID]="'saleOrderId'"
                  [refDetailUrl]="refDetailUrl"
                  [isDisabledEditBtn]="true"
                  [isCreateTaxInvoice]="isCreateTX"
                  (refreshData)="refreshData()"
                >
                </app-content-document>
              </ng-template>
            </li>
            <li [ngbNavItem]="'paymentImg'" class="nav-item">
              <a ngbNavLink routerLink="." [fragment]="'paymentImg'">
              {{ "General.SlipInfo" | translate }}
            </a>
              <ng-template ngbNavContent>
                <app-payment-evidence
                [titleDoc]="'General.SlipInfo' | translate"
                [detailObj]="invoiceObj"
                >
                </app-payment-evidence>
              </ng-template>
            </li>
            <li [ngbNavItem]="'paymentInfo'">
              <a ngbNavLink routerLink="." [fragment]="'paymentInfo'">{{
                "General.PaymentInfo" | translate
              }}</a>
              <ng-template ngbNavContent>
                <div class="card m-2">
                  <app-payment-detail
                    [titleDoc]="'Document.Invoice' | translate"
                    [docId]="docID"
                    [apiPath]="apiPath"
                    [docStatus]="docStatus"
                    [detailObj]="invoiceObj"
                    [typeDocName]="typeDocName"
                    [refDocID]="'saleOrderId'"
                    [refDetailUrl]="refDetailUrl"
                    (submitData)="handlePaymentDetailSubmit($event)"
                    (refreshData)="handlePaymentDetailRefresh($event)"
                  >
                  </app-payment-detail>
                </div>
              </ng-template>
            </li>

            <li [ngbNavItem]="'historyInfo'">
              <a ngbNavLink routerLink="." [fragment]="'historyInfo'">{{
                "General.HistoryInfo" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-action-history
                  [documentId]="docID"
                  [title]="'Document.Invoice'"
                  [apiPath]="apiPath"
                  [detailObj]="invoiceObj"
                  [typeDocName]="typeDocName"
                  [docStatus]="docStatus"
                >
                </app-action-history>
              </ng-template>
            </li>

            <li [ngbNavItem]="'relatedDoc'">
              <a ngbNavLink routerLink="." [fragment]="'relatedDoc'">{{
                "General.RelatedDocument" | translate
              }}</a>
              <ng-template ngbNavContent>
                <app-content-child-document
                  [orderStateData]="orderStatus[0]"
                  (refreshData)="refreshData($event)"
                  [currentDocCode]="invoiceObj[typeDocName]"
                >
                </app-content-child-document>
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div [ngbNavOutlet]="navFilled"></div>
        </div>
      </div>
    </section>
  </div>
</div>
