import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerComponent } from './customer.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CustomerFormPageComponent } from './customer/customer-form-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { DataTablesModule } from 'angular-datatables';
import { CoreCommonModule } from '@core/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { NgApexchartsModule } from 'ng-apexcharts';

const routes: Routes = !environment.menuList.customer ? [] : [
  {
    path: 'customer',
    component: CustomerComponent,
    canActivate: [AuthGuard],
    data: { animation: 'customer' },
  },
  {
    path: 'customer/create',
    component: CustomerFormPageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'customer-create' },
  },
  {
    path: 'customer/edit/:id',
    component: CustomerFormPageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'customer-edit' },
  },
  {
    path: 'customer/detail/:id',
    component: CustomerDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'customer-detail' },
  },
];
@NgModule({
  declarations: [CustomerComponent, CustomerFormPageComponent, CustomerDetailComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    ComponentsModule,
    CorePipesModule,
    TranslateModule,
    DataTablesModule,
    CoreCommonModule,
    NgbModule,
    Ng2FlatpickrModule,
    NgApexchartsModule
  ],
  exports: [CustomerComponent, CustomerFormPageComponent],
})
export class CustomerModule {}
