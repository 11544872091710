<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <app-search-panel
      [forCompanentName]="componentName"
      [pathUrl]="pathUrl + '/create'"
      [isForGroupTemplate]="false"
      [isShowSortByGroup]="false"
      [notShowSwitchView]="true"
      [notForContentMode]="true"
      (SetLoading)="SetLoadingState()"
      (callDataFromAPI)="getRoleDate($event)"
    ></app-search-panel>

    <div class="card">
      <div class="card-body">
        <table datatable [dtOptions]="dtOptions" class="table">
          <thead>
            <tr>
              <th width="5%" class="text-center">#</th>
              <th width="40%" class="text-center">
                {{ "ViewTable.Role" | translate }}
              </th>
              <th width="15%" class="text-center">
                {{ "ViewTable.Status" | translate }}
              </th>
              <th width="15%" class="text-center">
                {{ "ViewTable.CreateDate" | translate }}
              </th>
              <th width="5%" class="text-center">
                {{ "ViewTable.Actions" | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="w-100" *ngIf="isLoading">
              <td class="text-center" *ngFor="let num of [].constructor(5)">
                <div class="skeleton-loading"></div>
              </td>
            </tr>

            <tr *ngIf="roleSetList.length < 1 && !isLoading">
              <td colspan="100%" class="text-center">
                <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
              </td>
            </tr>

            <tr *ngFor="let role of roleSetList; index as ind">
              <td class="text-center">
                <span>{{ (page - 1) * pageSize + ind + 1 }}</span>
              </td>
              <td class="text-start text-primary">
                <a
                  *ngIf="role.roleName != 'SuperAdmin'"
                  [routerLink]="[pathUrl + '/edit/' + role.id]"
                  >{{ role.roleName }}</a
                >
                <p *ngIf="role.roleName == 'SuperAdmin'" class="m-0">
                  {{ role.roleName }}
                </p>
              </td>
              <td class="text-center">
                <div
                  class="form-group my-auto"
                  *ngIf="role.roleName != 'SuperAdmin'"
                >
                  <div ngbDropdown>
                    <a
                      ngbDropdownToggle
                      href="javascript:void(0);"
                      class="d-flex justify-content-center text-secondary"
                      id="dropdownBrowserState"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      [ngClass]="{
                        'text-success': role.isActive,
                        'text-danger': !role.isActive
                      }"
                    >
                      <div>
                        {{
                          (role.isActive ? "Status.Active" : "Status.Inactive")
                            | translate
                        }}
                      </div>
                      <i
                        data-feather="chevron-down"
                        class="text-dark cursor-pointer ml-50 my-auto"
                      ></i>
                    </a>
                    <div
                      ngbDropdownMenu
                      class="dropdown-menu-left"
                      aria-labelledby="dropdownBrowserState"
                    >
                      <a
                        ngbDropdownItem
                        *ngIf="role.isActive"
                        class="d-flex align-items-center"
                        (click)="openIsActiveModal(role, false)"
                      >
                        <i data-feather="eye-off" class="mr-50"></i>
                        {{ "Status.Inactive" | translate }}
                      </a>
                      <a
                        ngbDropdownItem
                        *ngIf="!role.isActive"
                        class="d-flex align-items-center"
                        (click)="openIsActiveModal(role, true)"
                      >
                        <i data-feather="eye" class="mr-50"></i>
                        {{ "Status.Active" | translate }}
                      </a>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <span>{{ "ViewTable.By" | translate }} </span
                >{{ role.createBy }}
                <br />
                <span>{{ "ViewTable.Date" | translate }}</span>
                <span *ngIf="currentLang != 'th'">
                  {{ role.createDate | date : "dd MMM YYYY" }}
                </span>
                <span *ngIf="currentLang != 'en'">
                  {{ role.createDate | thaidate : "DD MMM YYYY" }}
                </span>
              </td>
              <td class="text-center">
                <!-- <a
                      disabled
                      *ngIf="role.roleName == 'SuperAdmin'"
                      class="btn btn-primary btn-outline disabled btn-sm"
                      >SuperAdmin</a
                    > -->
                <a
                  class="btn btn-icon btn-icon rounded-circle btn-outline-warning mr-50"
                  *ngIf="role.roleName != 'SuperAdmin'"
                  [routerLink]="[pathUrl + '/edit/' + role.id]"
                  ><i data-feather="edit"></i
                ></a>
                <a
                  class="btn btn-icon btn-icon rounded-circle btn-outline-danger"
                  *ngIf="role.roleName != 'SuperAdmin'"
                  (click)="openDeleleUserModal(role)"
                  ><i data-feather="trash-2" class="text-danger"></i
                ></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
