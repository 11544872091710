import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ContentListComponent } from '../../../components/content-list/content-list.component';
import { ApiService } from '../../../service/api.service';
import { Subject, Subscription } from 'rxjs';
import { SubclassService } from './subclass.service';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from '../../../components/components.service';
import SubClass from '../../../model/SubClass';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-subclass',
  templateUrl: './subclass.component.html',
  styleUrls: ['./subclass.component.scss'],
})
export class SubclassComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription;

  componentName: string;

  apiPath: string;
  pathUrl: string;

  contentHeader: object;
  itemList: SubClass[];

  isLoading: boolean;
  isGridView: boolean = false;
  isShowChildList: boolean = false;
  isForGroupTemplate: boolean = false;
  isGoEditPage: boolean = false;

  activeStatus: string;
  searchText: string;
  searchData: { [k: string]: any } = {};

  public page = 1;
  public pageSize = 10;

  @ViewChild(ContentListComponent) contentListTable: ContentListComponent;

  constructor(
    private _apiService: ApiService,
    private _subClassService: SubclassService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService
  ) {
    this.SetLoadingState();
    this.initConfig();
    this.initData();
  }

  initConfig(): void {
    this.componentName = this._subClassService.componentName;
    this.apiPath = this._subClassService.apiUrl;
    this.pathUrl = this._subClassService.pathUrl;
  }

  initData(obj?: { [key: string]: any }): void {
    this.SetLoadingState();
    this.itemList = [];

    if (obj) {
      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = { SortPath: 'Name', Direction: 0, isDelete: false };
      } else {
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = { SortPath: 'Name', Direction: 0, isDelete: false };
    }

    this.searchData.name = this.searchText;
    this.searchData.includeMediaURL = false;

    if (this.contentListTable) {
      this.contentListTable.loadData(this.searchData);
    }
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  listView() {
    this.isGridView = false;
  }

  gridView() {
    this.isGridView = true;
  }

  SearchFilter(val: string) {
    this.searchText = val;
    this.SetLoadedState();
  }

  setPageSize(val: number): void {
    this.pageSize = val;
  }

  exportXLSX() {
    this.contentListTable.ExportXLSX();
  }

  setActiveContent(val): void {
    var self = this;
    this._apiService
      .SetIsActiveData(this.apiPath, val.id, val.status)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          self._componentsService.SuccessSwal();
          self.SetLoadingState();
          self.initData();
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
  }

  deleteContent(guid: string): void {
    var self = this;
    this._apiService
      .SoftDelete(this.apiPath, guid)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          self._componentsService.SuccessSwal();
          self.SetLoadingState();
          self.initData();
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
  }

  // drawDatatables(): void {
  //   this.dtOptions = {
  //     pagingType: 'full_numbers',
  //     scrollX: true,
  //     info: true,
  //     autoWidth: false,
  //     searching: false,
  //     order: [[0, 'asc']],
  //   };

  //   if (this.itemList) {
  //     this.dtTrigger.next();
  //   }
  // }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'BreadCrumbs.Manage.SubClass',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    // this.drawDatatables();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
}
