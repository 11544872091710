import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from 'app/main/pages/document/document.service';
import { ApiService } from 'app/main/service/api.service';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';
import { FilesApiService } from 'app/main/service/files-api.service';

@Component({
  selector: 'app-transfer-form',
  templateUrl: './transfer-form.component.html',
  styleUrls: ['./transfer-form.component.scss'],
})
export class TransferFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() callBackFormData: EventEmitter<any> = new EventEmitter();
  @Output() CallBackBankID: EventEmitter<any> = new EventEmitter();
  @Input() isSubmit: boolean = false;
  @Input() editData: any;
  @Input() isEditing: boolean;

  bankNameList: any[];
  bankAccountForm: FormGroup;

  isQRCode: boolean;
  selectBankId: number;
  accountNumber: string;
  bankBranch: string;
  accountName: string;
  bankAccountType: number = 1;

  isQrcodeInvalid: boolean = false;
  isBankIdInvalid: boolean = false;
  isAccountNumberInvalid: boolean = false;
  isBankBranchInvalid: boolean = false;
  isAccountNameInvalid: boolean = false;
  isBankAccountTypeInvalid: boolean = false;

  ImageUploadComponent = {
    selected: false,
  }

  @ViewChild('imageUploadComponent') imageUploadComponent: ImageUploadComponent;

  constructor(
    private _translateService: TranslateService,
    private _apiService: ApiService,
    private _documentService: DocumentService,
    private _documentApiService: DocumentApiService,
    private _formBuilder: FormBuilder,
    private _globalFuncService: GlobalFuncService,
    private _fileService: FilesApiService
  ) {
    this.bankNameList = [
      { id: 1, name: 'ธนาคารแห่งประเทศไทย' },
      { id: 2, name: 'ธนาคารกรุงเทพ' },
      { id: 3, name: 'ธนาคารกสิกรไทย' },
      { id: 4, name: 'ธนาคารกรุงไทย' },
      { id: 5, name: 'ธนาคารทหารไทยธนชาต' },
      { id: 6, name: 'ธนาคารไทยพาณิชย์' },
      { id: 7, name: 'ธนาคารซิตี้แบงก์' },
      { id: 8, name: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)' },
      { id: 9, name: 'ธนาคารซีไอเอ็มบีไทย' },
      { id: 10, name: 'ธนาคารยูโอบี' },
      { id: 11, name: 'ธนาคารกรุงศรีอยุธยา' },
      { id: 12, name: 'ธนาคารเมกะ สากลพาณิชย์' },
      { id: 13, name: 'ธนาคารออมสิน' },
      { id: 14, name: 'ธนาคารอาคารสงเคราะห์' },
      { id: 15, name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร' },
      { id: 16, name: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย' },
      { id: 17, name: 'ธนาคารแห่งประเทศจีน (ไทย)' },
      { id: 18, name: 'ธนาคารอิสลามแห่งประเทศไทย' },
      { id: 19, name: 'ธนาคารทิสโก้' },
      { id: 20, name: 'ธนาคารเกียรตินาคินภัทร' },
      { id: 21, name: 'ธนาคารไอซีบีซี (ไทย)' },
      { id: 22, name: 'ธนาคารไทยเครดิตเพื่อรายย่อย' },
      { id: 23, name: 'ธนาคารแลนด์ แอนด์ เฮ้าส์' },
      { id: 24, name: 'ธนาคารเอเอ็นแซด (ไทย)' },
      { id: 25, name: 'ธนาคารซูมิโตโม มิตซุย ทรัสต์ (ไทย)' },
      { id: 26, name: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย' },
    ];

    this.bankAccountForm = this._formBuilder.group({
      isQRCode: [null, Validators.required],
      bankId: [null, Validators.required],
      accountNumber: [null, Validators.required],
      bankBranch: [null, []],
      accountName: [null, Validators.required],
      bankAccountType: [1, Validators.required],
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    console.log('Form Editttttt', this.isEditing);
    console.log(this.editData);

    if (this.editData && this.isEditing) {

      this.isQRCode = this.editData.isQRCode;
      this.selectBankId = this.editData.bankId;
      this.accountNumber = this.editData.accountNumber;
      this.bankBranch = this.editData.bankBranch;
      this.accountName = this.editData.accountName;
      this.bankAccountType = this.editData.bankAccountType;

      this.bankAccountForm.setValue({
        isQRCode: this.isQRCode,
        bankId: this.selectBankId,
        accountNumber: this.accountNumber,
        bankBranch: this.bankBranch,
        accountName: this.accountName,
        bankAccountType: this.bankAccountType,
      });
      let Obj:any = {};
      Obj = this.editData
      this.CallBackBankID.emit(Obj)
      this.setFormValue();
    }
  }

  ngOnDestroy(): void {
    this.isQRCode = null;
    this.selectBankId = null;
    this.accountNumber = null;
    this.bankBranch = '';
    this.accountName = '';
    this.bankAccountType = null;
    this.bankNameList = [];
  }

  get f() {
    return this.bankAccountForm.controls;
  }

  convertMoneytoNumber(value: string): number {
    return this._globalFuncService.MoneyFormatToNumber(value);
  }

  onSelectBankId() {
    this.selectBankId = this.bankAccountForm.get('bankId').value;
    this.setFormValue();
  }

  onSelectAccountNumber() {
    this.accountNumber = this.bankAccountForm
      .get('accountNumber')
      .value.toString();
    this.setFormValue();
  }

  onSelectBankBranch() {
    this.bankBranch = this.bankAccountForm.get('bankBranch').value;
    this.setFormValue();
  }

  onSelectAccountName() {
    this.accountName = this.bankAccountForm.get('accountName').value;
    this.setFormValue();
  }

  onSelectAccountType() {
    this.bankAccountType = this.bankAccountForm.get('bankAccountType').value;
    this.setFormValue();
  }

    setFormValue() {
     this.bankAccountForm.patchValue({
      bankId: this.selectBankId,
      accountNumber: this.accountNumber,
      bankBranch: this.bankBranch,
      accountName: this.accountName,
      bankAccountType: this.bankAccountType,
    });
    this.callBackFormData.emit(this.bankAccountForm.value);

  }

  

  checkInvalidForm(): boolean {
    this.isQrcodeInvalid = this.bankAccountForm.get('isQRCode').invalid;
    this.isBankIdInvalid = this.bankAccountForm.get('bankId').invalid;
    this.isAccountNumberInvalid =
      this.bankAccountForm.get('accountNumber').invalid;
    this.isBankBranchInvalid = this.bankAccountForm.get('bankBranch').invalid;
    this.isAccountNameInvalid = this.bankAccountForm.get('accountName').invalid;
    this.isBankAccountTypeInvalid =
      this.bankAccountForm.get('bankAccountType').invalid;

    if (
      this.isBankIdInvalid ||
      this.isAccountNumberInvalid ||
      this.isBankBranchInvalid ||
      this.isAccountNameInvalid ||
      this.isBankAccountTypeInvalid
    ) {
      return true;
    } else {
      return false;
    }
  }
}
