<div class="row">
  <div
    *ngFor="let itemObj of itemObjList"
    class="col-sm-6 col-md-4 col-lg-3 col-xl-3"
  >
    <div class="card content-card py-1 px-2">
      <div class="d-flex justify-content-between">
        <a
          [routerLink]="[mainPathUrl + '/' + itemObj.id]"
          class="text-truncate-1"
        >
          <h4 class="text-primary">
            {{ itemObj.name }}
          </h4>
        </a>
        <div ngbDropdown>
          <a
            ngbDropdownToggle
            href="javascript:void(0);"
            class="hide-arrow"
            id="dropdownBrowserState"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i
              data-feather="more-vertical"
              class="text-primary cursor-pointer ml-50"
            ></i>
          </a>
          <div
            ngbDropdownMenu
            class="dropdown-menu-left"
            aria-labelledby="dropdownBrowserState"
          >
            <a
              ngbDropdownItem
              *ngIf="itemObj.isActive"
              class="d-flex align-items-center"
              (click)="openIsActiveModal(itemObj, false)"
            >
              <i data-feather="eye-off" class="mr-50"></i>
              {{ "Status.Unpublish" | translate }}
            </a>
            <a
              ngbDropdownItem
              *ngIf="!itemObj.isActive"
              class="d-flex align-items-center"
              (click)="openIsActiveModal(itemObj, true)"
            >
              <i data-feather="eye" class="mr-50"></i>
              {{ "Status.Publish" | translate }}
            </a>
            <a
              ngbDropdownItem
              class="d-flex align-items-center"
              [routerLink]="[editPathUrl + '/' + itemObj.id]"
            >
              <i data-feather="edit" class="mr-50"></i>
              {{ "General.Edit" | translate }}
            </a>
            <a
              ngbDropdownItem
              class="d-flex align-items-center"
              (click)="openDeleteContentModal(itemObj)"
            >
              <i data-feather="trash-2" class="mr-50"></i>
              {{ "General.Delete" | translate }}
            </a>
          </div>
        </div>
      </div>
      <ul class="mt-2">
        <li>
          {{ "General.Status" | translate }}:
          <span
            [ngClass]="{
              'text-success': itemObj.isActive,
              'text-danger': !itemObj.isActive
            }"
          >
            {{
              (itemObj.isActive ? "Status.Published" : "Status.Unpublished")
                | translate
            }}
          </span>
        </li>
        <!-- <li><span>Sort:</span></li> -->
        <li>
          Last edited:
          <span *ngIf="currentLang == 'th'">{{
            itemObj.updateDate | thaidate : "DD MMM YYYY"
          }}</span>
          <span *ngIf="currentLang != 'th'"
            >{{ itemObj.updateDate | date : "dd MMM YYYY" }}
          </span>
        </li>
        <li>
          Create by:
          <span>{{ itemObj.createBy ? itemObj.createBy : "Admin" }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-12 mt-2 mb-3" *ngIf="itemObjList.length < 1">
    <p class="text-center">There is no item to preview</p>
  </div>
</div>
