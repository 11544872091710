// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'https://ykn-api.appintouch.net/api',
  appName: 'YUKINA CMS',
  appTitle: 'YUKINA CMS',
  appLogoImage: 'assets/images/logo/yukina-logo.svg',
  appLogoImageCMS: 'assets/images/logo/yulina-logo-cms.png',
  appLogoNav: 'assets/images/logo/yukina-logo.svg',
  defaultIMG: 'assets/images/logo/yukina-logo.svg',

  // language list for content
  langDefault: 'en',
  langContent: ['en', 'th'],
  langItem: ['en'],

  //transaction
  transaction: {
    issuerData: {
      name: 'Cocueco (Thailand) Co., Ltd.',
      branch: 'สำนักงานใหญ่',
      email: 'sales@cocueco.com',
      taxID: '0105561167749',
      website: 'https://www.repove.world/',
      telephone: '081 234 5679',
      address: '75/4 ซอยสุขุมวิท 64/1, แขวงพระโขนงใต้, เขตพระโขนง, กรุงเทพมหานคร 10260'
    },
    logoOnPaperPath: '../assets/images/logo/logo.png',
    isUseWht: false,
    isSplitIVfromSO: true,
    isSplitGRfromPO: true,
    isJoinIV_TX_RE: false,
    isQuantityDecimal: true,

    shippingFee: 0
  },

  stock: {
    isQuantityDecimal: true
  },

  //customer
  customer: {
    requiredDetail: false,
    requiredCentralContact: false,
  },

  limitFileSize: {
    maxImageSizeInBytes: 500 * 1024, //300KB
    maxVideoSizeInBytes: 5 * 1024 * 1024, //2MB
    maxFileSizeInBytes: 2 * 1024 * 1024, //2MB
  },

  menuList: {
    itemManage: true,
    cms: true,
    customer: true,
    codeManage: true,
    reviewManage: true,
    transactionDoc: true,
    stock: true,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
