<div>
  <div class="card">
    <div class="card-body">
      <table
        [dtOptions]="dtOptions"
        class="table"
        datatable
      >
        <thead>
          <tr class="text-center" style="width: auto !important">
            <th class="text-center" width="8%">
              {{ "ViewTable.No" | translate }}
            </th>
            <th class="text-center" width="15%">
              codeName
            </th>
            <th class="text-center" width="15%">
              redeemCode
            </th>
            <th class="text-center" width="12%">
              start date
            </th>
            <th class="text-center" width="12%">
              end date
            </th>
            <th class="text-center" width="12%">
              amount
            </th>
            <th class="text-center" width="15%">
              Create by
            </th>
            <th class="text-center" width="10%">
              {{ "ViewTable.Actions" | translate }}
            </th>
          </tr>
        </thead>

        <tbody style="width: 100% !important">

          <tr class="w-100" *ngIf="isLoading">
            <td class="text-center" *ngFor="let num of [].constructor(8)">
              <div class="skeleton-loading"></div>
            </td>
          </tr>

          <tr *ngIf="itemList.length < 1 && !isLoading">
            <td colspan="100%" class="text-center">
               <p>{{ 'ViewTable.NoItemPreview' | translate }}</p>
            </td>
          </tr>

          <tr
            *ngFor="let itemObj of itemList; let ind = index"
            [ngClass]="{
              isvoice: itemObj.isVoice
            }"
            [ngClass]="{
              iscancel: itemObj.transactionStatusId == 9
            }"
          >
            <td class="text-center">{{ (page - 1) * pageSize + ind + 1 }}</td>
            <td class="text-truncate" style="max-width: 200px">
              <a
                [routerLink]="
                  itemObj.transactionStatusId == 1
                    ? [editPathUrl + '/' + itemObj.id]
                    : [detailPathUrl + '/' + itemObj.id]
                "
                class="text-truncate text-primary"
              >
                {{ itemObj.quotationCode }}
              </a>
            </td>
            <td class="text-truncate" style="max-width: 200px">
              <a
                [routerLink]="
                  itemObj.transactionStatusId == 1
                    ? [editPathUrl + '/' + itemObj.id]
                    : [detailPathUrl + '/' + itemObj.id]
                "
                class="text-truncate text-secondary"
              >
                {{ itemObj.customerName }}
              </a>
            </td>
            <td class="text-center my-auto">
              <app-document-status-badge
                [status]="itemObj.transactionStatusId"
                [isDelete]="itemObj.isDelete"
              ></app-document-status-badge>
            </td>
            <td class="text-center" style="width: 100px">
              <span *ngIf="currentLang != 'th'">
                {{ itemObj.documentCreateDate | date : "dd MMM YYYY" }}
              </span>
              <span *ngIf="currentLang != 'en'">
                {{ itemObj.documentCreateDate | thaidate : "DD MMM YYYY" }}
              </span>
              <br />
            </td>
            <td
              class="text-center my-auto"
              [ngClass]="{
                'date-exit-td':
                  itemObj.documentExpireDate < this.currentDate
              }"
            >
              <span *ngIf="currentLang != 'th'">
                {{ itemObj.documentExpireDate | date : "dd MMM YYYY" }}
              </span>
              <span *ngIf="currentLang != 'en'">
                {{ itemObj.documentExpireDate | thaidate : "DD MMM YYYY" }}
              </span>
              <span
                [ngClass]="{
                  'd-block': itemObj.documentExpireDate < this.currentDate
                }"
                class="text-caption-exit"
                >{{ "General.Expired" | translate }}</span
              >
            </td>
            <td class="text-end">
              <span>
                {{ convertNumbertoMoneyFormat(itemObj.grandTotal) }}
              </span>
            </td>
            <td class="text-center d-flex justify-content-center">
              <a
                class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50"
                [routerLink]="[detailPathUrl + '/' + itemObj.id]"
                ><i data-feather="eye"></i
              ></a>
              <a
                class="btn btn-icon btn-icon rounded-circle btn-outline-warning mr-50"
                *ngIf="itemObj.transactionStatusId == 1"
                [routerLink]="[editPathUrl + '/' + itemObj.id]"
                ><i data-feather="edit"></i
              ></a>
              
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
