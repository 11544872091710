import { Component, OnInit } from '@angular/core';
import Branch, { branchList } from 'app/main/model/Branch';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss'],
})
export class BranchComponent implements OnInit {
  branchList: Branch[] = branchList;

  constructor() {}

  ngOnInit(): void {}
}
