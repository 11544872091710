<div class="card">
  <div class="card-body" style="overflow-x: auto">
    <div class="d-flex justify-content-end col-12 p-0">
      <button class="btn btn-primary mb-1" (click)="ExportXLSX()">
        {{ "General.Export" | translate }}
      </button>
    </div>
    <div>
      <table
        datatable
        [dtOptions]="dtOptions"
        #contentTable
        class="table table-hover w-100"
      >
        <thead>
          <tr class="text-center">
            <th class="text-center" width="8%">#</th>
            <th class="text-center" width="40%">
              {{ "ViewTable.Name" | translate }}
            </th>
            <th class="text-center" width="10%">
              {{ "ViewTable.Status" | translate }}
            </th>
            <th class="text-center" width="10%">
              {{ "ViewTable.UpdateDate" | translate }}
            </th>
            <th class="text-center" width="10%">
              {{ "ViewTable.CreateBy" | translate }}
            </th>
            <th class="text-center" width="10%">
              {{ "ViewTable.Actions" | translate }}
            </th>
          </tr>
        </thead>
        <tbody style="width: 100% !important">
          <tr class="w-100" *ngIf="isLoading">
            <td class="text-center" *ngFor="let num of [].constructor(6)">
              <div class="skeleton-loading"></div>
            </td>
          </tr>

          <tr *ngIf="itemObjList.length < 1 && !isLoading">
            <td colspan="100%" class="text-center">
              <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
            </td>
          </tr>

          <tr *ngFor="let itemObj of itemObjList; let ind = index">
            <td class="text-center">
              {{ (page - 1) * pageSize + ind + 1 }}
            </td>
            <td class="text-truncate" style="max-width: 200px">
              <a
                [routerLink]="[editPathUrl + '/' + itemObj.id]"
                class="d-flex text-truncate primary-color"
              >
                <img
                  *ngIf="isShowCoverImage"
                  [src]="
                    itemObj.mediaFiles.coverImage[0].id
                      ? getImages(itemObj.mediaFiles.coverImage[0].id, {
                          Width: 60,
                          Height: 60
                        })
                      : defaultIMG
                  "
                  [alt]="defaultIMG"
                  width="60px"
                  height="60px"
                  style="border-radius: 5px; object-fit: cover"
                  
                />
                <p class="my-auto" [ngClass]="{ 'ml-2': isShowCoverImage }">
                  {{ keyName ? itemObj[keyName] : itemObj["name"] }}
                </p>
              </a>
            </td>
            <td class="text-center">
              <div class="form-group my-auto">
                <div ngbDropdown>
                  <a
                    ngbDropdownToggle
                    href="javascript:void(0);"
                    class="d-flex justify-content-center text-secondary"
                    id="dropdownBrowserState"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    [ngClass]="{
                      'text-success': itemObj.isActive,
                      'text-danger': !itemObj.isActive
                    }"
                  >
                    <div>
                      {{
                        (itemObj.isActive
                          ? "Status.Published"
                          : "Status.Unpublished"
                        ) | translate
                      }}
                    </div>
                    <i
                      data-feather="chevron-down"
                      class="text-dark cursor-pointer ml-50 my-auto"
                    ></i>
                  </a>
                  <div
                    ngbDropdownMenu
                    class="dropdown-menu-left"
                    aria-labelledby="dropdownBrowserState"
                  >
                    <a
                      ngbDropdownItem
                      *ngIf="itemObj.isActive"
                      class="d-flex align-items-center"
                      (click)="openIsActiveModal(itemObj, false)"
                    >
                      <i data-feather="eye-off" class="mr-50"></i>
                      {{ "Status.Unpublish" | translate }}
                    </a>
                    <a
                      ngbDropdownItem
                      *ngIf="!itemObj.isActive"
                      class="d-flex align-items-center"
                      (click)="openIsActiveModal(itemObj, true)"
                    >
                      <i data-feather="eye" class="mr-50"></i>
                      {{ "Status.Publish" | translate }}
                    </a>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">
              <span *ngIf="currentLang != 'th'">
                {{ itemObj.updateDate | date : "dd MMM YYYY" }}
              </span>
              <span *ngIf="currentLang != 'en'">
                {{ itemObj.updateDate | thaidate : "DD MMM YYYY" }}
              </span>
            </td>
            <td class="text-center">
              {{ itemObj.createBy ? itemObj.createBy : "-" }}
              <br />
            </td>

            <td class="text-center">
              <div class="d-flex justify-content-center">
                <a
                  class="btn btn-icon btn-icon rounded-circle btn-outline-warning mr-50"
                  [routerLink]="[editPathUrl + '/' + itemObj.id]"
                  ><i data-feather="edit"></i
                ></a>
                <a
                  class="btn btn-icon btn-icon rounded-circle btn-outline-danger mr-50"
                  [ngClass]="{
                    'd-none': itemObj.isDelete
                  }"
                  (click)="openDeleteContentModal(itemObj)"
                  ><i data-feather="trash-2" class="text-danger"></i
                ></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
