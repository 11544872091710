import { Component, OnInit } from '@angular/core';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import { HomeService } from '../home.service';
import { Validators } from '@angular/forms';
import Home from 'app/main/model/Home';
import { IngredientsService } from './ingredients.service';

@Component({
  selector: 'app-ingredients',
  templateUrl: './ingredients.component.html',
  styleUrls: ['./ingredients.component.scss']
})
export class IngredientsComponent implements OnInit {
  config!: ICMSConfig;
  keyName: string;


  

  constructor(private _ingredientsService: IngredientsService) {
    this.config=this._ingredientsService.config;
    this.keyName = this._ingredientsService.keyName;
   }

  ngOnInit(): void {
  }

}
