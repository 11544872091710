<table class="table">
    <thead>
        <tr>
            <th>#</th>
            <th>Name</th>
            <th>Time</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let branch of branchList; index as ind">
            <td>{{ ind + 1 }}</td>
            <td>{{ branch.name }}</td>
            <td></td>
        </tr>
    </tbody>
</table>