import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { CoreCommonModule } from '@core/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'app/main/components/components.module';
import { CsvModule } from '@ctrl/ngx-csv';
import { DataTablesModule } from 'angular-datatables';
import { ReviewComponent } from './review/review.component';
import { ReviewFormpageComponent } from './review/review-formpage/review-formpage.component';

const routes: Routes = [
  {
    path: 'review-manage/review',
    component: ReviewComponent,
    canActivate: [AuthGuard],
    data: { animation: 'review-manage', roles: ['SuperAdmin'] },
  },
  {
    path: 'review-manage/review/detail/:id',
    component: ReviewFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'promotion-code-create' },
  },
  // {
  //   path: 'code-manage/promotion-code/edit/:id',
  //   component: PromotionCodeFormpageComponent,
  //   canActivate: [AuthGuard],
  //   data: { animation: 'promotion-code-edit' },
  // },
]

@NgModule({
  declarations: [
    ReviewComponent,
    ReviewFormpageComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    CoreCommonModule,
    NgbModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    CsvModule,
    DataTablesModule
  ],
  exports: [
    ReviewComponent,
  ]
})
export class ReviewManageModule { }
