<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section>
      <app-search-panel
        [isForGroupTemplate]="false"
        (SetLoading)="SetLoadingState()"
        [isShowSortByGroup]="true"
        [isShowGroup]="true"
        [isShowCategory]="true"
        [isShowSubCategory]="true"
        [isShowClass]="true"
        [isShowSubClass]="true"
        [notShowCreateBtn]="true"
        (callDataFromAPI)="initData($event)"
      ></app-search-panel>

      <div class="card">
        <div class="card-body">
          <div>
            <div class="d-flex justify-content-end mb-1">
              <button
                (click)="exportReport()"
                [disabled]="selectItemToReportList.length < 1"
                class="btn btn-primary"
              >
                Export
              </button>
            </div>
            <div>
              <table class="table" datatable [dtOptions]="dtOptions">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">
                      <div
                        class="custom-control custom-checkbox custom-checkbox-datatable"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          [id]="'checkAllRecord'"
                          (change)="checkAllRecord($event)"
                        />
                        <label
                          class="custom-control-label"
                          for="checkAllRecord"
                        ></label>
                      </div>
                    </th>
                    <th width="5%" class="text-center">#</th>
                    <th width="45%" style="padding-left: 0.75rem !important">
                      {{ "General.Item" | translate }}
                    </th>
                    <th width="10%" class="text-center">
                      {{ "ContentDocument.PriceQ" | translate }}
                    </th>
                    <th width="10%" class="text-center">
                      {{ "Form.Quantity" | translate }}
                    </th>
                    <th width="10%">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="w-100" *ngIf="isLoading">
                    <td
                      class="text-center"
                      *ngFor="let num of [].constructor(6)"
                    >
                      <div class="skeleton-loading"></div>
                    </td>
                  </tr>

                  <tr *ngIf="itemList.length < 1 && !isLoading">
                    <td colspan="100%" class="text-center">
                      <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                    </td>
                  </tr>

                  <tr *ngFor="let item of itemList; index as ind">
                    <td class="text-center">
                      <div
                        class="custom-control custom-checkbox custom-checkbox-datatable"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          [id]="item.id"
                          (change)="checkRecord($event, item.id, ind)"
                        />
                        <label
                          class="custom-control-label"
                          for="{{ item.id }}"
                        ></label>
                      </div>
                    </td>
                    <td class="text-center">
                      {{ (page - 1) * pageSize + ind + 1 }}
                    </td>
                    <td>
                      <a
                        [routerLink]="['adjustment/edit/' + item.id]"
                        class="d-flex text-primary"
                      >
                        <img
                          [src]="
                            getImages(item.mediaFiles.coverImage[0].id, {
                              Width: 60,
                              Height: 60
                            })
                          "
                          style="border-radius: 5px; object-fit: cover"
                          width="60"
                          height="60"
                        />
                        <p class="my-auto ml-2">
                          {{ item.itemCode }}-&nbsp;{{ item.name }}
                        </p>
                      </a>
                    </td>
                    <td class="text-end">
                      {{ formatToMoney(item.sellingPrice) }}
                    </td>
                    <td class="text-end">{{ formatToMoney(item.quantity) }}</td>
                    <td class="text-center pr-2">
                      <a
                        class="btn btn-primary"
                        [routerLink]="['adjustment/edit/' + item.id]"
                        >{{ "Stock.AdjustStock" | translate }}</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
