<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>


        <app-search-panel-review-manage 
        [notShowCreateBtn]="true"
        (callSearch)="loadData($event)"
        >
        </app-search-panel-review-manage>

        <section>
            <div>
                <div>
                    <div class="card">
                        <div class="card-body">
                            <table [dtOptions]="dtOptions" class="table" datatable>
                                <thead>
                                    <tr class="text-center" style="width: auto !important">
                                        <th class="text-center" width="6%">
                                            {{ "ViewTable.No" | translate }}
                                        </th>
                                        <th class="text-center" width="14%">
                                            {{"ViewTable.ItemName" | translate}}
                                        </th>
                                        <th class="text-center" width="38%">
                                            {{"ViewTable.Message" | translate}}
                                        </th>
                                        <th class="text-center" width="7%">
                                            {{ "ViewTable.Status" | translate }}
                                        </th>
                                        <th class="text-center" width="12%">
                                            {{ "ViewTable.ReviewDate" | translate }}
                                        </th>
                                        <th class="text-center overme" width="11%">
                                            {{"ViewTable.ReviewBy" | translate}}
                                        </th>
                                        <th class="text-center" width="10%">
                                            {{ "ViewTable.Actions" | translate }}
                                        </th>
                                    </tr>
                                </thead>

                                <tbody style="width: 100% !important">

                                    <tr class="w-100" *ngIf="isLoading">
                                        <td class="text-center" *ngFor="let num of [].constructor(8)">
                                            <div class="skeleton-loading"></div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="itemList.length < 1 && !isLoading">
                                        <td colspan="100%" class="text-center">
                                            <p>{{ 'ViewTable.NoItemPreview' | translate }}</p>
                                        </td>
                                    </tr>

                                    <tr *ngFor="let itemObj of itemList; let ind = index">

                                        <td class="text-center">{{ (page - 1) * pageSize + ind + 1 }}</td>

                                        <td class="text-truncate" style="max-width: 100px">
                                            <a [routerLink]="[detailPathUrl + '/' + itemObj.id]
                          " class="text-truncate text-primary">
                                                {{ itemObj.itemName }}
                                                <!-- รอส่งชื่อ item -->
                                            </a>
                                        </td>

                                        <td class="text-truncate" style="max-width: 200px">
                                            <a [routerLink]="[detailPathUrl + '/' + itemObj.id]
                          " class="text-truncate text-secondary">
                                                {{ itemObj.message }}
                                            </a>
                                        </td>

                                        <td class="text-center">
                                            <div class="form-group my-auto">
                                                <div ngbDropdown>
                                                    <a ngbDropdownToggle href="javascript:void(0);"
                                                        class="d-flex justify-content-center text-secondary"
                                                        id="dropdownBrowserState" data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false" [ngClass]="{
                                'text-success': itemObj.isActive,
                                'text-danger': !itemObj.isActive
                              }">
                                                        <div>
                                                            {{
                                                            (itemObj.isActive
                                                            ? "Status.Published"
                                                            : "Status.Unpublished"
                                                            ) | translate
                                                            }}
                                                        </div>
                                                        <i data-feather="chevron-down"
                                                            class="text-dark cursor-pointer ml-50 my-auto"></i>
                                                    </a>
                                                    <div ngbDropdownMenu class="dropdown-menu-left"
                                                        aria-labelledby="dropdownBrowserState">
                                                        <a ngbDropdownItem *ngIf="itemObj.isActive"
                                                            class="d-flex align-items-center"
                                                            (click)="openIsActiveModal(itemObj, false)">
                                                            <i data-feather="eye-off" class="mr-50"></i>
                                                            {{ "Status.Unpublish" | translate }}
                                                        </a>
                                                        <a ngbDropdownItem *ngIf="!itemObj.isActive"
                                                            class="d-flex align-items-center"
                                                            (click)="openIsActiveModal(itemObj, true)">
                                                            <i data-feather="eye" class="mr-50"></i>
                                                            {{ "Status.Publish" | translate }}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td class="text-center my-auto">
                                            <span *ngIf="itemObj.reviewDate; else noStartDate">
                                                <span *ngIf="currentLang != 'th'">
                                                    {{ itemObj.reviewDate | date : "dd MMM YYYY" }}
                                                </span>
                                                <span *ngIf="currentLang == 'th'">
                                                    {{ itemObj.reviewDate | thaidate : "DD MMM YYYY" }}
                                                </span>
                                            </span>
                                            <ng-template #noStartDate>-</ng-template>
                                        </td>

                                        <td class="text-center text-truncate" style="max-width: 100px" >
                                            <span>
                                                {{ itemObj.reviewBy }}
                                            </span>
                                        </td>
                                        <td class="text-center d-flex justify-content-center">
                                            <!-- <a
                          class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50"
                          [routerLink]="[detailPathUrl + '/' + itemObj.id]"
                          ><i data-feather="eye"></i
                        ></a> -->
                                            <a class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50"
                                                [routerLink]="[detailPathUrl + '/' + itemObj.id]"><i
                                                    data-feather="eye"></i></a>


                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
</div>