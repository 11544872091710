import { Component, OnInit } from '@angular/core';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-home-landing',
  templateUrl: './home-landing.component.html',
  styleUrls: ['./home-landing.component.scss']
})
export class HomeLandingComponent implements OnInit {
  

  config:ICMSConfig = {
    apiPath: 'Home',
    pathUrl: '/cms/home/home-landing',
    tableName: 'Content',
    model: new Home(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.landingPage',
    formConfig: {
      productImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Product Image',
        description: 'รูปภาพสินค้า',
      },
    },
  }

  constructor() {
    
   }

  
  ngOnInit(): void {}

}
