import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { HowToUseService } from '../how-to-use.service';

@Component({
  selector: 'app-how-to-use-create',
  templateUrl: './how-to-use-create.component.html',
  styleUrls: ['./how-to-use-create.component.scss']
})
export class HowToUseCreateComponent implements OnInit {
  config: ICMSConfig;


  constructor(private _howToUseService: HowToUseService) {
    this.config = this._howToUseService.config
   }

  ngOnInit(): void {
  }

}
