import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { DocumentType } from 'app/main/model/DocumentType';
import { DocumentService } from 'app/main/pages/document/document.service';
import { ApiService } from 'app/main/service/api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-movement-table',
  templateUrl: './movement-table.component.html',
  styleUrls: ['./movement-table.component.scss'],
})
export class MovementTableComponent implements OnInit {
  @Input() itemID: string;
  itemMovementList: any[] = [];
  itemMovementSearchData: any = {};

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  isLoading: boolean = false;
  isAllChecked: any;

  public page = 1;
  public pageSize = 10;

  constructor(private _apiService: ApiService, private _documentService: DocumentService, private _router: Router) {}

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    var self = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: 10,
      order: [[0, 'asc']],
      ordering: false,
      serverSide: true,

      lengthMenu: [5, 10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',

      ajax: (dataTablesParameters: any, callback: any) => {
        this.itemMovementList = [];
        self.SetLoadingState();


        this.itemMovementSearchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.itemMovementSearchData.PageLength = dataTablesParameters.length;
        this.pageSize = dataTablesParameters.length;

        this.itemMovementSearchData.isDelete = false;
        this.itemMovementSearchData.Direction = 1;
        this.itemMovementSearchData.SortPath = 'OrdinalTime';

        this._apiService
          .GetDataById(
            'itemMovement/item',
            this.itemID,
            this.itemMovementSearchData
          )
          .subscribe((response) => {
            this.itemMovementList = response.data.resultData;
            self.checkAllRecord();
            self.SetLoadedState();
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          });
      },
    };
  }

  loadData(searchData?: any) {
    if (searchData) {
      this.itemMovementSearchData = searchData;
    }

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload(() => {
        this.SetLoadedState();
      });
    });
  }

  checkAllRecord(event?: any) {
    
    this.dtTrigger.next();
    this.datatableElement.dtInstance.then((dtInstance: any) => {
      this.isAllChecked = $('th', dtInstance.table(0).node()).find('[type="checkbox"]')[0]['checked'];
      var elts = [];
      $('td', dtInstance.table(0).node()).find('[type="checkbox"]')
      var el = $('td', dtInstance.table(0).node()).find('[type="checkbox"]')
      elts.push(el)
      var temp = elts[0]
      for (const elt of temp) {
        elt.checked = this.isAllChecked;
        //$("#txt-is_selected-" + elt.dataset['id']).attr('checked', 'checked');     
    };
    });      
  }

  checkRecord(event: any, id: string) {
    console.log(event, id)
  }

  routerToDocument(docType: number, docId: string) {
    
    let path = '';
    switch (docType) {
      case DocumentType.Quotation:
        path = this._documentService.quotation.DetailPathURL
        break;
      case DocumentType.SaleOrder:
        path = this._documentService.saleOrder.DetailPathURL
        break;
      case DocumentType.Invoice:
        path = this._documentService.invoice.DetailPathURL
        break;
      case DocumentType.TaxInvoice:
        path = this._documentService.Tax.DetailPathURL
        break;
      case DocumentType.Receipt:
        path = this._documentService.receipt.DetailPathURL
        break;
      case DocumentType.CreditNote:
        path = this._documentService.creditNote.DetailPathURL
        break;
      case DocumentType.DebitNote:
        path = this._documentService.debitNote.DetailPathURL
        break;
      case DocumentType.PurchaseRequisition:
        path = this._documentService.purchaseRequest.DetailPathURL
        break;
      case DocumentType.PurchaseOrder:
        path = this._documentService.purchaseOrder.DetailPathURL
        break;
      case DocumentType.GoodsReceive:
        path = this._documentService.GoodsReceive.DetailPathURL
        break;

      default:
        break;
    }

    const url = this._router.serializeUrl(
      this._router.createUrlTree([`/${path}/${docId}`])
    );

    if (url && docId) {
      window.open(url, '_blank');
    }
  }
}
