import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { QuotationComponent } from './quotation/quotation.component';
import { SaleOrderComponent } from './sale-order/sale-order.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { TaxComponent } from './tax/tax.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { QuotationFormpageComponent } from './quotation/quotation-formpage/quotation-formpage.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { ComponentsModule } from 'app/main/components/components.module';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SaleOrderFormpageComponent } from './sale-order/sale-order-formpage/sale-order-formpage.component';
import { QuotationDetailComponent } from './quotation/quotation-detail/quotation-detail.component';
import { QuotationExportComponent } from './quotation/quotation-detail/quotation-export/quotation-export.component';
import { DataTablesModule } from 'angular-datatables';
import { InvoiceDetailComponent } from './invoice/invoice-detail/invoice-detail.component';
import { InvoiceDetailExportComponent } from './invoice/invoice-detail/invoice-detail-export/invoice-detail-export.component';
import { InvoiceFormpageComponent } from './invoice/invoice-formpage/invoice-formpage.component';
import { CreditNoteComponent } from './credit-note/credit-note.component';
import { DebitNoteComponent } from './debit-note/debit-note.component';
import { DebitNoteFormpageComponent } from './debit-note/debit-note-formpage/debit-note-formpage.component';
import { CreditNoteFormpageComponent } from './credit-note/credit-note-formpage/credit-note-formpage.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SaleOrderDetailComponent } from './sale-order/sale-order-detail/sale-order-detail.component';
import { SaleOrderExportComponent } from './sale-order/sale-order-detail/sale-order-export/sale-order-export.component';

import { BlockUIModule } from 'ng-block-ui';
import { ReceiptDetailComponent } from './receipt/receipt-detail/receipt-detail.component';
import { ReceiptExportComponent } from './receipt/receipt-detail/receipt-export/receipt-export.component';
import { TaxDetailComponent } from './tax/tax-detail/tax-detail.component';
import { TaxExportComponent } from './tax/tax-detail/tax-export/tax-export.component';

import { DebitNoteDetailComponent } from './debit-note/debit-note-detail/debit-note-detail.component';
import { DebitNoteDetailExportComponent } from './debit-note/debit-note-detail/debit-note-detail-export/debit-note-detail-export.component';
import { CreditNoteDetailComponent } from './credit-note/credit-note-detail/credit-note-detail.component';
import { CreditNoteDetailExportComponent } from './credit-note/credit-note-detail/credit-note-detail-export/credit-note-detail-export.component';
import { PurchaseRequestComponent } from './purchase-request/purchase-request.component';
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { PurchaseOrderFormpageComponent } from './purchase-order/purchase-order-formpage/purchase-order-formpage.component';
import { PurchaseOrderDetailComponent } from './purchase-order/purchase-order-detail/purchase-order-detail.component';
import { PurchaseRequestDetailComponent } from './purchase-request/purchase-request-detail/purchase-request-detail.component';
import { PurchaseRequestFormpageComponent } from './purchase-request/purchase-request-formpage/purchase-request-formpage.component';
import { PurchaseOrderExportComponent } from './purchase-order/purchase-order-detail/purchase-order-export/purchase-order-export.component';
import { PurchaseRequestExportComponent } from './purchase-request/purchase-request-detail/purchase-request-export/purchase-request-export.component';
import { GoodsReceiptComponent } from './goods-receipt/goods-receipt.component';
import { GoodsReceiptDetailComponent } from './goods-receipt/goods-receipt-detail/goods-receipt-detail.component';
import { GoodsReceiptExportComponent } from './goods-receipt/goods-receipt-detail/goods-receipt-export/goods-receipt-export.component';
import { GoodsReceiptFormpageComponent } from './goods-receipt/goods-receipt-formpage/goods-receipt-formpage.component';
import { environment } from 'environments/environment';
import { ShippingExportComponent } from './quotation/quotation-detail/shipping-export/shipping-export.component';


const routes: Routes = !environment.menuList.transactionDoc ? [] : [

  //quotation
  {
    path: 'document/quotation/view',
    component: QuotationComponent,
    canActivate: [AuthGuard],
    data: { animation: 'quotation-view' },
  },
  {
    path: 'document/quotation/create',
    component: QuotationFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'quotation-create' },
  },
  {
    path: 'document/quotation/edit/:id',
    component: QuotationFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'quotation-edit' },
  },
  {
    path: 'document/quotation/detail/:id',
    component: QuotationDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'quotation-detail' },
  },
  {
    path: 'document/quotation/detail/:id/export',
    component: QuotationExportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'quotation-detail-export' },
  },


  //saleorder
  {
    path: 'document/saleorder/view',
    component: SaleOrderComponent,
    canActivate: [AuthGuard],
    data: { animation: 'saleorder-view' },
  },
  {
    path: 'document/saleorder/create',
    component: SaleOrderFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'saleorder-create' },
  },
  {
    path: 'document/saleorder/edit/:id',
    component: SaleOrderFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'saleorder-edit' },
  },
  {
    path: 'document/saleorder/detail/:id',
    component: SaleOrderDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'saleorder-detail' },
  },
  {
    path: 'document/saleorder/detail/:id/export',
    component: SaleOrderExportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'saleorder-detail-export' },
  },

  //invoice
  {
    path: 'document/invoice/view',
    component: InvoiceComponent,
    canActivate: [AuthGuard],
    data: { animation: 'invoice-view' },
  },
  {
    path: 'document/invoice/create/ref/:refID',
    component: InvoiceFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'invoice-create' },
  },
  {
    path: 'document/invoice/edit/:id',
    component: InvoiceFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'invoice-edit' },
  },
  {
    path: 'document/invoice/detail/:id',
    component: InvoiceDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'invoice-detail' },
  },
  {
    path: 'document/invoice/detail/:id/export',
    component: InvoiceDetailExportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'invoice-detail-export' },
  },
  {
    path: 'document/invoice/detail/:id/export-shipping',
    component: ShippingExportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'invoice-detail-export-shipping' },
  },

  //tax
  {
    path: 'document/tax/view',
    component: TaxComponent,
    canActivate: [AuthGuard],
    data: { animation: 'tax-view' },
  },
  {
    path: 'document/tax/create',
    component: TaxComponent,
    canActivate: [AuthGuard],
    data: { animation: 'tax-create' },
  },
  {
    path: 'document/tax/edit/:id',
    component: TaxComponent,
    canActivate: [AuthGuard],
    data: { animation: 'tax-edit' },
  },
  {
    path: 'document/tax/detail/:id',
    component: TaxDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'tax-detail' },
  },
  {
    path: 'document/tax/detail/:id/export',
    component: TaxExportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'saleorder-detail-export' },
  },

  //receipt
  {
    path: 'document/receipt/view',
    component: ReceiptComponent,
    canActivate: [AuthGuard],
    data: { animation: 'receipt-view' },
  },
  {
    path: 'document/receipt/create',
    component: ReceiptComponent,
    canActivate: [AuthGuard],
    data: { animation: 'receipt-create' },
  },
  {
    path: 'document/receipt/edit/:id',
    component: ReceiptComponent,
    canActivate: [AuthGuard],
    data: { animation: 'receipt-edit' },
  },
  {
    path: 'document/receipt/detail/:id',
    component: ReceiptDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'receipt-detail' },
  },
  {
    path: 'document/receipt/detail/:id/export',
    component: ReceiptExportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'receipt-detail-export' },
  },

  //debit-note
  {
    path: 'document/debit-note/view',
    component: DebitNoteComponent,
    canActivate: [AuthGuard],
    data: { animation: 'debit-note-view' },
  },
  {
    path: 'document/debit-note/create',
    component: DebitNoteFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'debit-note-create' },
  },
  {
    path: 'document/debit-note/edit/:id',
    component: DebitNoteFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'debit-note-edit' },
  },
  {
    path: 'document/debit-note/detail/:id',
    component: DebitNoteDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'debit-note-detail' },
  },
  {
    path: 'document/debit-note/detail/:id/export',
    component: DebitNoteDetailExportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'debit-note-detail-export' },
  },

  //credit-note
  {
    path: 'document/credit-note/view',
    component: CreditNoteComponent,
    canActivate: [AuthGuard],
    data: { animation: 'credit-note-view' },
  },
  {
    path: 'document/credit-note/create',
    component: CreditNoteFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'credit-note-create' },
  },
  {
    path: 'document/credit-note/detail/:id',
    component: CreditNoteDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'credit-note-detail' },
  },
  {
    path: 'document/credit-note/edit/:id',
    component: CreditNoteFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'credit-note-edit' },
  },
  {
    path: 'document/credit-note/detail/:id/export',
    component: CreditNoteDetailExportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'credit-note-export' },
  },

  {
    path: 'document/purchase-request/view',
    component: PurchaseRequestComponent,
    canActivate: [AuthGuard],
    data: { animation: 'purchase-request-view' },
  },
  {
    path: 'document/purchase-request/create',
    component: PurchaseRequestFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'purchase-request-create' },
  },
  {
    path: 'document/purchase-request/edit/:id',
    component: PurchaseRequestFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'purchase-request-edit' },
  },
  {
    path: 'document/purchase-request/detail/:id',
    component: PurchaseRequestDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'purchase-request-detail' },
  },
  {
    path: 'document/purchase-request/detail/:id/export',
    component: PurchaseRequestExportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'purchase-request-detail-export' },
  },

  {
    path: 'document/purchase-order/view',
    component: PurchaseOrderComponent,
    canActivate: [AuthGuard],
    data: { animation: 'purchase-order-view' },
  },
  {
    path: 'document/purchase-order/edit/:id',
    component: PurchaseOrderFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'purchase-order-edit' },
  },
  {
    path: 'document/purchase-order/detail/:id',
    component: PurchaseOrderDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'purchase-order-detail' },
  },
  {
    path: 'document/purchase-order/detail/:id/export',
    component: PurchaseOrderExportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'purchase-order-detail-export' },
  },

  {
    path: 'document/goods-receive/view',
    component: GoodsReceiptComponent,
    canActivate: [AuthGuard],
    data: { animation: 'goods-receive-view' },
  },
  {
    path: 'document/goods-receive/create/ref/:refID',
    component: GoodsReceiptFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'goods-receive-create' },
  },
  {
    path: 'document/goods-receive/edit/:id',
    component: GoodsReceiptFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'goods-receive-edit' },
  },
  {
    path: 'document/goods-receive/detail/:id',
    component: GoodsReceiptDetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'goods-receive-detail' },
  },
  {
    path: 'document/goods-receive/detail/:id/export',
    component: GoodsReceiptExportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'goods-receive-detail-export' },
  },
];

@NgModule({
  declarations: [
    QuotationComponent,
    SaleOrderComponent,
    InvoiceComponent,
    TaxComponent,
    ReceiptComponent,
    QuotationFormpageComponent,
    QuotationDetailComponent,
    QuotationExportComponent,
    InvoiceDetailComponent,
    InvoiceDetailExportComponent,
    InvoiceFormpageComponent,
    CreditNoteComponent,
    DebitNoteComponent,
    DebitNoteFormpageComponent,
    CreditNoteFormpageComponent,
    SaleOrderFormpageComponent,
    SaleOrderDetailComponent,
    SaleOrderExportComponent,
    ReceiptDetailComponent,
    ReceiptExportComponent,
    TaxDetailComponent,
    TaxExportComponent,
    DebitNoteDetailComponent,
    DebitNoteDetailExportComponent,
    CreditNoteDetailComponent,
    CreditNoteDetailExportComponent,
    PurchaseRequestComponent,
    PurchaseOrderComponent,
    PurchaseOrderFormpageComponent,
    PurchaseOrderDetailComponent,
    PurchaseRequestDetailComponent,
    PurchaseRequestFormpageComponent,
    PurchaseOrderExportComponent,
    PurchaseRequestExportComponent,
    GoodsReceiptComponent,
    GoodsReceiptDetailComponent,
    GoodsReceiptExportComponent,
    GoodsReceiptFormpageComponent,
    ShippingExportComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ComponentsModule,
    CoreCommonModule,
    ContentHeaderModule,
    Ng2FlatpickrModule,
    DragulaModule.forRoot(),
    FormsModule,
    TranslateModule,
    NgbModule,
    DataTablesModule,
    BlockUIModule.forRoot(),
    NgSelectModule,
    NgbNavModule,
  ],
  providers: [DragulaService, CurrencyPipe, DecimalPipe],
})
export class DocumentModule {}
