import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { User } from 'app/auth/models';
import { TimelineItem } from 'app/main/components/horizontal-timeline/horizontal-timeline.component';
import Customer from 'app/main/model/Customer';
import CustomerAddress from 'app/main/model/CustomerAddress';
import { Quotation } from 'app/main/model/Quotation';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DocumentService } from '../../../document.service';
import { AuthenticationService } from 'app/auth/service';
import { TranslateService } from '@ngx-translate/core';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/main/service/api.service';

@Component({
  selector: 'app-shipping-export',
  templateUrl: './shipping-export.component.html',
  styleUrls: ['./shipping-export.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShippingExportComponent implements OnInit {
  @BlockUI('section-block') sectionBlockUI: NgBlockUI;
  @BlockUI() blockUI: NgBlockUI;

  contentHeader: object;
  passObj: object;
  currentUser: User;
  timelineData: TimelineItem[];
  Excess: Boolean = false;
  quotationObj: Quotation;

  customerAddressObj: CustomerAddress;
  customerObj: Customer;

  docID: string;
  apiPath: string;

  constructor(
    private _documentService: DocumentService,
    private _documentApiService: DocumentApiService,
    private _route: ActivatedRoute,
    private _apiService: ApiService,
    private _router: Router
  ) { 
    this.apiPath = this._documentService.invoice.ApiPath;
  }

  ngOnInit(): void {
    // this.blockUI.start();

    var self = this;
    self.initData();
  }

  initData(): void {
    this._route.paramMap.subscribe((val) => {
      this.docID = this._route.snapshot.paramMap.get('id');
    });
    console.log('Doc id : ' + this.docID);
    console.log(this.apiPath);

    this.getInvoiceData();
  }

  getInvoiceData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._documentApiService
        .GetDocData(this.apiPath, this.docID)
        .subscribe((res) => {
          console.log(res);

          self.quotationObj = res.data.resultData[0];
          console.log('Customer ID', self.quotationObj);

          self.getCustomer(self.quotationObj.customerId);
        });
    });
  }


  getCustomerAddress(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('customerAddress', {
          customerId: customerId,
          isDelete: false,
        })
        .subscribe((res) => {
          self.customerAddressObj = res.data.resultData.filter((it) => {
            return it['useForShipping'] == true;
          });

          this.passObj = {
            quotationObj: this.quotationObj,
            customerObj: this.customerObj,
            customerAddressObj: this.customerAddressObj[0],
          };
          this.blockUI.stop();
          console.log(this.passObj);
        });
    });
  }

  getCustomer(customerId: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService.GetDataById('customer', customerId).subscribe((res) => {
        self.customerObj = res.data.resultData[0];
        self.getCustomerAddress(customerId);
      });
    });
  }

}
