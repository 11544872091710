<ng-container *ngIf="langList.length > 1">
  <div class="lang-switcher-sidebar nav-vertical">
    <ul ngbNav class="nav nav-pills flex-column text-nowrap mb-0">
      <li ngbNavItem *ngFor="let lang of langList">
        <a
          ngbNavLink
          (click)="selectedLanguage(lang)"
          [class.active]="selectedLang === lang"
          >{{ lang | uppercase }}</a
        >
      </li>
    </ul>
  </div>
</ng-container>

<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <a [routerLink]="[pathUrl]">
          <i
            data-feather="arrow-left"
            class="text-primary cursor-pointer back-to-manage-btn"
          ></i>
          {{ 'General.ItemManage' | translate }}
        </a>

        <ng-container *ngIf="langList.length > 1">
          <ul ngbNav class="nav nav-pills">
            <li ngbNavItem *ngFor="let lang of langList">
              <a
                ngbNavLink
                (click)="selectedLanguage(lang)"
                [class.active]="selectedLang === lang"
                >{{ lang | uppercase }}</a
              >
            </li>
          </ul>
        </ng-container>
      </div>
      <div class="card-body">
        <app-item-set-form *ngIf="!isLoading" [callbackUrl]="pathUrl" [selectedLang]="selectedLang"></app-item-set-form>
      </div>
    </div>
  </div>
</div>
