<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <app-search-panel-frontend-user
      [pathUrl]="pathUrl + '/create'"
      (SetLoading)="SetLoadingState()"
      (callDataFromAPI)="getAllUser($event)"
    ></app-search-panel-frontend-user>
    <!-- Basic Alerts start -->
    <section>
      <div class="card">
        <div class="card-body">
          <!-- <a
              (click)="
                exportToXLSX(userList, 'UserList.xlsx', columnToExportXLSX)
              "
              >EXPORT CSV</a
            > -->

          <div>
            <table datatable [dtOptions]="dtOptions" class="table">
              <thead>
                <tr>
                  <th width="5%" class="text-center">#</th>
                  <th width="30%" class="text-center">
                    {{ "ViewTable.UserName" | translate }}
                  </th>
                  <th width="10%" class="text-center">
                    {{ "ViewTable.Role" | translate }}
                  </th>
                  <th width="20%" class="text-center">
                    {{ "ViewTable.Info" | translate }}
                  </th>
                  <th width="10%" class="text-center">
                    {{ "ViewTable.Status" | translate }}
                  </th>
                  <th width="15%" class="text-center">
                    {{ "ViewTable.CreateDate" | translate }}
                  </th>
                  <th width="5%" class="text-center">
                    {{ "ViewTable.Actions" | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="w-100" *ngIf="isLoading">
                  <td class="text-center" *ngFor="let num of [].constructor(7)">
                    <div class="skeleton-loading"></div>
                  </td>
                </tr>

                <tr *ngIf="userList.length < 1 && !isLoading">
                  <td colspan="100%" class="text-center">
                    <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                  </td>
                </tr>

                <tr *ngFor="let user of userList; index as ind">
                  <td class="text-center">
                    <span>{{ (page - 1) * pageSize + ind + 1 }}</span>
                  </td>
                  <td class="text-center my-auto">
                    <div>
                      <a
                        [routerLink]="[pathUrl + '/edit/' + user.id]"
                        class="d-flex"
                      >
                        <img
                          [src]="
                            user.mediaFiles?.profileImage
                              ? getImages(
                                  user.mediaFiles?.profileImage[0]?.id,
                                  {
                                    Width: 60,
                                    Height: 60
                                  }
                                )
                              : defaultIMG
                          "
                          [alt]="defaultIMG"
                          
                          class="mr-1"
                          style="
                            border-radius: 5px;
                            width: 50px;
                            height: 50px;
                            object-fit: cover;
                          "
                        />
                        <p class="text-truncate my-auto">{{ user.username }}</p>
                      </a>

                      <!-- <span
                          class="ml-50"
                          [ngClass]="{
                            'text-success': user.isVerify,
                            'text-danger': !user.isVerify
                          }"
                        >
                          <i
                            data-feather="check-circle"
                            *ngIf="user.isVerify"
                          ></i>
                          <i
                            data-feather="x-octagon"
                            *ngIf="!user.isVerify"
                          ></i>
                        </span> -->
                    </div>
                  </td>

                  <td class="text-center">
                    <app-role-badge
                      [roleSetName]="user.roleSetName"
                    ></app-role-badge>
                  </td>

                  <td>
                    {{ user.firstName ? user.firstName : "-" }}
                    {{ user.lastName ? user.lastName : "-" }} <br />
                    {{ user.email }}
                  </td>
                  <td class="text-center">
                    <div class="form-group my-auto">
                      <div ngbDropdown>
                        <a
                          ngbDropdownToggle
                          href="javascript:void(0);"
                          class="d-flex justify-content-center text-secondary"
                          id="dropdownBrowserState"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          [ngClass]="{
                            'text-success': user.isActive,
                            'text-danger': !user.isActive
                          }"
                        >
                          <div>
                            {{
                              (user.isActive
                                ? "Status.Active"
                                : "Status.Inactive"
                              ) | translate
                            }}
                          </div>
                          <i
                            data-feather="chevron-down"
                            class="text-dark cursor-pointer ml-50 my-auto"
                          ></i>
                        </a>
                        <div
                          ngbDropdownMenu
                          class="dropdown-menu-left"
                          aria-labelledby="dropdownBrowserState"
                        >
                          <a
                            ngbDropdownItem
                            *ngIf="user.isActive"
                            class="d-flex align-items-center"
                            (click)="openIsActiveModal(user, false)"
                          >
                            <i data-feather="eye-off" class="mr-50"></i>
                            {{ "Status.Inactive" | translate }}
                          </a>
                          <a
                            ngbDropdownItem
                            *ngIf="!user.isActive"
                            class="d-flex align-items-center"
                            (click)="openIsActiveModal(user, true)"
                          >
                            <i data-feather="eye" class="mr-50"></i>
                            {{ "Status.Active" | translate }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span>{{ "ViewTable.By" | translate }} </span
                    >{{ user.createBy }}
                    <br />
                    <label for="helpInputTop">
                      <span>{{ "ViewTable.Date" | translate }} </span>
                      <span *ngIf="currentLang == 'th'">
                        {{ user.createDate | thaidate : "D MMM YYYY" }}
                      </span></label
                    >
                    <small>
                      <i>
                        <span *ngIf="currentLang != 'th'">
                          {{ user.createDate | date : "d MMM YYYY" }}
                        </span></i
                      ></small
                    >
                  </td>
                  <td class="text-center">
                    <div class="d-flex justify-content-center">
                      <a
                        class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50"
                        (click)="openResetPasswordModal(user)"
                        ><i data-feather="key" class="text-success"></i
                      ></a>
                      <a
                        class="btn btn-icon btn-icon rounded-circle btn-outline-warning mr-50"
                        [routerLink]="[pathUrl + '/edit/' + user.id]"
                        ><i data-feather="edit"></i
                      ></a>
                      <a
                        class="btn btn-icon btn-icon rounded-circle btn-outline-danger mr-50"
                        (click)="openDeleleUserModal(user)"
                        ><i data-feather="trash-2" class="text-danger"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
