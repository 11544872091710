import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { HomeService } from '../../home.service';
import { IngredientsService } from '../ingredients.service';

@Component({
  selector: 'app-ingredients-create',
  templateUrl: './ingredients-create.component.html',
  styleUrls: ['./ingredients-create.component.scss']
})
export class IngredientsCreateComponent implements OnInit {
  config: ICMSConfig;
  constructor(private _ingredientService: IngredientsService) {
    this.config = this._ingredientService.config;
   }

  ngOnInit(): void {
  }

}
