import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';

@Injectable({
  providedIn: 'root'
})
export class CustomerReviewService {
  config:ICMSConfig = {
    apiPath: 'CustomerReview',
    pathUrl: '/cms/home/customer-review',
    tableName: 'Content',
    model: new Home(),
    isList: true,
    componentName: 'BreadCrumbs.CMS.customerReview',
    formConfig: {
      reviewerImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Reviewer Image',
        description: 'รูปภาพผู้ใช้',
      },
      title: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Reviewer Name',
        description: 'ชื่อผู้ใช้',
      },
      reviewThumbnails: {
        
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Review Thumbnails',
        description: 'รูปภาพการใช้งาน',
      },
      detail: {
        type: FormType.Textarea,
        validator: [Validators.required],
        label: 'Review Description',
        description: 'คำอธิบาย',
      },
      isActive: {
        type: FormType.IsActive,
        label: 'Active',
        description: 'เปิดใช้งาน'
      }
    },
  }
  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'title';
  constructor() { }
}
