import { CoreMenu } from '@core/types';
import { environment } from 'environments/environment';

const isJoinIV_TX_RE = environment.transaction.isJoinIV_TX_RE;
const isShowMenuList = environment.menuList

export const menu: CoreMenu[] = [
  {
    id: 'general-section',
    type: 'section',
    title: 'General.General',
    icon: 'bar-chart-2',
    children: [
      {
        id: 'home',
        title: 'Home',
        translate: 'General.Home',
        type: 'item',
        icon: 'home',
        url: 'home',
      },
      {
        id: 'settings',
        title: 'Settings',
        translate: 'General.Settings',
        type: 'item',
        icon: 'settings',
        url: 'settings',
      },
    ],
  },

  {
    id: 'itemmanage-section',
    type: 'section',
    title: 'General.ItemManagement',
    icon: 'bar-chart-2',
    hidden: !isShowMenuList.itemManage,
    role: ['SuperAdmin'],
    children: [
      {
        id: 'brand-management',
        translate: 'General.BrandManage',
        title: 'Manage Brand',
        hidden: !isShowMenuList.itemManage,
        type: 'item',
        icon: 'award',
        url: 'item-manage/brand',
      },
      {
        id: 'group-management-collapse',
        title: 'Manage Group',
        translate: 'General.GroupManage',
        hidden: !isShowMenuList.itemManage,
        type: 'collapsible',
        icon: 'tag',
        children: [
          {
            id: 'group-management',
            translate: 'General.Group',
            title: 'Group',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/group',
          },
          {
            id: 'category-management',
            translate: 'General.Category',
            title: 'Category',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/category',
          },
          {
            id: 'subcategory-management',
            translate: 'General.SubCategory',
            title: 'Sub-Category',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/subcategory',
          },
          {
            id: 'class-management',
            translate: 'General.Class',
            title: 'Class',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/class',
          },
          {
            id: 'subclass-management',
            translate: 'General.SubClass',
            title: 'Sub-Class',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/subclass',
          },
        ],
      },
      {
        id: 'item-management',
        translate: 'General.ItemManage',
        title: 'Manage Item',
        hidden: !isShowMenuList.itemManage,
        type: 'item',
        icon: 'gift',
        url: 'item-manage/item',
      },
      {
        id: 'item-set-management',
        translate: 'General.ItemSetManage',
        title: 'Manage Item Set',
        hidden: !isShowMenuList.itemManage,
        type: 'item',
        icon: 'gift',
        url: 'item-manage/set',
      },
    ],
  },

  {
    id: 'content-section',
    type: 'section',
    title: 'CMS',
    icon: 'bar-chart-2',
    hidden: !isShowMenuList.cms,
    role: ['SuperAdmin'],
    children: [
      {
        id: 'content-section',
        title: 'Manage Content',
        translate: 'General.ContentManage',
        hidden: !isShowMenuList.cms,
        icon: 'bar-chart-2',
        type: 'collapsible',
        children: [
          {

            id: 'home-management',
            title: 'Home',
            translate: 'General.Home',
            hidden: !isShowMenuList.cms,
            icon: 'home',
            type: 'collapsible',
            // id: 'home-management',
            // title: 'Home',
            // translate: 'General.Home',
            // hidden: !isShowMenuList.cms,
            // type: 'collapsible',
            // icon: 'home',
            // url: 'cms/home',
            children: [
              {
                id: 'home-landing-management',
                title: 'Landing',
                translate: 'BreadCrumbs.CMS.landingPage',
                hidden: !isShowMenuList.cms,
                type: 'item',

                // icon: 'trello',
                url: 'cms/home/home-landing',
              },
              {
                id: 'home-ingredients-management',
                title: 'Ingredients',
                translate: 'BreadCrumbs.CMS.ingredients',
                hidden: !isShowMenuList.cms,
                type: 'item',
                // icon: 'trello',
                url: 'cms/home/home-ingredients',
              },
              {
                id: 'home-suitable-for-management',
                title: 'Suitable For',
                translate: 'BreadCrumbs.CMS.suitableFor',
                hidden: !isShowMenuList.cms,
                type: 'item',
                // icon: 'trello',
                url: 'cms/home/suitable-for',
              },
              {
                id: 'home-how-to-use-management',
                title: 'How to use',
                translate: 'BreadCrumbs.CMS.howToUse',
                hidden: !isShowMenuList.cms,
                type: 'item',
                // icon: 'trello',
                url: 'cms/home/how-to-use',
              },
              {
                id: 'customer-review-management',
                title: 'Customers Review',
                translate: 'BreadCrumbs.CMS.customerReview',
                hidden: !isShowMenuList.cms,
                type: 'item',
                // icon: 'trello',
                url: 'cms/home/customer-review',
              },
              {
                id: 'how-to-buy-management',
                title: 'How to buy',
                translate: 'BreadCrumbs.CMS.howToBuy',
                hidden: !isShowMenuList.cms,
                type: 'item',
                //icon: 'trello',
                url: 'cms/home/how-to-buy',
              },
              {
                id: 'faq-management',
                title: 'FAQ',
                translate: 'BreadCrumbs.CMS.faq',
                hidden: !isShowMenuList.cms,
                type: 'item',
                //icon: 'trello',
                url: 'cms/home/faq',
              },

            ]
          },
        ],
      },
    ],
  },
  // {
  //   id: 'customer-section',
  //   type: 'section',
  //   title: 'Customer',
  //   translate: 'General.Customer',
  //   hidden: !isShowMenuList.customer,
  //   icon: 'bar-chart-2',
  //   role: ['SuperAdmin'],
  //   children: [
  //     {
  //       id: 'customer',
  //       title: 'Customer',
  //       translate: 'General.Customer',
  //       hidden: !isShowMenuList.customer,
  //       type: 'item',
  //       icon: 'users',
  //       url: 'customer',
  //     },
  //   ],
  // },
  
  {
    id: 'code-manage-section',
    type: 'section',
    title: 'Code Manage',
    translate: 'General.ManageCode',
    hidden: !isShowMenuList.codeManage,
    icon: 'bar-chart-2',
    role: ['SuperAdmin'],
    children: [
      {
        id: 'promotion-code',
        title: 'Promotion Code',
        translate: 'General.PromotionCode',
        hidden: !isShowMenuList.codeManage,
        type: 'item',
        icon: 'tag',
        url: 'code-manage/promotion-code',
      },
    ],
  },

  {
    id: 'review-manage-section',
    type: 'section',
    title: 'Review Manage',
    translate: 'General.ManageReview',
    hidden: !isShowMenuList.reviewManage,
    icon: 'bar-chart-2',
    role: ['SuperAdmin'],
    children: [
      {
        id: 'review',
        title: 'Review',
        translate: 'General.Review',
        hidden: !isShowMenuList.reviewManage,
        type: 'item',
        icon: 'tag',
        url: 'review-manage/review',
      },
    ],
  },

  {
    id: 'transaction',
    type: 'section',
    title: 'General.Sale', // title: 'Transaction',
    // translate: 'MENU.OTHERS.SECTION',
    hidden: !isShowMenuList.transactionDoc,
    icon: 'box',
    children: [
      {
        id: 'document',
        title: 'Revenue Document',
        translate: 'General.RevenueDocument',
        hidden: !isShowMenuList.transactionDoc,
        icon: 'paperclip',
        type: 'collapsible',
        children: [

          {
            id: 'quotation',
            title: 'Quotation',
            translate: 'Menu.Document.QO',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'quotation-create',
                title: 'Create',
                translate: 'General.Create',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/quotation/create',
              },
              {
                id: 'quotation-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/quotation/view',
              },
            ],
          },

          {
            id: 'saleorder',
            title: 'Sale Order',
            translate: 'Menu.Document.SO',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'saleorder-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/saleorder/view',
              },
            ],
          },

          {
            id: 'invoice',
            title: isJoinIV_TX_RE ? 'Invoice/Tax Invoice/Receipt' : 'Invoice',
            translate: isJoinIV_TX_RE
              ? 'Document.IV_TX_RE'
              : 'Menu.Document.IV',
            icon: 'circle',
            type: 'collapsible',
            hidden: !isShowMenuList.transactionDoc,
            children: [
              {
                id: 'invoice-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/invoice/view',
              },
            ],
          },

          {
            id: 'tax',
            title: 'Tax Invoice',
            translate: 'Menu.Document.TX',
            icon: 'circle',
            type: 'collapsible',
            hidden: isJoinIV_TX_RE || !isShowMenuList.transactionDoc,
            children: [
              {
                id: 'tax-view',
                title: 'View',
                translate: 'General.ViewAll',
                type: 'item',
                url: 'document/tax/view',
              },
            ],
          },

          {
            id: 'receipt',
            title: 'Receipt',
            translate: 'Menu.Document.RC',
            icon: 'circle',
            type: 'collapsible',
            hidden: isJoinIV_TX_RE || !isShowMenuList.transactionDoc,
            children: [
              {
                id: 'receipt-view',
                title: 'View',
                translate: 'General.ViewAll',
                type: 'item',
                url: 'document/receipt/view',
              },
            ],
          },

          {
            id: 'credit-note',
            title: 'Credit Note',
            translate: 'Menu.Document.CNT',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'credit-note-create',
                title: 'Create',
                translate: 'General.Create',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/credit-note/create',
              },
              {
                id: 'credit-note-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/credit-note/view',
              },
            ],
          },

          {
            id: 'debit-note',
            title: 'Debit Note',
            translate: 'Menu.Document.DBN',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'debit-note-create',
                title: 'Create',
                translate: 'General.Create',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/debit-note/create',
              },
              {
                id: 'debit-note-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/debit-note/view',
              },
            ],
          },
        ],
      },
      {
        id: 'document-expenses',
        title: 'Expenses Document',
        translate: 'General.ExpensesDocument',
        hidden: !isShowMenuList.transactionDoc,
        icon: 'paperclip',
        type: 'collapsible',
        children: [
          {
            id: 'purchase-request',
            title: 'Purchase Request',
            translate: 'Menu.Document.PR',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'pr-create',
                title: 'Create',
                translate: 'General.Create',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/purchase-request/create',
              },
              {
                id: 'pr-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/purchase-request/view',
              },
            ],
          },
          {
            id: 'purchase-order',
            title: 'Purchase Order',
            translate: 'Menu.Document.PO',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'po-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/purchase-order/view',
              },
            ],
          },
          {
            id: 'goods-receive',
            title: 'Goods Receive',
            translate: 'Menu.Document.GR',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'gr-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/goods-receive/view',
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: 'stock-management-section',
    type: 'section',
    title: 'Stock',
    hidden: !isShowMenuList.stock,
    translate: 'General.Stock',
    icon: 'bar-chart-2',
    role: ['SuperAdmin'],
    children: [
      {
        id: 'stock-management',
        title: 'Stock',
        hidden: !isShowMenuList.stock,
        translate: 'General.Stock',
        type: 'item',
        icon: 'box',
        url: 'stock',
      },
    ],
  },

  // {
  //   id: 'frontend-control-panel-section',
  //   type: 'section',
  //   title: 'Frontend Control Panel',
  //   translate: 'Menu.FrontendControlPanel',
  //   icon: 'bar-chart-2',
  //   role: ['SuperAdmin'],
  //   children: [
  //     {
  //       id: 'frontend-role-management',
  //       title: 'Manage Roles',
  //       translate: 'General.RolesManage',
  //       type: 'item',
  //       icon: 'key',
  //       url: 'admin/frontend/roleset',
  //     },
  //     {
  //       id: 'frontend-user-management',
  //       title: 'Manage User',
  //       translate: 'General.UserManage',
  //       type: 'item',
  //       icon: 'user',
  //       url: 'admin/frontend/users',
  //     },
  //   ],
  // },

  {
    id: 'control-panel-section',
    type: 'section',
    title: 'Control Panel',
    translate: 'General.ControlPanel',
    icon: 'bar-chart-2',
    role: ['SuperAdmin'],
    children: [
      // {
      //   id: 'trash',
      //   title: 'Trash',
      //   translate: 'General.Trash',
      //   type: 'item',
      //   icon: 'trash-2',
      //   url: 'admin/trash',
      // },
      // {
      //   id: 'acl-management',
      //   title: 'Manage ACL',
      //   translate: 'General.ACLManage',
      //   type: 'item',
      //   icon: 'key',
      //   url: 'admin/acl',
      // },
      {
        id: 'system-settings',
        title: 'System Settings',
        translate: 'General.SystemSettings',
        type: 'item',
        icon: 'key',
        url: 'admin/system-settings',
      },
      {
        id: 'role-management',
        title: 'Manage Roles',
        translate: 'General.RolesManage',
        type: 'item',
        icon: 'key',
        url: 'admin/roleset',
      },
      {
        id: 'user-management',
        title: 'Manage User',
        translate: 'General.UserManage',
        type: 'item',
        icon: 'user',
        url: 'admin/users',
      },
    ],
  },
];
