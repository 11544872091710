import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Promotion from 'app/main/model/Promotion';
import { ReviewManageService } from 'app/main/pages/review-manage/review-manage.service';
import { ApiService } from 'app/main/service/api.service';

@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.scss']
})
export class ReviewFormComponent implements OnInit {
  @Input() isDetail: boolean;
  isLoading: boolean;
  ReviewId: string;
  itemObj: Promotion;
  apiPath: string;
  pathUrl: string;
  currentLang: string;
  constructor(
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _reviewManageService: ReviewManageService,
    private _translateService: TranslateService,
  ) {
    this.pathUrl = this._reviewManageService.pathUrl;
    this.apiPath = this._reviewManageService.apiUrl;
  }

  ngOnInit(): void {
    this.isDetail = this._route.snapshot.paramMap.get('id') ? true : false;
    if (this.isDetail) {
      console.log('isDetail');
      this.ReviewId = this._route.snapshot.paramMap.get('id');
      this.getData(this.ReviewId);
    } 
    // else {
    //   console.log('create');

    // }
    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });
  }

  getData(ReviewId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.GetDataById(this.apiPath, ReviewId).subscribe((res) => {
        this.itemObj = res.data.resultData[0];
        console.log(res);
        console.log(this.itemObj);
        
        if (this.itemObj) {
          console.log('buildForm');
          // this.buildForm(this.itemObj);

          console.log(this.itemObj.isActive);
          this.isLoading = false;
        }
      }, reject);
    });
  }
}
