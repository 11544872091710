<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <app-search-panel
      [forCompanentName]="componentName"
      [pathUrl]="pathUrl + '/create'"
      [isGridView]="isGridView"
      [isForGroupTemplate]="isForGroupTemplate"
      [isShowSortByGroup]="false"
      (callDataFromAPI)="initData($event)"
      (ActiveStatusFilter)="ActiveStatusFilter($event)"
    ></app-search-panel>

    <section>
      <div class="card">
        <div class="card-body">
          <div>
            <table [dtOptions]="dtOptions" class="table" datatable>
              <thead>
                <tr class="text-center" style="width: 100% !important">
                  <th class="text-center" width="4%">No.</th>
                  <th class="text-center" width="10%">Code</th>
                  <th class="text-center" width="28%">Title</th>
                  <th class="text-center" width="10%">Email</th>
                  <th class="text-center" width="15%">Status</th>
                  <th class="text-center" width="10%">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr class="w-100" *ngIf="isLoading">
                  <td class="text-center" *ngFor="let num of [].constructor(6)">
                    <div class="skeleton-loading"></div>
                  </td>
                </tr>

                <tr *ngIf="itemList.length < 1 && !isLoading">
                  <td colspan="100%" class="text-center">
                    <p>{{ "ViewTable.NoItemPreview" | translate }}</p>
                  </td>
                </tr>

                <tr *ngFor="let item of itemList; let ind = index">
                  <td class="text-center">{{ ind + 1 }}</td>
                  <td class="text-center">
                    <a [routerLink]="[detailPathUrl + '/' + item.id]">
                      {{ item.repoveCode }}
                    </a>
                  </td>
                  <td>
                    <div class="d-block">
                      <p class="mb-0">
                        {{
                          item.islegalEntity
                            ? item.businessName
                            : item.customerFirstName +
                              " " +
                              item.customerLastName
                        }}
                      </p>
                      <small
                        class="mb-0"
                        *ngIf="
                          !(item.isHeadBranch != true && item.branchNumber == 0)
                        "
                      >
                        ({{
                          item.isHeadBranch
                            ? ("Customer.headOffice" | translate)
                            : ("Customer.branch" | translate) +
                              ": " +
                              item.branchNumber.toString().padStart(5, "0") + ", " + item.branchName
                        }})</small
                      >
                    </div>
                  </td>
                  <td>{{ item.contactEmail ? item.contactEmail : "-" }}</td>
                  <td class="text-center">
                    <div class="form-group my-auto">
                      <div ngbDropdown>
                        <a
                          ngbDropdownToggle
                          href="javascript:void(0);"
                          class="d-flex justify-content-center text-secondary"
                          id="dropdownBrowserState"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          [ngClass]="{
                            'text-success': item.isActive,
                            'text-danger': !item.isActive
                          }"
                        >
                          <div>
                            {{
                              (item.isActive
                                ? "Status.Active"
                                : "Status.Inactive"
                              ) | translate
                            }}
                          </div>
                          <i
                            data-feather="chevron-down"
                            class="text-dark cursor-pointer ml-50 my-auto"
                          ></i>
                        </a>
                        <div
                          ngbDropdownMenu
                          class="dropdown-menu-left"
                          aria-labelledby="dropdownBrowserState"
                        >
                          <a
                            ngbDropdownItem
                            *ngIf="item.isActive"
                            class="d-flex align-items-center"
                            (click)="openIsActiveModal(item, false)"
                          >
                            <i data-feather="eye-off" class="mr-50"></i>
                            {{ "Status.Inactive" | translate }}
                          </a>
                          <a
                            ngbDropdownItem
                            *ngIf="!item.isActive"
                            class="d-flex align-items-center"
                            (click)="openIsActiveModal(item, true)"
                          >
                            <i data-feather="eye" class="mr-50"></i>
                            {{ "Status.Active" | translate }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class=" d-flex justify-content-center">
                      <a
                      class="btn btn-icon btn-icon rounded-circle btn-outline-success mr-50"
                      [routerLink]="[detailPathUrl + '/' + item.id]"
                      ><i data-feather="eye"></i
                    ></a>
                    <a
                      class="btn btn-icon btn-icon rounded-circle btn-outline-warning mr-50"
                      [routerLink]="[editPathUrl + '/' + item.id]"
                      ><i data-feather="edit"></i
                    ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </section>
  </div>
</div>
