import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ApiService } from 'app/main/service/api.service';
import { FlatpickrOptions } from 'ng2-flatpickr';

@Component({
  selector: 'app-search-panel-review-manage',
  templateUrl: './search-panel-review-manage.component.html',
  styleUrls: ['./search-panel-review-manage.component.scss']
})
export class SearchPanelReviewManageComponent implements OnInit {
  @Input() pathUrl: string;
  @Input() forCompanentName: string;
  @Input() notShowCreateBtn: boolean = false;
  @Input() notForContentMode: boolean = false;
  @Input() isForGroupTemplate: boolean;
  @Input() isShowSortByGroup: boolean = false;

  @Input() isShowRoleSet: boolean = false;
  @Output() callSearch: EventEmitter<any> = new EventEmitter();
  // @Output() callReset: EventEmitter<any> = new EventEmitter();

  @ViewChild('searchbar') searchBar: ElementRef;
  @ViewChild('activeFil') activeFil: ElementRef;
  @ViewChild('item') item: ElementRef;
  @ViewChild('ReviewDateFrom') ReviewDateFrom: any;
  @ViewChild('EndDateValue') EndDateValue: any;
  ItemList : any[] = [];

  constructor(
    private _apiService: ApiService
  ) {
    this.getItem();
  }

  startDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y ',
    // enableTime: true
  }

  endDateOptions: FlatpickrOptions = {
    dateFormat: 'd/m/Y ',
    // enableTime: true
  }

  // defaultDate = new Date();

  ResetSearch(): void {
    console.log('ResetSearch');
    this.searchBar.nativeElement.value = '';
    this.activeFil.nativeElement.value = 'all';
    this.item.nativeElement.value = 'all';
    this.ReviewDateFrom.flatpickr?.setDate(null);
    this.EndDateValue.flatpickr?.setDate(null);

    this.callSearch.emit();
  }

  SubmitSearch(): void {
    console.log('SubmitSearch');
    let obj: any = {};

    const activeStr = this.activeFil.nativeElement.value;
    if (activeStr === 'active') {
      obj.isActive = true;
    } else if (activeStr === 'inactive') {
      obj.isActive = false;
    }

    function adjustDate(dateValue: Date, hours: number, minutes: number, seconds: number) {
      const localDate = new Date(dateValue.getTime() - dateValue.getTimezoneOffset() * 60000);
      localDate.setHours(hours);
      localDate.setMinutes(minutes);
      localDate.setSeconds(seconds);
      return new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
    }

    const ReviewDateFromValue = this.ReviewDateFrom.flatpickr?.selectedDates[0];
    const EndDateValueValue = this.EndDateValue.flatpickr?.selectedDates[0];

    if (ReviewDateFromValue) {
      obj.ReviewDateFrom = adjustDate(ReviewDateFromValue, 0, 0, 0);
    }

    if (EndDateValueValue) {
      obj.ReviewDateTo = adjustDate(EndDateValueValue, 23, 59, 59);
    }
    //ทดสอบ obj
    this.searchBar.nativeElement.value != '' && (obj.Message = this.searchBar.nativeElement.value);
    this.item.nativeElement.value != 'all' && (obj.ItemId = this.item.nativeElement.value);


    console.log('obj',obj);

    this.callSearch.emit(obj);
    
  }

  getDateFlatpickrValue(): void {
    
  }

  ngOnInit(): void {
    console.log(this.pathUrl);
  }

  getItem(): void{
    // item?IsActive=true&verbose=false&IncludeMediaURL=true
    let params = {
      IsActive: true,
      verbose: false,
      IncludeMediaURL: true,
      IsDelete: false
    }
    this._apiService.GetAllData('item',params).subscribe((res) => {
      console.log(res);
      
      this.ItemList = res.data.resultData;
    });
  }
}
