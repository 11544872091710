export const locale = {
  lang: 'en',
  data: {
    autoForm: {
      typeAnyKey: 'Type any key......',
    },
    Alert: {
      lang: 'This field is not yet specified in : ',
      Success: 'Success',
      Confirm: 'Confirm',
      Error: 'Error',
      SomethingWentWrong: 'Something went wrong',
      Invalid: 'Please complete the required information.',
      Approved: 'Approved',
      ApproveFail: 'Approve Fail',
      PleaseCancelVoid: 'Please cancel or void related documents first',
      GetReceiptSuccess: 'Get Receipt Success',
      VoidGotReceiptSuccess: 'Void got receipt success',
      CancelDocumentSuccess: 'Cancel Document Success',
      VoidDocumentSuccess: 'Void Document Success',
      SignUpComplete: 'Sign Up Complete',
      VerifyEmailSendSuccessfully: 'Verify Email Send Successfully',
      EmailNotExist: 'Email Not Exist',
      PleaseTryAgain: 'Please try again',
      FormValidationFailed: 'Form Validation Failed',
      ImageUploadSuccessfully: 'Image Upload Successfully',
      EmailResetPasswordHasSent: 'Email reset password link has been sent',
      PasswordHasBeenReset: 'Password has been reset',
      max: 'Max',
      files: 'files',
      maximize: 'The number that can be added has been reached.',
      ChooseFiles: 'Choose Files',
      Cancel: 'Cancel',
      CodeQO: 'Quotation : {{docCode}}',
      GoToSO: 'Go to Sale Order',
      CodeSO: 'Sale Order : {{docCode}}',
      GoToIV: 'Go to Invoice',
      CodeIV: 'Invoice : {{docCode}}',
      GoToRC: 'Go to Receipt',
      CodeRC: 'Receipt : {{docCode}}',
      GoToTX: 'Go to TAX Invoice',
      CodeTX: 'TAX Invoice : {{docCode}}',
      CodePR: 'Purchase Request : {{docCode}}',
      GoToPO: 'Go to Purchase Order',
      CodePO: 'Purchase Order : {{docCode}}',
      GoToGR: 'Go to Goods Receive',
      CodeGR: 'Goods Receive : {{docCode}}',
    },
    Modal: {
      ConfirmTheCancellation: 'Confirm the cancellation ',
      AreYouSureToCancellation:
        'Are you sure you want to cancellation {{title}} No.{{docCode}} ?',
      ConfirmTheCreateTaxInvoice: 'Confirm the create Tax Invoice',
      AreYouSureToCreateTaxInvoice:
        'Are you sure to create Tax Invoice from Invoice ?',
      CancelCreateSeparateIV: 'Cancel Separate Invoice Creation',
      AreYouSureToCancelCreateSeparateIV:
        'Are you sure to cancel the creation of a separate invoice ?',
      CancelCreatePR: 'Cancel Purchase Request Creation',
      AreYouSureToCancelCreatePR:
        'Are you sure to cancel the creation of a purchase request ?',
      CancelCreateSeparateGR: 'Cancel Separate Goods Receive Creation',
      AreYouSureToCancelCreateSeparateGR:
        'Are you sure to cancel the creation of a separate goods receive ?',
      CancelEditPR: 'Cancel to Edit Purchase Request',
      AreYouSureToCancelEditPR:
        'Are you sure to cancel editing the purchase request ?"',
      SaveDraftPR: 'Save Purchase Request Draft',
      AreYouSureToSaveDraftPR: 'Are you sure to save purchase request draft ?',
      CreateSeparateIV: 'Create Separate Invoice',
      AreYouSureToCreateSeparateIV: 'Are you sure to create separate invoice ?',
      CreateSeparateGR: 'Create Separate Goods Receive',
      AreYouSureToCreateSeparateGR:
        'Are you sure to create separate goods receive ?',
      CreatePR: 'Create Purchase Request',
      AreYouSureToCreatePR: 'Are you sure to create purchase request ?',
      ConfirmGetReceipt: 'Confirm Receipt',
      AreYouSureToGetReceipt: 'Are you sure you want to get the receipt ?',
      ConfirmVoidGotReceipt: 'Confirm to Void Got Receipt',
      AreYouSureToVoidGotReceipt: 'Are you sure you want to void got receipt ?',
      ApprovePO: 'Approve Purchase Order',
      AreYouConfirmToApprovePO:
        'Are you confirm to approve this Purchase Order ?',
      CancelManagePage: 'Unsaved Changes',
      CancelManage: 'Cancel Manage ',
      PressSubmitToCancel: 'Are you sure to unsaved changes of these data',
      ConfirmEditPage: 'Confirm Edit ',
      ConfirmEdit: 'Confirm Edit ',
      AreYouSureToEditPage: 'Are you sure to editing ',
      AreYouSureToEdit: 'Are you sure to editing ',
      ConfirmCreate: 'Confirm Saving Data',
      AreYouSureToCreate: 'Are you sure to save this changes?',
      ConfirmEditNewsLandingPage: 'Confirm Edit News Landing Page',
      AreYouSureToEditNewsLandingPage:
        'Are you sure to editing News Landing Page',
      CancelManageNewsLandingPage: 'Cancel Manage News Landing Page',
    },
    ErrorMessage: {
      QuantityMustNotBeNegative:
        '*Quantity of item/service must not be negative.',
      QuantityMustNotBeNegativeOrExceedTheRemaining:
        '*Quantity of products/services must not exceed the remaining quantity or be negative.',
      ProductPriceMustNotBeNegative: '*Product price must not be negative.',
      ProductPriceMustNotBeNegativeOrExceedTheOriginalAmount:
        '*Product price must not exceed the original amount or be negative.',
      DiscountMustNotBeNegativeOrExceedTheProductPrice:
        '*Discount amount must not exceed the product price or be negative.',
      TotalDiscountMustNotBeNegative:
        '**Total discount amount must not be negative.',
      TotalDiscountMustNotExceed: '**Total discount amount must not exceed',
      AndMustNotBeNegative: 'and must not be negative.',
      GrandTotalAmountMustNotBe0Baht:
        '**Grand total amount must not be less than 0 Baht.',
      QuantityGreaterThanRemaining:
        '*Quantity of item/service is greater than the remaining.',
      DescNotFirstRow:
        'Description row cannot be the top of the first item/service in the row.',
      PleaseSelectItem: '*Please select item fisrt.',
    },
    Handle: {
      Name: 'Name is required',
      Exceed: 'Cannot exceed',
      Char: 'characters',
      Group: 'Group is required',
      Title: 'Title is required',
      SubTitle: 'Subtitle is required',
      Category: 'Category is required',
      Class: 'SubClass is required',
      classId: 'Class is required',
      Detail: 'Detail is required',
      ExceedChar: 'Name cannot exceed 50 characters.',
      UserName: 'Username is required',
      ExceedCharUserName: 'Username cannot exceed 100 characters.',
      Email: 'Email is required',
      EmailSyntax: 'Email syntax is incorrect.',
      LastName: 'Last Name is required',
      ExceedCharLastName: 'Last Name cannot exceed 100 characters.',
      ExceedCharName: 'Name cannot exceed 100 characters.',
      Description: 'Description is required',
      DescriptionAllLanguage: 'Description is required in all language.',
      DetailAllLanguage: 'Detail is required in all language.',
      SingleImage: 'Image is required',
      MutipleFile: 'File is required',
      FirstLetterInvalid: 'Special character not allow at first charactor.',
      CodeName: 'Promotion name required',
      RedeemCode: 'Redeem Code is required',
      Amount: 'Amount is required',
      Discount: 'Discount is required',
      AmountPerCustomer: 'Amount per customer is required',
      StartDate: 'Start date is required',
      EndDate: 'End date is required',
      noWhitespaceValidator: 'No whitespace allowed',
    },
    General: {
      Save: 'Save',
      PressToCancel: 'Press the confirm button to cancel.',
      CancelAction: 'Cancel action related to ',
      CancelCreate: 'Cancel to create ',
      Cancel: 'Cancel ',
      Confirm: 'Confirm ',
      General: 'General',
      Default: 'Default',
      Deprecated: 'Deprecated',
      Detail: 'Detail',
      Date: 'Date',
      Total: 'Total',
      Buy: 'Buy',
      Baht: 'Baht',
      Sell: 'Sell',
      OrderHistory: 'Order History',
      Copy: 'Copy',
      Gender: 'Gender',
      Check: 'Check',
      Quotation: 'Quotation',
      AreYouConfirm: 'Are you confirm ',
      toCreate: 'to create ',
      toEdit: 'to edit ',
      Home: 'Home',
      Admin: 'Admin',
      Profile: 'Profile',
      ACLManage: 'Manage ACL',
      UserManage: 'Manage User',
      RolesManage: 'Manage Roles',
      BannerManage: 'Manage Banner',
      BrandManage: 'Manage Brand',
      Brand: 'Brand',
      NewsManage: 'Manage News',
      Item: 'Item',
      ContentManage: 'Manage Content',
      ItemManage: 'Manage Item',
      ItemSetManage: 'Manage Item Set',
      GroupManage: 'Manage Group',
      CategoryManage: 'Manage Category',
      SubCategoryManage: 'Manage SubCategory',
      ClassManage: 'Manage Class',
      SubClassManage: 'Manage SubClass',
      Purchasing: 'Purchasing',
      PurchaseOrder: 'Purchase Order',
      ControlPanel: 'Control Panel',
      RelatedDocument: 'Related Document',
      Create: 'Create',
      Edit: ' Edit',
      Delete: 'Delete',
      Add: 'Add ',
      Export: 'Export',
      Manage: 'Manage',
      PDPA: 'PDPA',
      Event: 'Event',
      Search: 'Search',
      SortBy: 'Sort By',
      Status: 'Status',
      All: 'All',
      Draft: 'Draft',
      Approve: 'Approve',
      Bin: 'Bin',
      Previous: 'Previous',
      Next: 'Next',
      Change: 'Change',
      Settings: 'Settings',
      Group: 'Group',
      Category: 'Category',
      SubCategory: 'Sub-Category',
      Class: 'Class',
      SubClass: 'Sub-Class',
      ItemManagement: 'Item',
      Customer: 'Customer',
      Sale: 'Sale',
      Account: 'Customer',
      Trash: 'Trash',
      Document: 'Documents',
      RevenueDocument: 'Revenue Documents',
      ExpensesDocument: 'Expenses Documents',
      Revenue: 'Revenue',
      Exoenses: 'Exoenses',
      ViewAll: 'View All',
      Stock: 'Stock',
      StockManage: 'Stock Manage',
      Reset: 'Reset',
      Back: 'Back',
      Expired: '(Expired)',
      DocumentInfo: 'Document',
      SlipInfo: 'Slip',
      PaymentInfo: 'Payment',
      HistoryInfo: 'History',
      Percent: 'Percent',
      Remain: 'Remain',
      InStock: 'Stock',
      Original: 'Original',
      Product: 'Product',
      OurTechnology: 'Our Technology',
      About: 'About',
      NewsEvent: 'News & Event',
      LandingPage: 'Landing Page',
      ConsumerReview: 'Consumer Review',
      CompanyVideo: 'Company Video',
      Contact: 'Contact',
      PrivacyPolicy: 'Privacy & Policy',
      CookiePolicy: 'Cookie Policy',
      TermsCondition: 'Terms & Condition',
      SellerManage: 'Seller Management Regulation',
      ShoppingCart: 'Shopping Cart',
      SignIn: 'Sign In',
      RoleSet: 'Role',
      ValueWaitToBePaid: 'Value waiting to be paid',
      ValueWaitToPaid: 'Value waiting to be paid',
      ReceivePayment: 'Receive Payment',
      Payment: 'Payment',
      SystemSettings: 'System Settings',
      PrintShippingLabel: 'Print parcel cover sheet',
      DeliveryProvider: 'Delivery Provider',
      Cod: 'Cash on (Cod)',
      TransferPayment: 'Transfer payment',
      ManageCode: 'Manage Code',
      PromotionCode: 'Promotion Code',
      PromotionCodeManage: 'Manage Promotion Code',
      ConfirmReceivePayment: 'Confirm receipt of money on destination',
      AreYouSureToReleaseCOD: 'Are you sure to release products with COD payment? \nIf canceled, this transaction will be canceled immediately.',
      ConfirmCancelOrder: 'Confirm to cancel order',
      ConfirmCancelOrdertitleDetail: 'Do you confirm to cancel this transaction?',
      Success : 'Success',
      CancelOrderSuccess: 'Cancel order success',
      PaymentType: 'Payment Type',
      CashOnDelivery: 'Cash on Delivery',
      BankTransfer: 'Bank Transfer',
      ManageReview: 'Manage Review',
      Review: 'Review',
      ItemName: 'Item Name',
      FromDate: 'From Date',
      ToDate: 'To Date',
    },
    Form: {
      Name: 'Name',
      Barcode: 'Barcode',
      Description: 'Description',
      Detail: 'Detail',
      Status: 'Status',
      Quantity: 'Quantity',
      beforeDiscountPrice: 'Before Discount Price',
      purchasePrice: 'Purchase Price',
      Price: 'Price',
      SellingPrice: 'Selling Price',
      Group: 'Group',
      Images: 'Images',
      Cancel: 'Cancel',
      Confirm: 'Confirm',
      Submit: 'Submit',
      RefText1: 'ref text',
      Title: 'Title',
      Subtitle: 'Subtitle',
      Details: 'Details',
      ButtonCaption: 'Button Caption',
      ButtonURL: 'Button URL',
      Files: 'Files',
      FieldRequired: 'This field is required!',
      Attract: 'Attract Files',
      Cover: 'Cover',
      SaveDraft: 'Save Draft',
      ApproveDoc: 'Approve',
      CreateGRFormPO: 'Create Goods Receive from Purchase Order',
      IsShowing: 'Show on Website',
      IsBestSeller: 'Best Seller',
      IsNewProduct: 'New Item',
      EnterDescription: 'Enter Description...',
      EnterName: 'Enter Name...',
      BeginTypeHere: 'Begin typing here...',
    },
    Status: {
      Active: 'Active',
      Inactive: 'Inactive',
      Published: 'Published',
      Unpublished: 'Unpublished',
      Publish: 'Publish',
      Unpublish: 'Unpublish',
      Draft: 'Draft',
      AwaitApproval: 'Await Approval',
      Accepted: 'Accepted',
      AwaitPayment: 'Await Payment',
      Paid: 'Paid',
      Expired: 'Expired',
      Overdue: 'Overdue',
      Voided: 'Voided',
      Cancel: 'Cancel',
      GotReceipt: 'Got Receipt',
      Pending: 'Pending',
      Complete: 'Complete',
      WaitingAdjust: 'Waiting Adjust',
    },
    Sort: {
      AscChar: 'A-Z',
      DescChar: 'Z-A',
      Newest: 'Newest',
      Oldest: 'Oldest',
    },
    Menu: {
      Document: {
        QO: 'Quotation (QO)',
        SO: 'Sale Order (SO)',
        IV: 'Invoice (IV)',
        TX: 'TAX Invoice (TX)',
        RC: 'Receipt (RC)',
        PR: 'Purchase Request (PR)',
        PO: 'Purchase Order (PO)',
        GR: 'Goods Receive (GR)',
        CNT: 'Credit Note (CNT)',
        DBN: 'Debit Note (DBN)',
      },
      Profile: 'Profile',
      Settings: 'Settings',
      Logout: 'Logout',
      FrontendControlPanel: 'Frontend Control Panel',
    },

    Settings: {
      General: 'General',
      BankAccount: 'Bank Account',
      DocumentInfo: 'Document Info',
      Copied: 'Copied!',
      CopyBankAccountToClipboard: 'Bank Account Number was copied to clipboard.',
      Modal: {
        GeneralConfirmTitle: 'Confirm to save Document info',
        GeneralConfirmDetail: 'Are you sure to saving Document info with these data?',
        SetDefaultBankAccountTitle: 'Confirm to set Default Bank Account',
        SetDefaultBankAccountDetail: 'Are you sure to saving this Bank Account to Default?',
        RemoveDefaultBankAccountTitle: 'Confirm to remove Default Bank Account',
        RemoveDefaultBankAccountDetail: 'Are you sure to remove this Default Bank Account?',
      },
      DefaultBankAccount: 'Default Bank Account',
      AllBankAccount:'All Bank Account',
      Toast: {
        Success: 'Success',
        Error: 'Error',
        SuccessDefaultBankAccountDetail: 'Set default bank account completed!',
        SuccessRemoveDefaultBankAccountDetail: 'Remove default bank account completed!',
      },
      Branch: 'Branch'
    },

    Gender: {
      Male: 'Male',
      Female: 'Female',
      NotSpecified: 'Not Specified',
    },
    User: {
      Username: 'Username',
      Name: 'Name',
      Prefix: 'Prefix',
      FirstName: 'First Name',
      LastName: 'Last Name',
      Gender: 'Gender',
      Email: 'Email',
      Phone: 'Phone',
      Role: 'Role',
      Company: 'Company',
      Organization: 'Organization',
      Branch: 'Branch',
      Department: 'Department',
      Position: 'Position',
      CreateOn: 'Create On',
      General: 'General',
      Security: 'Security',
      AccountSettings: 'Account Settings',
      OldPassword: 'Old Password',
      NewPassword: 'New Password',
      ReNewPassword: 'Re-new Password',
      IsActive: 'Active',
      IsVerify: 'Verify',
      UserStatus: 'User Status',
      UserDetail: 'User Detail',
      Active: 'Active',
      InActive: 'Inactive',
      SelectRole: 'Select Role',
      SelectPrefix: 'Select Prefix',
      SelectGender: 'Select Gender',
      Cover: 'Cover',
    },

    Customer: {
      businessName: 'Business name',
      businessNameError: 'Business Name is required',
      contactInfo: 'Contact Information',
      accountsReceivableRecord: 'Accounts Receivable Record',
      accountsPayableRecord: 'Accounts Payable Record',
      thaiName: 'Thai Name',
      contactName: 'Contact Name',
      individualOrLegalEntity: 'Individual or Legal Entity',
      JuristicPerson: 'Juristic Person',
      OrdinaryPerson: 'Ordinary Person',
      requiredError: 'This field is required',
      selectCountry: 'Select Country',
      country: 'Thailand',
      countryOther: 'Other Countries',
      idNumber: 'TAX ID/ID Card',
      contactType: 'Contact Type',
      officeType: 'Office Type',
      noOffice: 'No Office',
      headOffice: 'Head Office',
      office: 'Office',
      noCountry: 'None',
      branch: 'Branch',
      search: 'Search',
      branchCodeLabel: 'Branch Code',
      branchCodePlaceholder: 'Enter branch code',
      branchNameLabel: 'Branch Name',
      branchNamePlaceholder: 'Enter branch name',
      officeTypeLabel: 'Select organization type',
      pleaseSpecify: 'Please specify',
      nameLabel: 'Name',
      contactNameLabel: 'Contact Name',
      debtorLabel: 'Accounts Payable',
      creditorLabel: 'Accounts Receivable',
      personalInfo: 'Personal Information',
      primaryContact: 'Primary Contact',
      specifyContactInfo: 'Specify your basic contact information',
      firstName: 'First Name',
      lastName: 'Last Name',
      nickname: 'Nickname',
      ContactCode: 'Contact Code',
      UplineCode: 'Upline Code',
      FullName: 'Name',
      position: 'Position',
      nameRequiredError: 'First Name is required',
      lastNameRequiredError: 'Last Name is required',
      nicknameRequiredError: 'Nickname is required',
      positionRequiredError: 'Position is required',
      contactChannelInfo: 'Contact Channel Information',
      centralContactInfo: 'Central Contact Information',
      indirectContact: 'In case you cannot be contacted directly',
      centralPhoneNumber: 'Central Phone Number',
      faxNumber: 'Fax Number',
      centralEmail: 'Central Email',
      syntaxError: 'Mistakes in using the language',
      website: 'Website',
      gbPhoneRequiredError: 'Central Phone Number is required',
      gbFaxRequiredError: 'Fax Number is required',
      gbEmailRequiredError: 'Central Email is required',
      gbSiteRequiredError: 'Website is required',
      office_type: 'Office Type is required',
      debtorRq: 'Accounts Receivable Type is required',
      creditorRq: 'Accounts Payable Type is required',
      phone: 'Phone Number',
      email: 'Email',
      emailRequiredError: 'Email is required',
      phoneRequiredError: 'Phone Number is required',
      fillOutCompletely: 'Please fill out the information completely.',
      invalidAllZeroBranchNumber: 'Cannot use 00000 as branch number.',
      branchNameRequiredError: 'Branch Name is required.',
      duplicateTaxID:
        'This number is already used for another tax invoice address.',
        BuyHistory: 'Buy History',
        SellHistory: 'Sell History',
    },
    Address: {
      AlertAddr: 'Please complete and check for accuracy.',
      addrInfo: 'Address Infomation',
      registeredAddress: 'Tax Invoice Address',
      mailingAddress: 'Shipping Address',
      contactPerson: 'Contact Person',
      address: 'Address',
      addressDetails: 'Number - Alley/Lane, Road or District',
      addressDetailsNote: 'Please specify',
      country: 'Country',
      copy: 'Same as Tax Invoice Address',
      thailand: 'Thailand',
      india: 'India',
      thailand2: 'Thailand (2)',
      province: 'Province',
      bangkok: 'Bangkok',
      startingPoint: 'Starting Point',
      district: 'District',
      subdistrict: 'Subdistrict',
      postalCode: 'Postal Code',
      cNameRequiredError: 'Contact Person is required.',
      cAddressRequiredError: 'Address is required.',
      cLocalRequiredError: 'Local area is required.',
      cCityRequiredError: 'Province is required.',
      cDistrictRequiredError: 'District is required.',
      cSubDistrictRequiredError: 'Subdistrict is required.',
      cCountyRequiredError: 'Province is required.',
      cPostcodeRequiredError: 'Postal code is required.',
      cPhoneRequiredError: 'Contact Phone is required',
      selectCountry: 'Select Country',
      selectProvince: 'Select Province',
      selectDistrict: 'Select District',
      selectSubDistrict: 'Select Sub District',
      isDefault: 'Default',
      setAsDefault: 'Set As Default',
      contactPhone: 'Contact Phone',
      addNewAddress: 'Add New Address',
      editSendShippingAddress: 'Edit Shipping Address for sending goods',
      editReceiveShippingAddress: 'Edit Shipping Address for Receiving goods',
      cancelEditAddress: 'Cancel Edit Address'
    },
    Bank: {
      reftext: 'Bank Information',
      bankName: 'Bank Name',
      bankGroupRequired: 'Bank group is required',
      branch: 'Branch',
      bankAddressRequired: 'Bank address is required',
      accountNumber: 'Account Number',
      bankIdRequired: 'Bank ID is required',
      accountName: 'Account Name',
      bankNameRequired: 'Bank name is required',
      accountType: 'Account Type',
      savings: 'Savings',
      current: 'Current',
      bankTypeRequired: 'Bank type is required',
    },
    Invoice: {
      item: 'Invoice Settings',
      sellPaymentSettingLabel:
        'Set Payment According to Issued Invoices (Sales)',
      sellPaymentSettingOptions: {
        settingByCompany: 'Set according to company settings',
        afterInvoiceXDays: 'X days after invoice issuance',
        endOfMonthInvoiceDate: 'End of month of invoice issuance',
        endOfMonthNextMonth: 'End of next month',
      },
      buyPaymentSettingLabel:
        'Set Payment According to Recorded Expenses/Received Invoices (Purchases)',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      sellreq: 'set_sell is required.',
      buyreq: 'set_buy is required.',
    },
    iTem: {
      Group: 'Group is parents of Category',
      Validate: ' * Please choose',
      Cate: 'Category is parents of Sub-category',
      subCate: 'Sub-category is sub detail of Category',
      Number: ' * Number only',
      Media: 'Files Limit',
      MediaLimit: ' * Need to be less than 5 items',
    },

    Document: {
      QuotationDraft: 'Quotation Draft',
      Quotation: 'Quotation',
      SaleOrder: 'Sale Order',
      Invoice: 'Invoice',
      Invoice_Tax: 'Invoice/Tax Invoice',
      Tax: 'TAX Invoice',
      DebitNote: 'Debit Note',
      CreditNote: 'Credit Note',
      ReturnSlip: 'Return Slip',
      Receipt: 'Receipt',
      IV_TX_RE: 'Invoice/Tax Invoice/Receipt',
      PurchaseRequest: 'Purchase Requisition',
      PurchaseOrder: 'Purchase Order',
      SeparateGR: 'Create Separate Goods Receive',
      SeparateIV: 'Create Separate Invoice',
      GoodsReceive: 'Goods Receive',
      goodRecieve: 'Goods Receive',
      fullValue: 'Full value',
      writtenOff: 'Amount written off from this doc',
    },
    BreadCrumbs: {
      Manage: {
        Brand: 'Brand',
        Group: 'Group',
        Category: 'Category',
        SubCategory: 'Sub Category',
        Class: 'Class',
        SubClass: 'Sub Class',
        Item: 'Item',
        ItemSet: 'Item Set',
        Customer: 'Manage Customer',
        User: 'Manage Users',
        Role: 'Manage Role',
        Trash: 'Trash',
        BankAccount: 'Bank Account',
      },
      CMS: {
        Banner: 'Manage Banner',
        banner: ' Banner',
        News: 'Manage News & Event',
        news: ' News & Event',
        PDPA: 'Manage Privacy Policy Page',
        PrivacyPolicy: ' Privacy Policy Page',
        pdpa: ' PDPA',
        Event: 'Manage Event',
        event: ' Event',
        cookiePolicy: 'Manage Cookie Policy Page',
        CookiePolicy: ' Cookie Policy',
        termsCondition: 'Manage Terms Condition Page',
        TermsCondition: ' Terms & Condition',
        shoppingCart: 'Manage Shopping Cart',
        ShoppingCart: ' Shopping Cart',
        signIn: 'Manage Sign In',
        SignIn: ' Sign In',
        Contact: 'Manage Contact Page',
        contact: ' Contact',
        About: 'Manage About Page',
        about: ' About',
        Home: 'Manage Home Page',
        home: ' Home',
        Product: 'Manage Product Page',
        ProductLanding: ' Product',
        LandingPage: 'Manage News Landing Page',
        landingPage: ' Landing Page',
        CreateNews: 'Create News & Event',
        SellerManageRegulation: 'Seller Management Regulation Page',
        SellerManagementRegulation: ' Seller Management Regulation',

        NewsEvent: 'Manage News and Event Page',
        newsEvent: ' News and Event',
        NewsEventLandingPage: 'News and Event Landing Page',

        CustomerReview: 'Manage Customer Review Page',
        customerReview: 'Customer Review',
        CustomerReviewLandingPage: 'Customer Review Landing Page',
        
        howToBuy: 'How To Buy',
        HowToBuy: 'Manage How To Buy Page',
        HowToBuyLandingPage: 'How To Buy Landing Page',

        ingredients: 'Ingredients',
        Ingredients: 'Manage Ingredients Page',
        IngredientsLandingPage: 'Ingredients Landing Page',

        suitableFor: 'Suitable For',
        SuitableFor: 'Manage Suitable For Page',

        faq: 'FAQ',
        Faq: 'Manage FAQ',

        howToUse: 'How To Use',
        HowToUse: 'Manage How To Use Page',
        HowToUseLandingPage: 'How To Use Landing Page',

        NewsReview: 'Manage Consumer Review Page',
        newsReview: ' Consumer Review',
        NewsReviewLandingPage: 'Consumer Review Landing Page',

        companyVideo: 'Manage Company Video Page',
        CompanyVideo: ' Company Video',
        CompanyVideoLandingPage: 'Company Video Landing Page',

        newsVideo: 'Manage Company Video Page',
        NewsVideo: ' Company Video',
        NewsVideoLandingPage: 'Company Video Landing Page',
      },
    },
    FormQuotation: {
      SelectCustomer: 'Select Contact',
      SelectStockType: 'Select Stock Type',
      SelectItem: 'Select Product/Service',
      SelectShippingAddress: 'Select Shipping Address',
      SelectReceiveAddress: 'Select Receive Address',
      SelectBillingAddress: 'Select Billing Address',
      SelectIssueBillingAddress: 'Select Issue Tax Invoice Address',
      SelectPaymentMethod: 'Select ฺPayment Method',
      CreateDate: 'Issue Date',
      ExprieDate: 'Valid Until',
      DocCode: 'Document Code',
      DocRef: 'Document Ref.',
      SaleRef: 'Sale Reference',
      Price: {
        Title: 'Price',
        Select: 'Select Tax',
        Exclude: 'Exclude Tax',
        Include: 'Include Tax',
      },
      AddressTax: 'Tax Invoice Address',
      AddressShipping: 'Shipping Address',
      AddressReceive: 'Receive Address',
      AddressBilling: 'Billing Address',
      AddressIssueBilling: 'Issue Tax Invoice Address',
      Tel: 'Tel.',
      RecieveMoreProduct: {
        Label: 'Did you receive more products',
        Option1: 'Do not receive more products',
        Option2: 'Receive more products',
        Description1: "The product's quantity will not change",
        Description2: "The product's quantity will increase",
        Reason: 'Reason for issuing credit note',
      },
      SendMoreProduct: {
        Label: 'Changes in Debit Note',
        Option1: 'Do not send products',
        Option2: 'Send more products',
        Description1: "The product's quantity will not change",
        Description2: "The product's quantity will decrease",
        Reason: 'Reason for issuing debit note',
      },
      DescriptionOfReason: 'Description of reason',
    },
    PromotionForm: {
      PromotionName: 'Promotion name',
      RedeemCode: 'Redeem code',
      Amount: 'Amount',
      UnlimitedCode: 'Unlimited code',
      Discount: 'Discount',
      PercentDiscount: 'Percent discount',
      NumberCanReuse: 'Codes that customers can reuse',
      UnlimitedReusable: 'Unlimited reusable discounts',
      StartDate: 'Start date',
      EndDate: 'End date',
      DiscountNoExpire: 'The discount never expires',


    },
    SearchPanel: {
      General: {},
      Document: {
        CustomerName: 'Contact Name',
        DocCode: 'Document Code',
        CreateDateFrom: 'From create date',
        CreateDateTo: 'To create date',
        PriceFrom: 'From Grand Total',
        PriceTo: 'To Grand Total',
      },
    },
    ControlPanel: {
      AreYouSureToCancel: 'Are you sure to cancel ',
      AreYouSureTo: 'Are you sure to ',
      Confirm: 'Confirm ',
      Cancel: 'Cancel ',
      Create: 'create ',
      Edit: 'edit ',
    },
    Stock: {
      AreYouSureToCancel: 'Are you sure to cancel ',
      AreYouSureTo: 'Are you sure to ',
      Confirm: 'Confirm ',
      Cancel: 'Cancel ',
      Create: 'create ',
      Edit: 'Edit ',
      FG: 'FG',
      FGReserve: 'FG (Reserve)',
      FGAvailable: 'FG (Avaliable)',
      FOC: 'FOC',
      FOCReserve: 'FOC (Reserve)',
      FOCAvailable: 'FOC (Avaliable)',
      Reserve: 'Reserve',
      Avaliable: 'Avaliable',
      CenterIn: 'Center (In)',
      CenterOut: 'Center (Out)',
      Claim: 'Claim',
      Clear: 'Clear',
      Balance: 'Balance',
      Document: 'Document',
      StockType: 'Stock Type',
      TransferGoods: 'Transfer Goods',
      Type: 'Type',
      Quantity: 'Quantity',
      TransferIn: 'Transfer (In)',
      TransferOut: 'Transfer (Out)',
      Adjusted: 'Adjusted',
      Adjust: 'Adjust',
      Increase: 'Increase',
      Decrease: 'Decrease',
      Transfer: 'Transfer',
      From: 'From',
      To: 'To',
      Before: 'Before',
      After: 'After',
      Remarks: 'Remarks',
      Name: 'Name',
      Remain: 'Remain',
      Used: 'Used',
      QuantityForAdd: 'Quantity For Add',
      AdjustStock: 'Adjust Stock',
      AdjustSetStock: 'Adjust Set Stock',
      Purchase: 'Purchase',
      Sell: 'Sell',
      Return: 'Return',
      CancelReserve: 'Cancel Reserve',
      AdjustSet: 'AdjustSet',
      CreditNote: 'Credit Note',
      DebitNote: 'Debit Note',
    },
    Brand: {
      CancelCreate: ' to cancel the create ',
      Create: ' to create ',
      Edit: ' to edit ',
      Confirmsave: 'Confirm to save ',
      Confirmedit: 'Confirm to edit ',
      Confirmcreate: 'Confirm to create ',
      Save: ' to save ',
      CancelEditTitle: 'Cancel to edit Manage Brand',
      CancelEdit: 'Are you sure to cancel to edit Manage Brand?',
      CancelAddTitle: 'Cancel to add Manage Brand',
      CancelAdd: 'Are you sure to cancel to add Manage Brand?',
      ConfirmEditTitle: 'Confirm to edit Manage Brand',
      ConfirmEdit: 'Are you sure to edit Manage Brand?',
      ConfirmAddTitle: 'Confirm to add Manage Brand',
      ConfirmAdd: 'Are you sure to add Manage Brand?',
      Active: 'Are you sure to ',
      DeleteTitle: 'Delete ',
      Delete: 'Are you sure to delete ',
    },
    Promation:{
      CancelEditTitle:'Cancel editing promotion code management',
      CancelEdit:'Are you sure to cancel editing the promotion code?',
      CancelAddTitle:'Cancel adding promotion code management',
      CancelAdd:'Are you sure to cancel adding the promo code management?',
      ConfirmEditTitle:'Confirm editing of promotion code management',
      ConfirmEdit:'Are you sure you want to edit your promo code management?',
      ConfirmAddTitle:'Confirm adding promotion code management',
      ConfirmAdd:'Are you sure you want to add promo code management?',
    },
    DocTable: {
      No: 'NO.',
      Item: 'Item/Service',
      Detail: 'Detail',
      Quantity: 'Quantity',
      Price: 'Price',
      Discount: 'Discount',
      StockType: 'Stock Type',
      PreTaxAmount: 'Amount',
      PreNewAmount: 'มูลค่าที่ถูกต้องก่อนภาษี',
      Select: 'Select Item',
      Wht: 'WHT',
      Button: {
        AddItem: 'Add Item',
        AddDes: 'Add Description',
        Reset: 'Reset',
      },
    },
    ViewTable: {
      No: '#',
      Code: 'Code',
      CustomerName: 'Customer Name',
      Status: 'Status',
      DateFrom: 'Date From',
      DateTo: 'Date To',
      GrandTotal: 'Grand Total',
      Actions: 'Actions',
      NoItemPreview: 'There is no item to preview',
      Name: 'Name',
      UpdateDate: 'Update Date',
      CreateBy: 'Create By',
      Role: 'Role',
      CreateDate: 'Create Date',
      By: 'By: ',
      Date: 'Date: ',
      UserName: 'User Name',
      Info: 'Info',
      InventoryStatus: 'Inventory Status',
      CodeName: 'Promotion name',
      RedeemCode: 'Redeem Code',
      StartDate: 'Start Date',
      EndDate: 'End Date',
      Amount: 'Amount',
      ReviewBy: 'Review By',
      ItemName: 'Item Name',
      Message: 'Message',
      ReviewDate: 'Review Date',
      
      
    },
    DocFooter: {
      Description: 'Description',
      Validate: "*Can't much than 9 lines",
      TotalDiscount: 'Total Discount',
      AmountVatExemp: 'Vat exempted amount',
      Vat: 'VAT',
      Baht: 'Baht',
      NetAmount: 'Net Amount',
      GrandTotal: 'Grand Total Amount',
      GrandTotalRepaid: 'Grand Total Repaid',
      AmountOfReferred: 'Total amount of referred tax invoice(s)',
      NewAmount: 'New amount',
      DeliveryPrice: 'Shipping Fee',
      Not: 'Not',
      SelectDiscountFormat: 'Select Discount Format',
    },
    ActionHistory: {
      No: 'No.',
      UsageActivity: 'Usage Activity ',
      Created: 'Created ',
      Paid: 'Paid ',
      Viewed: 'View ',
      At: 'at',
      Attach: ' attach',
      Update: 'Update ',
      Accept: 'Accept ',
      Void: 'Void ',
      Cancel: 'Cancel ',
      DocumentLog: 'Document Log',
      IssueTaxInvoice: 'Issue Tax Invoice',
      TaxInvoice: 'Number',
      GotReceipt: 'Got Receipt',
      VoidGotReceipt: 'Void Got Receipt',
      RegisterTaxInvoice: 'Register Tax Invoice',
      VoidTaxInvoice: 'Void Tax Invoice',
    },
    ContentDocument: {
      No: 'No.',
      Ref: 'Ref.',
      Print: 'Print',
      PrintDemo: 'Print (Demo)',
      Menu: 'Menu',
      Edit: 'Edit',
      CustomerInfo: 'Customer Info.',
      Customer: 'Customer',
      Address: 'Address',
      IssueDate: 'Issue Date',
      DueDate: 'Due Date',
      ValidUntil: 'Valid Until',
      PricingAndTaxSetting: 'Pricing and tax setting',
      CreditNoteInfo: 'Credit Note Info',
      DebitNoteInfo: 'Debit Note Info',
      PricingType: 'Pricing Type',
      Currency: 'Currency',
      ProductService: 'Product/Service',
      Description: 'Description',
      Quantity: 'Quantity',
      Unit: 'Unit',
      BeforePrice: 'Before Price/QTY',
      PriceQ: 'Price/QTY',
      CostQ: 'Cost/QTY',
      DiscQ: 'Disc./QTY',
      CostAmount: 'Cost Amount',
      PreVatAmount: 'Pre-VAT Amount',
      DocumentSummary: 'Document Summary',
      CurrentDocument: 'Current Document',
      Discount: 'Discount',
      VatItemValue: 'Pre-VAT Amount',
      VatAmount: 'VAT Amount',
      NetTotal: 'Net Total',
      Baht: 'THB',
      RemarkForCustomer: 'Remark for customer',
      HeadOffice: 'Head Office',
      Branch: 'Branch',
      Exclude: 'Exclude',
      Include: 'Include',
      WithholdingTaxAmount: 'Withholding Tax Amount',
      NetAmountToPay: 'Net Amount to Pay',
      Difference: 'Difference',
      TotalAmountOfReferredTaxInvoices:
        'Total amount of referred tax invoice(s)',
      NewAmount: 'New Amount',
      FixedPage: 'Fixed Page',
      CancelDocument: 'Cancel Document',
      VoidDocument: 'Void Document',
      CreateTaxInvoice: 'Create TAX Invoice',
      VoidGotReceipt: 'Void Got Receipt',
      Amount: 'Amount',
      Remain: 'Remain',
      Item: 'Item',
      ItemForItemSet: 'Item for Item Set',
      ItemSetValue: 'Item Set Value',
      ValueForItemSet: 'Price value for Item Set',
      ValueForItemSetCost: 'Cost value for Item Set',
      TotalCostPrice: 'Total Cost Price',
      TotalProductPrice: 'Total Product Price',
      InventoryStatus: 'Inventory Status',
      TransferCenterInAll: 'Transfer to Center Stock',
      SetInventory: 'Delivery Complete',
      EditRemarks: 'Edit Remarks',
      PrintShipping: 'Shipping Label Print',
      TotalBeforePrice: 'Total before discount price',
      ValueForItemSetBeforePrice: 'Before discount value for Item set',
      PaymentMethod: 'Payment method',
      TrackingNumber: 'TrackingNumber',
      TransportationChannel: 'TransportationChannel',
    },
    SaleOrder: {
      ConfirmOrder: 'Confirm your Sale Order',
      CreateIVFormSO: 'Create Invoice from Sale Order',
      Approve: 'Approve',
      toApprove: ' to approve this ',
      this: '',
      Approvespace: 'Approve ',
      ApproveSale: 'Approve for Invoice',
      DetailSale: 'Are you confirm to approve for Invoice ?',
    },
    CreditDebit: {
      Create: 'Create ',
      EnterYour: 'Enter Your ',
      SelectDocument: 'Select Document',
      Business: 'Business',
      DocumentNo: 'Document No.',
      PleaseSelectBusiness: 'Please select Business',
      PleaseSelectDocumentNo: 'Please select Document No.',
      SelectBusiness: 'Select Business',
      SelectDocumentNo: 'Select Document',
      BusinessNotFound: 'Business not found',
      DocumentNotFound: 'Document not found',
      DebitNoteInfo: 'Debit Note Info',
      CreditNoteInfo: 'Credit Note Info',
      EnterDebitNoteInfo: 'Enter Debit Note Info',
      EnterCreditNoteInfo: 'Enter Credit Note Info',
      ReasonForIssuingCreditNote: 'Reason for issuing Credit Note',
      ReasonForIssuingDebitNote: 'Reason for issuing Debit Note',
      DescriptionOfReason: 'Description of reason',
    },
    Payment: {
      Baht: 'THB',
      ReceivePaymentRecord: 'Payment Record',
      PaymentRecord: 'Payment Record',
      TheTotalAmountOfReceivableDebt: 'The total amount of receivable debt is',
      PaymentRecieveMethod: 'Payment Recieve Method',
      PaymentMethod: 'Payment Method',
      PaymentAmount: 'Payment Amount',
      PaymentReceiveAmount: 'Payment Amount',
      Remark: 'Remark',
      Paid: 'Paid Value',
      MoneyPaymentRecieve: 'Money Payment Recieve',
      MoneyPayment: 'Money Payment',
      TaxWithheld: 'Tax Withheld',
      TotalPaymentRecieve: 'Total Payment Recieve',
      TotalPayment: 'Total Payment',
      RemainingAmount: 'Remaining Amount',
      Selectmethod: 'Please select a payment method.',
      dueDate: 'Due date',
      getPaidOn: 'Get paid on',
      getFullPaid: 'Get paid in full on',
      PaymentDate: 'Payment Date',
      FullPaid: 'Received full payment',
      paymentMethod: 'Payment method',
      payWithDoc: 'Payment with documents',
      stillPay: 'Still not getting paid in full',
      thisPrice: 'This price is',
      reCPay: 'Receive payment no.',
      cutOPay: 'Cut off payment with documents no.',
      Cash: 'Cash',
      cash: 'Cash',
      Transfer: 'Transfer',
      CreditDebit: 'Credit/Debit Card',
      Cheque: 'Cheque',
      Other: 'Other',
      Modal: {
        AccountNo: 'Account No.',
        CreateBankAccount: 'Create Bank Account',
        EditBankAccount: 'Edit Bank Account',
        BankAccount: {
          Label: 'Bank Account',
          Placeholder: 'Select a bank account',
          AddBankAccount: 'Add bank account',
          Error: 'Please select a bank account',
          CancelCreateTitle: 'Cancel Create Bank Account',
          CancelCreate: 'Are you sure to cancel create bank account?',
          ConfirmCreateTitle: 'Confirm Create Bank Account',
          ConfirmCreate: 'Are you sure to create bank account?',
          CancelEditTitle: 'Cancel edit bank account',
          CancelEdit: 'Are you sure you want to cancel bank account edits?',
          ConfirmEditTitle: 'Confirm bank account edits',
          ConfirmEdit: 'Are you sure you want to edit your bank account?',
          Bank: {
            Label: 'Bank',
            Placeholder: 'Select a bank',
            Error: 'Please select a bank',
          },
          AccountNumber: {
            Label: 'Account Number',
            Placeholder: 'Enter account number',
            Error: 'Please enter an account number',
          },
          BankBranch: {
            Label: 'Bank Branch',
            Placeholder: 'Enter bank branch',
            Error: 'Please enter a bank branch',
          },
          AccountName: {
            Label: 'Account Name',
            Placeholder: 'Enter account name',
            Error: 'Please enter an account name',
          },
          BankAccountType: {
            Label: 'Account Type',
            Saving: 'Savings',
            FixedDeposit: 'Fixed Deposit',
            Current: 'Current',
          },
        },
      },
    },
    DocumentPaper: {
      Print: 'Print',
      FixedPage: 'Fixed Page',
      HideObject: 'Hide Object',
      PageNumber: 'Page Number',
      Manuscript: 'Manuscript',
      Watermark: 'Watermark',
      TableColor: 'Table Color',
      Discount: 'Discount',
      WithheldTax: 'Withheld Tax',
      ClearAll: 'Clear All',
      BGColor: 'BG Color',
      IsOriginal: 'Original Doc',
      ShippingFee: 'Shipping Fee',
    },
    FormName: {
      QuotationForm: 'Quotation Form',
      QuotationDetail: 'Quotation Detail',

      SaleOrderForm: 'Sale Order Form',
      SaleOrderDetail: 'Sale Order Detail',

      InvoiceForm: 'Invoice Form',
      InvoiceDetail: 'Invoice Detail',

      TaxInvoiceForm: 'Tax Invoice Form',
      TaxInvoiceDetail: 'Tax Invoice Detail',

      ReceiptDetail: 'Receipt Detail',

      DebitNoteForm: 'Debit Note Form',
      DebitNoteDetail: 'Debit Note Detail',

      CreditNoteForm: 'Credit Note Form',
      CreditNoteDetail: 'Credit Note Detail',

      PurchaseRequestForm: 'Purchase Request Form',
      PurchaseRequestDetail: 'Purchase Request Detail',

      PurchaseOrder: 'Purchase Order Form',
      PurchaseOrderDetail: 'Purchase Order Detail',

      GoodsReceiveForm: 'Goods Receive Form',
      GoodsReceiveDetail: 'Goods Receive Detail',

      ReviewDetail: 'Review Detail',
    },
    Quotation: {
      ConfirmOrder: 'Confirm your Quotation',
      CancelCreateTitle: 'Cancel to create Quotation',
      CancelCreate: 'Are you sure to cancel to create Quotation?',
      CancelEditTitle: 'Cancel to edit Quotation',
      CancelEdit: 'Are you sure to cancel to edit Quotation?',
      SaveDraftTitle: 'Save Quotation Draft',
      SaveDraft: 'Are you sure to save Quotation Draft?',
      EditTitle: 'Edit Quotation',
      Edit: 'Are you sure to edit Quotation?',
      CreateTitle: 'Create Quotation',
      Create: 'Are you sure to create Quotation?',
      ApproveSale: 'Approve for Sale Order',
      DetailSale: 'Are you confirm to approve for Sale Order ?',
    },
    ManageItem: {
      CancelCreateTitle: 'Cancel to Create Item',
      CancelCreate: 'Are you sure to cancel to create Item?',
      CancelEditTitle: 'Cancel to Edit Item',
      CancelEdit: 'Are you sure to cancel to edit Item?',
      ConfirmCreateTitle: 'Confirm to Create Item',
      ConfirmCreate: 'Are you sure to create Item?',
      ConfirmEditTitle: 'Confirm to Edit Item',
      ConfirmEdit: 'Are you sure to edit Item?',
    },
    ManageItemSet: {
      CancelCreateTitle: 'Cancel to Create Item Set',
      CancelCreate: 'Are you sure to cancel to create Item Set?',
      CancelEditTitle: 'Cancel to Edit Item Set',
      CancelEdit: 'Are you sure to cancel to edit Item Set?',
      ConfirmCreateTitle: 'Confirm to Create Item Set',
      ConfirmCreate: 'Are you sure to create Item Set?',
      ConfirmEditTitle: 'Confirm to Edit Item Set',
      ConfirmEdit: 'Are you sure to edit Item Set?',
    },
    ManageGroup: {
      CancelCreateTitle: 'Cancel to create Group',
      CancelCreate: 'Are you sure to cancel to create Group?',
      CancelEditTitle: 'Cancel to edit Group',
      CancelEdit: 'Are you sure to cancel to edit Group?',
      ConfirmCreateTitle: 'Confirm to create Group',
      ConfirmCreate: 'Are you sure to create Group?',
      ConfirmEditTitle: 'Confirm to edit Group',
      ConfirmEdit: 'Are you sure to edit Group?',
    },
    ManageCategory: {
      CancelCreateTitle: 'Cancel to create Category',
      CancelCreate: 'Are you sure to cancel to create Category?',
      CancelEditTitle: 'Cancel to edit Category',
      CancelEdit: 'Are you sure to cancel to edit Category?',
      ConfirmCreateTitle: 'Confirm to create Category',
      ConfirmCreate: 'Are you sure to create Category?',
      ConfirmEditTitle: 'Confirm to edit Category',
      ConfirmEdit: 'Are you sure to edit Category?',
    },
    ManageSubCategory: {
      CancelCreateTitle: 'Cancel to create SubCategory',
      CancelCreate: 'Are you sure to cancel to create SubCategory?',
      CancelEditTitle: 'Cancel to edit SubCategory',
      CancelEdit: 'Are you sure to cancel to edit SubCategory?',
      ConfirmCreateTitle: 'Confirm to create SubCategory',
      ConfirmCreate: 'Are you sure to create SubCategory?',
      ConfirmEditTitle: 'Confirm to edit SubCategory',
      ConfirmEdit: 'Are you sure to edit SubCategory?',
    },
    ManageClass: {
      CancelCreateTitle: 'Cancel to create Class',
      CancelCreate: 'Are you sure to cancel to create Class?',
      CancelEditTitle: 'Cancel to edit Class',
      CancelEdit: 'Are you sure to cancel to edit Class?',
      ConfirmCreateTitle: 'Confirm to create Class',
      ConfirmCreate: 'Are you sure to create Class?',
      ConfirmEditTitle: 'Confirm to edit Class',
      ConfirmEdit: 'Are you sure to edit Class?',
    },
    ManageSubClass: {
      CancelCreateTitle: 'Cancel to create SubClass',
      CancelCreate: 'Are you sure to cancel to create SubClass?',
      CancelEditTitle: 'Cancel to edit SubClass',
      CancelEdit: 'Are you sure to cancel to edit SubClass?',
      ConfirmCreateTitle: 'Confirm to create SubClass',
      ConfirmCreate: 'Are you sure to create SubClass?',
      ConfirmEditTitle: 'Confirm to edit SubClass',
      ConfirmEdit: 'Are you sure to edit SubClass?',
    },
    ManageRole: {
      CancelCreateTitle: 'Cancel to create Role',
      CancelCreate: 'Are you sure to cancel to create Role?',
      CancelEditTitle: 'Cancel to edit Role',
      CancelEdit: 'Are you sure to cancel to edit Role?',
      ConfirmCreateTitle: 'Confirm to create Role',
      ConfirmCreate: 'Are you sure to create Role?',
      ConfirmEditTitle: 'Confirm to edit Role',
      ConfirmEdit: 'Are you sure to edit Role?',
      DeleteTitle: 'Delete Role',
      Delete: 'Are you sure to delete Role?',
      RoleName: 'Role Name',
      Active: 'Are you sure to ',
    },
    ManageUser: {
      CancelCreateTitle: 'Cancel to create User',
      CancelCreate: 'Are you sure to cancel to create User?',
      CancelEditTitle: 'Cancel to edit User',
      CancelEdit: 'Are you sure to cancel to edit User?',
      ConfirmCreateTitle: 'Confirm to create User',
      ConfirmCreate: 'Are you sure to create User?',
      ConfirmEditTitle: 'Confirm to edit User',
      ConfirmEdit: 'Are you sure to edit User?',
      DeleteTitle: 'Delete User',
      Delete: 'Are you sure to delete User?',
      ResetTitle: 'Reset Password',
      Reset: 'Are you sure to reset password?',
      Active: 'Are you sure to ',
    },
    CreditNote: {
      ConfirmCreateTitle: 'Confirm to create Credit Note',
      ConfirmCreate: 'Are you sure to create Credit Note?',
    },
    DebitNote: {
      ConfirmCreateTitle: 'Confirm to create Debit Note',
      ConfirmCreate: 'Are you sure to create Debit Note?',
    },
    GoodsReceive: {
      GetReceipt: 'Get Receipt',
      YouCanGetTheReceipt: 'You can get the Receipt',
    },
    Files: {
      NoFileChosen: 'No File Chosen',
      ChooseFile: 'Choose File',
    },
    Informative: {
      Description: 'Description',
      ActiveStatus: 'Active Status',
      Home: {
        BannerImage: 'Banner Image',
        BannerImageDescription: 'Resolution 1920px*730px',
        BannerVideo: 'Banner Video',
        BannerVideoDescription: 'Resolution 1920px*730px',
        UseBannerAsVideo: 'Use Banner as Video',
        WhoWeAreQuote: {
          Label: 'Who we are Quote',
          Description: 'Quote',
        },
        WhoWeAreDescription: {
          Label: 'Who we are Description',
          Description: 'Description',
        },
        WhoWeAreImage1: {
          Label: 'Who We Are Image 1',
          Description: 'Resolution: 260px*260px',
        },
        WhoWeAreImage2: {
          Label: 'Who We Are Image 2',
          Description: 'Resolution: 440px*640px',
        },
        WhoWeAreImage3: {
          Label: 'Who We Are Image 3',
          Description: 'Resolution: 480px*300px',
        },
        NewProductSection: 'New Product Section Description',
        NewProductBG: 'New Product Background',
        NewProductBGDescription: 'Resolution: 940px*750px',
        NewProductWordingButton: 'New Product Wording Button',
        WordingOnButton: 'Wording on button',
        NewProductURL: 'New Product URL',
        NewProductURLDescription: 'URL to redirect',
        OurTechnologyQuote: 'Our Technology Quote',
        OurTechnologyVideo: 'Our Technology Video',
        OurTechnologyVideoDescription: 'Resolution: 1280px*600px',
        MemberPromotionImage1: 'Member Promotion Image 1',
        MemberPromotionImage1Description: 'Resolution: 660px*447px',
        MemberPromotionImage2: 'Member Promotion Image 2',
        MemberPromotionImage2Description: 'Resolution: 660px*447px',
      },
      About: {
        BannerTitle: 'Banner Title',
        MainWordingOnBanner: 'Main Wording on banner',
        BannerSubTitle: 'Banner Sub Title',
        SubWordingOnBanner: 'Sub Wording on banner',
        BannerImage: 'Banner Image',
        BannerImageDescription:
          'Banner Background Image, Resolution 1920px*450px',
        QuoteTitle1: 'Quote Title 1',
        QuoteDescription: 'Quote Description',
        QuoteImage1: 'Quote Image 1',
        QuoteImageDescription: 'Resolution 1280px*600px',
        AboutDescription1: 'About Description 1',
        DescriptionOnLeftSide: 'Description on left side',
        AboutDescription2: 'About Description 1',
        DescriptionOnRightSide: 'Description on right side',
        AboutImage1: 'About Image 1',
        AboutImage1Description: 'Image on left side, Resolution 220px*220px',
        AboutImage2: 'About Image 2',
        AboutImage2Description: 'Image on middle side, Resolution 400px*600px',
        AboutImage3: 'About Image 3',
        AboutImage3Description: 'Image on right side, Resolution 510px*300px',
        BannerTitle2: 'Banner Title 2',
        BannerImage2: 'Banner Image 2',
        QuoteTitle2: 'Quote Title 2',
        QuoteVideo: 'Quote Video',
      },
      Contact: {
        BannerTitle: 'Banner Title',
        BannerTitleDescription: 'Wording in the middle of Banner',
        BannerImage: 'Banner Image',
        BannerImageDescription: 'Resolution 1920px*450px',
        VisitUs: 'Visit us',
        VisitUsDescription: 'Description of Office Location',
        CalltUs: 'Call us',
        CallUsDescription: 'Description of Store Location',
        EmailtUs: 'Email us',
        EmailUsDescription: 'Description of Call us directly',
        ContactFormImage1: 'Contact Form Image 1',
        ContactFormImage1Description: 'Resolution 510px*587px',
        ContactFormImage2: 'Contact Form Image 2',
        ContactFormImage2Description: 'Resolution 330px*387px',
      },
      NewsEvent: {
        NewsBanner: {
          Label: 'News Banner',
          Description: 'Banner Background',
        },
        NewsTitle: {
          Label: 'News Title',
          Description: 'News Title',
        },
        ConsumerReviewDate: {
          Label: 'Consumer Review Date',
          Description: 'Date of Consumer Review',
        },
        ConsumerReviewTitle: {
          Label: 'Consumer Review Title',
          Description: 'Title of Consumer Review',
        },
        ConsumerReviewImage1: {
          Label: 'Consumer Review Image 1',
          Description: '1st Image of Consumer Review',
        },
        Detail: {
          Label: 'Detail',
          Description: 'Detail of Consumer Review',
        },
        ConsumerReviewImage2: {
          Label: 'Consumer Review Image 2',
          Description: '2nd Image of Consumer Review',
        },
        ReviewerName: {
          Label: 'Reviewer Name',
          Description: 'Name of Reviewer',
        },
        URLButton: {
          Label: 'URL Button',
          Description: 'URL to redirect',
        },
        SocialMedia: {
          Label: 'Social Media',
          Description: 'Select Social Media',
        },
        CompanyVideo: {
          ContentDate: {
            Label: 'Content Date',
            Description: 'Date of Company Video',
          },
          VideoTitle: {
            Label: 'Video Title',
            Description: 'Title of Company Video',
          },
          VideoCoverImage: {
            Label: 'Video Cover Image',
            Description: 'Video Cover Image of Company Video',
          },
          Subtitle: {
            Label: 'Subtitle',
            Description: 'Subtitle of Company Video',
          },
          RepoveLive: {
            Label: 'Repove Live',
            Description: 'Check if is Live',
          },
          LiveDate: {
            Label: 'Live Date',
            Description: 'Date on Live',
          },
          MediaType: {
            Label: 'Media Type',
            Description: 'Video or Live',
          },
        },
      },
      SignIn: {
        BannerImage: {
          1: 'Banner Image 1',
          2: 'Banner Image 2',
        },
        BannerMobileImage: {
          1: 'Banner Mobile Image 1',
          2: 'Banner Mobile Image 2',
        },
        Description: 'Resolution 648px*785px',
      },
      Product: {
        BannerImage: {
          1: 'Banner Image 1',
          2: 'Banner Image 2',
        },
        BannerTitle: {
          Label: 'Banner Title',
          Description: 'Title on Banner Image 2',
        },
        Description: 'Resolution 1920px*450px',
      },
      PrivacyPolicy: {
        Date: {
          Label: 'Date',
          Description: 'Date of Privacy Policy',
        },
        Title: {
          Label: 'Title',
          Description: 'Title of Privacy Policy',
        },
        Description: {
          Label: 'Description',
          Description: 'Description of Privacy Policy',
        },
      },
      CookiePolicy: {
        Date: {
          Label: 'Date',
          Description: 'Date of Cookie Policy',
        },
        Title: {
          Label: 'Title',
          Description: 'Title of Cookie Policy',
        },
        Description: {
          Label: 'Description',
          Description: 'Description of Cookie Policy',
        },
      },
      TermsCondition: {
        Date: {
          Label: 'Date',
          Description: 'Date of Terms and Condition',
        },
        Title: {
          Label: 'Title',
          Description: 'Title of Terms and Condition',
        },
        Description: {
          Label: 'Description',
          Description: 'Description of Terms and Condition',
        },
      },
      SellerManagementRegulation: {
        Date: {
          Label: 'Date',
          Description: 'Date of Seller Management Regulation',
        },
        Title: {
          Label: 'Title',
          Description: 'Title of Seller Management Regulation',
        },
        Description: {
          Label: 'Description',
          Description: 'Description of Seller Management Regulation',
        },
      },
    },
  },
};
