class Branch {
  id?: any;
  name: string;
  addressNumber: string;
  provinceId: string;
  provinceName?: string;
  districtId: string;
  districtName?: string;
  subDistrictId: string;
  subDistrictName?: string;
  postCode: string;
  mapUrl: string;
  mediaFiles?: any[];
  phone: string;
  email: string;
  isActive: boolean;
  isDelete: boolean;
  //   createDate: Date;
  //   updateDate: Date;
}

export default Branch;

export const branchList: Branch[] = [
  // {
  //   id: '1',
  //   name: 'Repove Office สาขากรุงเทพ',
  //   addressNumber: '75/4 ซอยสุขุมวิท 64/1',
  //   provinceId: '10   ',
  //   provinceName: 'กรุงเทพมหานคร',
  //   districtId: '1009      ',
  //   districtName: 'พระโขนง',
  //   subDistrictId: '100913    ',
  //   subDistrictName: 'พระโขนง',
  //   postCode: '10260',
  //   mapUrl: '10260',
  //   phone: '0812345679',
  //   email: 'sales@cocueco.com',
  //   isActive: true,
  //   isDelete: false,
  // },
  // {
  //   id: '2',
  //   name: 'Repove Office สาขาชลบุรี',
  //   addressNumber: '75/4 ซอยสุขุมวิท 64/1',
  //   provinceId: '10   ',
  //   provinceName: 'กรุงเทพมหานคร',
  //   districtId: '1009      ',
  //   districtName: 'พระโขนง',
  //   subDistrictId: '100913    ',
  //   subDistrictName: 'พระโขนง',
  //   postCode: '10260',
  //   mapUrl: '10260',
  //   phone: '0812345679',
  //   email: 'sales@cocueco.com',
  //   isActive: true,
  //   isDelete: false,
  // },

];
